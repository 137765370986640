import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";

import { SubTab } from "../Cms/TabNInput";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {API} from "../../constant/api"

const Activated = () => (
  <span className="label label-lg label-light-success label-inline">
    Activated
  </span>
);

const Deactivated = () => (
  <span className="label label-lg label-light-danger label-inline">
    Deactivated
  </span>
);

const ViewOne = (props) => {
  const { id } = props.match.params;

  const [AccommodationData, setAccommodationData] = useState([]);

  const {
    response: responseAccommodationData,
    request: requestAccommodationData,
  } = useRequest();

  const { date_format } = useSelector((state) => state.setting);

  useEffect(() => {
    requestAccommodationData("GET", `accommodation/${id}`);

    document.title = "View Accommodation - Kura";
  }, []);
  /*
name
authorName
address
description
category
condition
price
createdAt
pictures
: 
["uploads/images/Accommodation-pictures/2022-12-14T09-17-22.775Z-activity1.jpeg"]
status
*/
  useEffect(() => {
    if (responseAccommodationData) {
      const {
        lodgeName,
        preferredReligion,
        address,
        description,
        roomId,
        preferredAgeRange,
        price,
        pictures,
        status,
        createdAt,
        isSold
      } = responseAccommodationData.accommodationData;

      setAccommodationData([
        { title: "Room", value: roomId ?? "-" },
        { title: "Lodge Name", value: lodgeName ?? "-" },
        // { title: "Preferred Religion", value: preferredReligion ?? "-" },
        { title: "Address", value: address ?? "-" },
        {
          title: "Preferred Age Range",
          value: `${preferredAgeRange[0]}-${preferredAgeRange[1]}` ?? "-",
        },

        { title: "Description", value: description ?? "-" },
        // { title: "Condition", value: condition ?? "-" },
        { title: "Price", value: price ?? "-" },
        {
          title: "Created At",
          value: <Moment format={date_format}>{createdAt}</Moment>,
        },
        {
          title: "Status",
          value: status ?? "-",
        },
        {
          title: "Sold",
          value: isSold ? "True" : "False",
        },
        {
          title: "pictures",
          value:
            pictures?.length > 0 ? (
              <div className="d-flex" style={{ gap: "20px" }}>
                {pictures.map((picture, index) => (
                  <div key={index}>
                    <img
                      src={`${API.PORT}/${picture}`}
                      width="100px"
                      alt=""
                      data-fancybox
                    />
                  </div>
                ))}
              </div>
            ) : (
              <p>No pictures found !</p>
            ),
        },
      ]);
    }
  }, [responseAccommodationData]);

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="View Accommodation"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/Accommodation", name: "Back To Accommodation" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card card-custom gutter-b">
            <div className="card-header card-header-tabs-line">
              <div className="card-toolbar">
                <ul
                  className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                  role="tablist"
                >
                  {["Accommodation Information"].map((data, index) => (
                    <SubTab key={index} name={data} index={index} />
                  ))}
                </ul>
              </div>
            </div>

            <div className="card-body px-0">
              <div className="tab-content px-10">
                <div
                  className={`tab-pane active`}
                  id={`kt_apps_contacts_view_tab_3`}
                  role="tabpanel"
                >
                  {AccommodationData.length > 0 &&
                    AccommodationData.map((Accommodation, index) => (
                      <div key={index} className="form-group row my-2">
                        <label className="col-4 col-form-label">
                          {Accommodation.title}
                        </label>
                        <div className="col-8">
                          <span className="form-control-plaintext font-weight-bolder">
                            {Accommodation.value}
                          </span>
                        </div>
                      </div>
                    ))}

                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOne;
