import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Input, RenderInputFields, SubmitButton } from "../Form/Form";

const Edit = () => {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  const { response, request } = useRequest();
  const { response: typeEditresponse, request: typeEditRequest } = useRequest();

  const history = useHistory();

  useEffect(() => {
    typeEditRequest("GET", `master/report-reason/${id}`);
    document.title = "Edit Report Reason - Kura";
  }, []);

  useEffect(() => {
    if (typeEditresponse) {
      reset({
        reason: typeEditresponse?.reason?.reason,
      });
    }
  }, [typeEditresponse]);

  useEffect(() => {
    if (response) {
      toast.success("Reason has been updated successfully.");
      history.push("/report-reason");
    }
  }, [response]);

  const onSubmit = (data) => {
    const { reason } = data;
    request("PUT", "master/report-reason", {
      reason,
      id,
    });
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "Reason",
        type: "text",
        name: "reason",
        registerFields: {
          required: true,
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Report Reason Types"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/report-reason", name: "Back To Report Reason" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Edit Report Reason
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
