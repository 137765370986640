import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { ExportButton, SearchInput, SearchSubmitButton } from "../Form/Form";
import { addOneToDate, downloadExcel, downloadPDF } from "../../util/fn";
import { ADMINURL } from "../../constant/api";
import moment from "moment";

const OBJ_TABLE = {
  Id: "freelanceId",
  "user name": "user_name",
  "Type / Category": "type",
  studentLevel: "studentLevel",
  // time: "time",
  price: "price",
  // contact: "contact",
  "Created At": "createdAt",
  isApproved: "status",
  status: "isActive",
  Reported: "isReported",
};

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  isActive = "",
  type = "",
  dateFrom = "1970-01-01",
  dateTo,
  user_name = "",
  user_customId = "",
  user_userName = "",
  freelanceId = "",
  status = "",
  studentLevel = ""
) => {
  if (sortBy.length > 0) {
    if (sortBy == "registered on" || sortBy == "Created At") {
      sortBy = "createdAt";
    }
  } else {
    sortBy = "createdAt";
  }
  order = order.length > 0 ? order : "desc";

  dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";

  dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());
  return `freelance?page=${page}&per_page=${per_page}&sortBy=${sortBy}&order=${order}&isActive=${isActive}&type=${type}&dateFrom=${dateFrom}&dateTo=${dateTo}&user_name=${user_name}&user_customId=${user_customId}&user_userName=${user_userName}&freelanceId=${freelanceId}&status=${status}&studentLevel=${studentLevel}`;
};

const ViewAll = () => {
  const history = useHistory();
  const [freelancers, setFreelancers] = useState([]);
  const [page, setPage] = useState(1);
  const [freelanceType, setFreelanceType] = useState([]);
  const [studentLevel, setStudentLevel] = useState([]);

  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "Created At",
    order: "desc",
  });

  const MySwal = withReactContent(Swal);

  const { records_per_page, date_format } = useSelector(
    (state) => state.setting
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    clearErrors,
    setError,
  } = useForm();

  const { request, response } = useRequest();
  const { request: requestChangeStatus, response: responseChangeStatus } =
    useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();
  const { request: requestCheckApproved, response: responseCheckApproved } =
    useRequest();
  const { response: typesRes, request: typesReq } = useRequest();
  const { response: studentLevelRes, request: studentLevelReq } = useRequest();
  const { response: getReportIdRes, request: getReportIdReq } = useRequest();

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = "Beta-help - Kura";
    typesReq("GET", "admin/freelance-types");
    studentLevelReq("Get", "admin/freelance-student-levels");
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      setFreelancers(response.freelance);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  useEffect(() => {
    if (typesRes && typesRes?.status) {
      setFreelanceType(typesRes.types);
    }
  }, [typesRes]);

  useEffect(() => {
    if (studentLevelRes && studentLevelRes?.status) {
      setStudentLevel(studentLevelRes.levels);
    }
  }, [studentLevelRes]);

  useEffect(() => {
    if (responseChangeStatus) {
      const { id, newStatus } = responseChangeStatus;

      const oldScholarshipTypes = [...freelancers];
      const indexToChange = oldScholarshipTypes.findIndex(
        (scholar) => scholar._id == id
      );
      oldScholarshipTypes[indexToChange].isActive = newStatus;

      setFreelancers(oldScholarshipTypes);
    }
  }, [responseChangeStatus]);

  useEffect(() => {
    if (responseCheckApproved) {
      const { id, status } = responseCheckApproved;

      const oldFreelance = [...freelancers];
      const indexToChange = oldFreelance.findIndex((hub) => hub._id == id);
      oldFreelance[indexToChange].status = status;

      setFreelancers(oldFreelance);
    }
  }, [responseCheckApproved]);

  useEffect(() => {
    if (responseDelete) {
      const { id } = responseDelete;
      let newscholarTypes = [...freelancers];
      newscholarTypes = newscholarTypes.filter((ty) => ty._id != id);
      setFreelancers(newscholarTypes);
      toast.success(responseDelete.message);
    }
  }, [responseDelete]);

  const fetchMoreData = ({ selected }) => {
    setFreelancers([]);
    const {
      isActive,
      type,
      dateFrom,
      dateTo,
      user_name,
      user_customId,
      user_userName,
      freelanceId,
      status,
      studentLevel,
    } = getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        type,
        dateFrom,
        dateTo,
        user_name,
        user_customId,
        user_userName,
        freelanceId,
        status,
        studentLevel
      )
    );
  };

  const deleteHandler = (id, data) => {
    const freelanceType = data.projectId ? "project" : "expert";
    MySwal.fire({
      title: "Are you sure?",
      text: "You want to delete this freelancer?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestDelete("DELETE", "freelance", { id, freelanceType });
      } else if (result.isDismissed) {
      }
    });
  };

  const onSearchHandler = (data) => {
    let {
      isActive,
      type,
      dateFrom,
      dateTo,
      user_name,
      user_customId,
      user_userName,
      freelanceId,
      status,
      studentLevel,
    } = data;

    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        type,
        dateFrom,
        dateTo,
        user_name,
        user_customId,
        user_userName,
        freelanceId,
        status,
        studentLevel
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("isActive");
    resetField("dateFrom");
    resetField("dateTo");
    resetField("user_name");
    resetField("dateFrom");
    resetField("dateTo");
    resetField("user_userName");
    resetField("user_customId");
    resetField("status");
    resetField("freelanceId");
    resetField("studentLevel");

    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const {
      isActive,
      type,
      dateFrom,
      dateTo,
      user_name,
      user_customId,
      user_userName,
      freelanceId,
      status,
      studentLevel,
    } = getValues();
    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        type,
        dateFrom,
        dateTo,
        user_name,
        user_customId,
        user_userName,
        freelanceId,
        status,
        studentLevel
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const openCreatorHandler = (id, data) => {
    // window.open(`${ADMINURL}/users/view/${data.userId}`, "_blank");
    history.push(`/users/view/${data.userId}`);
  };

  const sortingHandler = (sortBy) => {
    const {
      isActive,
      type,
      dateFrom,
      dateTo,
      user_name,
      user_customId,
      user_userName,
      freelanceId,
      status,
      studentLevel,
    } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          newOrder,
          isActive,
          type,
          dateFrom,
          dateTo,
          user_name,
          user_customId,
          user_userName,
          freelanceId,
          status,
          studentLevel
        )
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          "desc",
          isActive,
          type,
          dateFrom,
          dateTo,
          user_name,
          user_customId,
          user_userName,
          freelanceId,
          status,
          studentLevel
        )
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  const changeStatusHandler = (id, data) => {
    const ehub = data;
    const status = ehub.isActive ? false : true;
    const freelanceType = ehub.projectId ? "project" : "expert";
    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestChangeStatus("PUT", "freelance/status", {
          id,
          status,
          freelanceType,
        });
      } else if (result.isDismissed) {
      }
    });
  };

  const changeApprovedHandler = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Approve",
      denyButtonText: `Not - Approve`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        requestCheckApproved("PUT", "freelance/approve", {
          id,
          status: "approved",
        });
      } else if (result.isDenied) {
        requestCheckApproved("PUT", "freelance/approve", {
          id,
          status: "rejected",
        });
      }
    });
  };

  const InputFields = [
    {
      label: "User (Name)",
      name: "user_name",
      required: false,
    },
    {
      label: "User Name",
      name: "user_userName",
      required: false,
    },
    {
      label: "User Id",
      name: "user_customId",
      required: false,
    },
    {
      label: "Beta-help Id",
      name: "freelanceId",
      required: false,
    },
    {
      label: "Type",
      name: "type",
      required: false,
      isSelectInput: true,
      children: (
        <>
          <option value="">Select</option>
          {freelanceType.length > 0 &&
            freelanceType.map((type, i) => {
              return (
                <option key={i} value={type.type}>
                  {type.type}
                </option>
              );
            })}
        </>
      ),
    },
    {
      label: "Student Level",
      name: "studentLevel",
      required: false,
      isSelectInput: true,
      children: (
        <>
          <option value="">Select</option>
          {studentLevel.length > 0 &&
            studentLevel.map((type, i) => {
              return (
                <option key={i} value={type.level}>
                  {type.level}
                </option>
              );
            })}
        </>
      ),
    },
    {
      label: "IsActive",
      name: "isActive",
      required: false,
      isSelectInput: true,
      children: (
        <>
          <option value="">Select</option>
          <option value={true}>Active</option>
          <option value={false}>Not active</option>
        </>
      ),
    },
    {
      label: "Status",
      name: "status",
      required: false,
      isSelectInput: true,
      children: (
        <>
          <option value="">Select</option>
          <option value="pending">Pending</option>
          <option value="approved">Approved</option>
          <option value="rejected">Rejected</option>
        </>
      ),
    },

    {
      label: "Date From",
      name: "dateFrom",
      isDate: true,
      clearErrors,
    },
    {
      label: "Date To",
      name: "dateTo",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback: "'To Date' cannot be smaller than 'From Date'",
      },
    },
  ];

  const viewReportHandler = (id) => {
    getReportIdReq("POST", "report/get-id", { adId: id });
  };

  useEffect(() => {
    if (getReportIdRes) {
      history.push(`report/view/${getReportIdRes.reportId}`);
    }
  }, [getReportIdRes]);

  const getDownloadData = () => {
    let data = JSON.parse(JSON.stringify(freelancers));

    data = data.map((item) => {
      return {
        Id: item.freelanceId,
        "User Name": item.user_name,
        "Type / Category": item.type || item.category,
        "Student Level": item.studentLevel,
        Price: item.price,
        "Created At": moment(item.createdAt).format(date_format),
        "Is Approved":
          item.status.charAt(0).toUpperCase() + item.status.slice(1),
        Status: item.isActive ? "Active" : "Inactive",
        Reported: item.isReported ? "True" : "False",
      };
    });

    return data;
  };

  const downloadExcelHandler = () => {
    const data = getDownloadData();

    downloadExcel(data, "Beta-help");
  };

  const generatePdfDocument = async () => {
    const data = getDownloadData();

    const headings = data.length > 0 ? Object.keys(data[0]) : [];

    await downloadPDF(headings.length, headings, data, "Beta-help");
  };

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb title="Beta-help" links={[{ to: "/", name: "Dashboard" }]} />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <ExportButton
                      downloadExcelHandler={downloadExcelHandler}
                      downloadPdfHandler={generatePdfDocument}
                    />

                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={freelancers}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      renderAs={{
                        status: (val) =>
                          val.charAt(0).toUpperCase() + val.slice(1),
                        isReported: (val) => (val ? "True" : "False"),
                      }}
                      links={[
                        {
                          isLink: false,
                          name: "Deactivate",
                          click: changeStatusHandler,
                          title: "Click To Activate",
                          access: ["v3"],
                        },
                        {
                          isLink: false,
                          name: "Approved",
                          click: changeApprovedHandler,
                          title: "Click To Approve/Reject",
                          access: ["v9"],
                        },
                        {
                          isLink: false,
                          name: "Activate",
                          click: changeStatusHandler,
                          title: "Click To Deactivate",
                          access: ["v3"],
                        },
                        {
                          isLink: false,
                          name: "Creator",
                          click: openCreatorHandler,
                          title: "View Creator",
                          access: ["v11"],
                        },
                        {
                          isLink: false,
                          name: "Delete",
                          click: deleteHandler,
                          access: ["v6"],
                        },
                        {
                          isLink: false,
                          name: "Report",
                          title: "View report",
                          click: viewReportHandler,
                          extraData: true,
                          access: ["v10"],
                        },
                      ]}
                      onlyDate={{
                        createdAt: "date",
                        startDate: "dateTime",
                        endDate: "dateTime",
                        deadline: "dateTime",
                      }}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={freelancers?.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
