import React, { useEffect, useState } from "react";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const ViewOne = (props) => {
  const { id: feedbackId } = props.match.params;
  const [feedbackData, setFeedbackData] = useState([]);
  const { response: feedbackRes, request: feedbackReq } = useRequest();

  useEffect(() => {
    if (feedbackId) {
      feedbackReq("GET", `feedback/${feedbackId}`);
      document.title = "Feedback - Kura";
    }
  }, [feedbackId]);

  useEffect(() => {
    if (feedbackRes) {
      const obj = feedbackRes.feedback;
      setFeedbackData([
        { title: "Name", value: obj?.name ?? "-" },
        { title: "Email", value: obj?.email ?? "-" },
        { title: "Message", value: obj?.message ?? "-" },
      ]);
    }
  }, [feedbackRes]);

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="View Feedback"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/feedback" /*backPageNum: page */ },
            name: "Back To Feedbacks",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  {feedbackData.length > 0 &&
                    feedbackData.map((val, ind) => {
                      return (
                        <div key={ind} className="form-group row my-2">
                          <label className="col-4 col-form-label">
                            {val.title}
                          </label>
                          <div className="col-8">
                            <span className="form-control-plaintext font-weight-bolder">
                              {val.value}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOne;
