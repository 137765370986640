import React, { useState, useEffect } from "react";
import ApexChart from "react-apexcharts";
import moment from "moment";

import useRequest from "../../../hooks/useRequest";




const UserGenderChart = () => {
    const [typeOptions, setTypeOptions] = useState({
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return Math.round(val) + "%"
            },
        }
    }
    );
    const [statusOptions, setStatusOptions] = useState({
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return Math.round(val) + "%"
            },
        }
    }
    );

    const [typeSeries, setTypeSeries] = useState([]);
    const [statusSeries, setStatusSeries] = useState([]);

    const { response: typeRes, request: typeReq } = useRequest();
    const { response: statusRes, request: statusReq } = useRequest();


    useEffect(() => {
        typeReq("GET", `dashboard/bookstore-graph?by=type`);
        statusReq("GET", `dashboard/bookstore-graph?by=status`);
    }, []);

    useEffect(() => {
        if (typeRes) {
            let arr = typeRes.bookstore;

            let graphData = {};
            arr.forEach((val) => {
                graphData[val.name] = val.count
            })
            setTypeOptions(
                {
                    graphData,
                    labels: arr.map(val => `${val.name} ${val.count}`),
                }
            );
            setTypeSeries(arr.map(val => val.count));
        }
    }, [typeRes]);

    useEffect(() => {
        if (statusRes) {
            let arr = statusRes.bookstore;

            let graphData = {};
            arr.forEach((val) => {
                graphData[val.name] = val.count
            })
            setStatusOptions(
                {
                    graphData,
                    labels: arr.map(val => `${val.name} ${val.count}`)
                }
            );
            setStatusSeries(arr.map(val => val.count));
        }
    }, [statusRes]);


    return (
        <div className="row">
            <div className="col-xl-6">
                <div className="card card-custom gutter-b card-stretch gutter-b">
                    <div className="card-header h-auto border-0">
                        <div className="card-title py-5">
                            <h3 className="card-label">
                                <span className="d-block text-dark font-weight-bolder">
                                    Bookstores (category)
                                </span>
                            </h3>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* <div id="kt_charts_widget_5_chart"></div> */}
                                <ApexChart
                                    options={typeOptions}
                                    series={typeSeries}
                                    type="donut"
                                    height={350}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-6">
                <div className="card card-custom gutter-b card-stretch gutter-b">
                    <div className="card-header h-auto border-0">
                        <div className="card-title py-5">
                            <h3 className="card-label">
                                <span className="d-block text-dark font-weight-bolder">
                                    Bookstores (status)
                                </span>
                            </h3>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* <div id="kt_charts_widget_5_chart"></div> */}
                                <ApexChart
                                    options={statusOptions}
                                    series={statusSeries}
                                    type="donut"
                                    height={350}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserGenderChart;
