import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ADMINURL } from "../../constant/api";

import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { SearchInput, SearchSubmitButton } from "../Form/Form";
import { addOneToDate, intersect } from "../../util/fn";

const OBJ_TABLE = {
  location: "model",
  description: "description",
  "logged on": "createdAt",
};

const searchQueryHandler = (page, per_page, id) => {
  //   if (sortBy.length > 0) {
  //     if (sortBy == "registered on") {
  //       sortBy = "createdAt";
  //     } else if (sortBy === "phone number") {
  //       sortBy = "contact";
  //     }
  //   } else {
  //     sortBy = "createdAt";
  //   }
  //   order = order.length > 0 ? order : "desc";

  //   dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";

  //   dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());

  return `log/user?page=${page}&per_page=${per_page}&id=${id}`;
};

const ViewAll = () => {
  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "registered on",
    order: "desc",
  });

  const { id } = useParams();

  const MySwal = withReactContent(Swal);

  const { records_per_page } = useSelector((state) => state.setting);
  const { permissions, roleId } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    clearErrors,
    setError,
  } = useForm();

  const { request, response } = useRequest();
  const { request: requestChangeStatus, response: responseChangeStatus } =
    useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();
  const { request: requestSendCreds, response: responseSendCreds } =
    useRequest();
  const { request: loginRequest, response: loginResponce } = useRequest();
  const { request: enableAccountRequest, response: enableAccountResponce } =
    useRequest();

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request("GET", searchQueryHandler(1, records_per_page, id));
    }
    document.title = "Users logs - Kura";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      setLogs(response.logs);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  //   useEffect(() => {
  //     if (responseChangeStatus) {
  //       const { id, status } = responseChangeStatus;

  //       const oldSeekers = [...logs];
  //       const indexToChange = oldSeekers.findIndex((seeker) => seeker._id == id);
  //       oldSeekers[indexToChange].isActive = status;

  //       setLogs(oldSeekers);
  //     }
  //   }, [responseChangeStatus]);

  //   useEffect(() => {
  //     if (enableAccountResponce) {
  //       const { id, isAccountDisabled } = enableAccountResponce;

  //       const oldSeekers = [...logs];
  //       const indexToChange = oldSeekers.findIndex((seeker) => seeker._id == id);
  //       oldSeekers[indexToChange].isAccountDisabled = isAccountDisabled;

  //       setLogs(oldSeekers);
  //     }
  //   }, [enableAccountResponce]);

  //   useEffect(() => {
  //     if (responseDelete) {
  //       const { id } = responseDelete;
  //       let newSeeker = [...logs];
  //       newSeeker = newSeeker.filter((seeker) => seeker._id != id);
  //       setLogs(newSeeker);
  //       toast.success("User has been deleted successfully.");
  //     }
  //   }, [responseDelete]);

  //   useEffect(() => {
  //     if (responseSendCreds) {
  //       toast.success("Login credentials sent successfully");
  //     }
  //   }, [responseSendCreds]);

  //   useEffect(() => {
  //     if (loginResponce) {
  //       window.open(
  //         `http://192.168.235.200:3002?token=${loginResponce?.token}`,
  //         "_blank",
  //         "noopener,noreferrer"
  //       );
  //     }
  //   }, [loginResponce]);

  const fetchMoreData = ({ selected }) => {
    setLogs([]);

    setPage(selected + 1);
    request("GET", searchQueryHandler(selected + 1, perPage, id));
  };

  //   const deleteHandler = (id) => {
  //     MySwal.fire({
  //       title: "Are you sure?",
  //       text: "You want to delete this user?",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonText: "Yes, delete it",
  //       cancelButtonText: "No, cancel",
  //       reverseButtons: true,
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         requestDelete("DELETE", "user", { id });
  //       } else if (result.isDismissed) {
  //       }
  //     });
  //   };

  //   const onSearchHandler = (data) => {
  //     let {
  //       isActive,
  //       userId,
  //       name,
  //       email,
  //       city,
  //       isEmailVerified,
  //       contact,
  //       dateFrom,
  //       dateTo,
  //     } = data;

  //     if (dateFrom && dateTo) {
  //       if (Moment(dateFrom).isAfter(dateTo)) {
  //         setError("dateTo", {
  //           type: "manual",
  //         });
  //         return;
  //       }
  //     }

  //     request(
  //       "GET",
  //       searchQueryHandler(
  //         1,
  //         perPage,
  //         currentSort.sortBy,
  //         currentSort.order,
  //         isActive,
  //         userId,
  //         name,
  //         email,
  //         city,
  //         isEmailVerified,
  //         contact,
  //         dateFrom,
  //         dateTo
  //       )
  //     );
  //     setPage(1);
  //   };

  //   const onResetHandler = (e) => {
  //     e.preventDefault();
  //     resetField("isActive");
  //     resetField("firstName");
  //     resetField("lastName");
  //     resetField("email");
  //     resetField("contact");
  //     resetField("dateFrom");
  //     resetField("dateTo");
  //     request(
  //       "GET",
  //       searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
  //     );
  //     setPage(1);
  //   };

  const perPageChangeHandler = (event) => {
    request("GET", searchQueryHandler(1, event.target.value, id));
    setPage(1);
    setPerPage(event.target.value);
  };

  //   const sortingHandler = (sortBy) => {
  //     const {
  //       isActive,
  //       userId,
  //       name,
  //       email,
  //       city,
  //       isEmailVerified,
  //       contact,
  //       dateFrom,
  //       dateTo,
  //     } = getValues();

  //     if (currentSort.sortBy == sortBy) {
  //       const newOrder = currentSort.order === "asc" ? "desc" : "asc";
  //       request(
  //         "GET",
  //         searchQueryHandler(
  //           page,
  //           perPage,
  //           sortBy,
  //           newOrder,
  //           isActive,
  //           userId,
  //           name,
  //           email,
  //           city,
  //           isEmailVerified,
  //           contact,
  //           dateTo
  //         )
  //       );
  //       setCurrentSort({ sortBy, order: newOrder });
  //     } else {
  //       request(
  //         "GET",
  //         searchQueryHandler(
  //           page,
  //           perPage,
  //           sortBy,
  //           "desc",
  //           isActive,
  //           userId,
  //           name,
  //           email,
  //           city,
  //           isEmailVerified,
  //           contact,
  //           dateFrom,
  //           dateTo
  //         )
  //       );
  //       setCurrentSort({ sortBy, order: "desc" });
  //     }
  //   };

  //   const changeStatusHandler = (id) => {
  //     const user = logs.find((seeker) => seeker._id == id);
  //     const status = user.isActive == true ? false : true;

  //     MySwal.fire({
  //       title: "Are you sure?",
  //       text: "Want to change this status?",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonText: "Yes, change it",
  //       cancelButtonText: "No, cancel",
  //       reverseButtons: true,
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         requestChangeStatus("PUT", "user/status", {
  //           id,
  //           status,
  //         });
  //       } else if (result.isDismissed) {
  //       }
  //     });
  //   };

  //   const changeAccountDisableHandler = (id) => {
  //     const user = logs.find((seeker) => seeker._id == id);
  //     // const status = user.isAccountDisabled == false;

  //     MySwal.fire({
  //       title: "Are you sure?",
  //       text: "Want to Enable?",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonText: "Yes, change it",
  //       cancelButtonText: "No, cancel",
  //       reverseButtons: true,
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         enableAccountRequest("PUT", "user/enable-user", {
  //           id,
  //         });
  //       } else if (result.isDismissed) {
  //       }
  //     });
  //   };

  //   const sendCredentials = (id) => {
  //     requestSendCreds("POST", "user/send-credentials", { id });
  //   };

  //   const loginUser = (id) => {
  //     loginRequest("POST", "admin/login-user", { id });
  //   };

  //   const InputFields = [

  //   ];

  // console.log(logs)

  const openLinkHandler = (id, data) => {
    if (data.url) {
      window.open(`${ADMINURL}${data.url}`, "_blank");
    }
  };

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb title="Logs" links={[{ to: "/", name: "Dashboard" }]} />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                {/* <div className="card-header">
                                    <div className="card-title"></div>
                                    <div className="card-toolbar">
                                    </div>
                                </div> */}
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    {/* <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div> */}
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={() => {}}
                      mainData={logs}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      renderAs={
                        {
                          // isEmailVerified: (val) =>
                          //   val.toString().charAt(0).toUpperCase() +
                          //   val.toString().slice(1),
                          // isAccountDisabled: (val) =>
                          //   val.toString().charAt(0).toUpperCase() +
                          //   val.toString().slice(1),
                          // name: (val) => val ?? "-",
                          // email: (val) => val ?? "-",
                          // contact: (val) => val ?? "-",
                        }
                      }
                      links={[
                        {
                          isLink: false,
                          name: "SendCreds",
                          click: openLinkHandler,
                          title: "View",
                          show: (data) => data.url,
                          //   access: ["c8"],
                        },
                      ]}
                      onlyDate={{
                        createdAt: "date",
                        startDate: "dateTime",
                        endDate: "dateTime",
                      }}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={logs.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
