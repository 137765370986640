import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import "../Login/Login.css";
import useRequest from "../../hooks/useRequest";
import { authSuccess } from "../../store/auth/action";
import { addSetting } from "../../store/setting/action";
import { GoogleIcon } from "../../util/Svg";

const SetCredential = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const { search } = useLocation();
  let queries = search.split("?token=")[1];
  let token = queries.split("&userTypeSet=")[0];
  let userTypeSet = queries.split("&userTypeSet=")[1];

  const dispatch = useDispatch();
  const [isTypeAssigned, setIsTypeAssigned] = useState(
    userTypeSet && userTypeSet !== "NA" ? true : false
  );
  const { response, request } = useRequest();
  const { response: getSchoolsResponse, request: getSchoolsRequest } =
    useRequest();
  const [type, setType] = useState("");
  const [schools, setSchools] = useState("");
  const [selectSchool, setSelectSchool] = useState("");
  const [locationError, setLocationError] = useState("");
  const [schoolError, setSchoolError] = useState("");
  const [typeError, setTypeError] = useState("");

  useEffect(() => {
    document.title = "Login - Kura";
    getSchoolsRequest("GET", "admin/schools");
    if (userTypeSet && userTypeSet !== "NA") {
      setType(userTypeSet);
    }
  }, []);

  useEffect(() => {
    if (getSchoolsResponse) {
      setSchools(getSchoolsResponse?.data);
    }
  }, [getSchoolsResponse]);

  useEffect(() => {
    if (response) {
      if (response.status == true) {
        toast.success(response.message);
        setTypeError("");
        setSchoolError("");
        setLocationError("");
        setTimeout(() => {
          window.close();
        }, 2000);
      } else {
        toast.error("Could not set credentials");
      }
    }
  }, [response]);

  const onSubmit = (data) => {
    console.log(type);
    if (type == "") {
      setTypeError("type field is required");
    } else if (type == "non-student" && data.address == "") {
      setLocationError("address field is required");
      setSchoolError("");
      setTypeError("");
    } else if (type == "student" && selectSchool == "") {
      setSchoolError("school field is required");
      setLocationError("");
      setTypeError("");
    } else {
      data["userType"] = type;
      data["schools"] = selectSchool;
      data["token"] = token;
      data["isTypeAssigned"] = isTypeAssigned;
      request("POST", "user/set-credentials", data);
    }
  };

  console.log(errors.contact)

  return (
    <div className="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid">
      <div className="login-container d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid p-8">
        <div className="login-content d-flex flex-column card p-5 p-md-10">
          <a href="#!" className="login-logo pb-8 text-center">
            <img src="./logo.png" className="max-h-50px" alt="" />
          </a>

          <div className="login-form">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="form"
              id="kt_login_singin_form"
            >
              {/* <div className="pb-5 pb-lg-15">
                <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
                  Sign In
                </h3>
                <div className="text-muted font-weight-bold font-size-h4">
                                    New Here?
                                    <a href="custom/pages/login/login-4/signup.html"
                                        className="text-primary font-weight-bolder">Create Account</a>
                                </div>
              </div> */}
              <div className="col-12">
                <div className="row">
                  {!isTypeAssigned && (
                    <div className="col-6">
                      <div className="form-group">
                        <label className="font-size-h6 font-weight-bolder text-dark">
                          Uset Type
                        </label>
                        <select
                          className="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0"
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option value="">Select Type</option>
                          <option value="Student">Student</option>
                          <option value="Non-Student">Non-Student</option>
                        </select>
                        <div>
                          {typeError && typeError.length > 0 ? (
                            <span style={{ color: "red" }}>{typeError}</span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-6">
                    <div className="form-group">
                      <div className="d-flex justify-content-between mt-n5">
                        <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                          Phone Number
                        </label>
                      </div>
                      <input
                        className={`form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0 ${
                          errors.contact && "is-invalid" /*: "is-valid"*/
                        }`}
                        // className='form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0 '
                        type="text"
                        name="contact"
                        autoComplete="off"
                        placeholder="Phone Number"
                        {...register("contact", {
                          required: true,
                          pattern: {
                            // value: /^(?:(?:(?:\+?234(?:\h1)?|01)\h*) ?(?:\(\d{3}\)|\d{3})|\d{4})(?:\W*\d{3})?\W*\d{4}$/,
                            value: /^(?:(?:(?:\+?234(?:\h1)?|01)\h*) ?(?:\(\d{3}\)|\d{10})|\d{4})(?:\W*\d{3})?\W*\d{4}$/,
                            message: "Invalid phone number",
                          },
                        })}
                      />
                      {errors.contact?.type === "required" && (
                        <div className="invalid-feedback">
                          The contact field is required.
                        </div>
                      )}
                      {errors.contact?.type === "pattern" && (
                        <div className="invalid-feedback">
                          {errors.contact.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <div className="d-flex justify-content-between mt-n5">
                        <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                          School
                        </label>
                      </div>
                      <select
                        className="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0"
                        onChange={(e) => {
                          setSelectSchool(e.target.value);
                        }}
                      >
                        <option value="">Select School</option>
                        {schools &&
                          schools.map((item) => {
                            return (
                              <option value={item._id}>{item.name}</option>
                            );
                          })}
                      </select>
                      <div>
                        {schoolError && schoolError.length > 0 ? (
                          <span style={{ color: "red" }}>{schoolError}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <div className="d-flex justify-content-between mt-n5">
                        <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                          Address
                        </label>
                      </div>
                      <input
                        className="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0"
                        type="text"
                        name="address"
                        autoComplete="off"
                        placeholder="Address"
                        {...register("address", {
                          // required: true,
                        })}
                      />
                      <div>
                        {console.log(locationError)}
                        {locationError && locationError.length > 0 ? (
                          <span style={{ color: "red" }}>{locationError}</span>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* {errors.address?.type === "required" && (
                        <div className="invalid-feedback">
                          The address field is required.
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <div className="d-flex justify-content-between mt-n5">
                        <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                          Password
                        </label>
                      </div>
                      <input
                        className={`form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0 ${
                          errors.password && "is-invalid" /*: "is-valid"*/
                        }`}
                        type="password"
                        name="password"
                        autoComplete="off"
                        placeholder="Password"
                        {...register("password", {
                          required: true,
                          pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
                        })}
                      />
                      {errors.password?.type === "required" && (
                        <div className="invalid-feedback">
                          The password field is required.
                        </div>
                      )}
                      {errors.password?.type === "pattern" && (
                        <div className="invalid-feedback">
                          Password must be of 8 characters long with atleast one
                          uppercase, one lowercase and one number.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <div className="d-flex justify-content-between mt-n5">
                        <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                          Confirm Password
                        </label>
                      </div>
                      <input
                        className={`form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0 ${
                          errors.confirm_password &&
                          "is-invalid" /*: "is-valid"*/
                        }`}
                        type="password"
                        name="confirm_password"
                        autoComplete="off"
                        placeholder="confirm_password"
                        {...register("confirm_password", {
                          required: true,
                          pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
                        })}
                      />
                      {errors.confirm_password?.type === "required" && (
                        <div className="invalid-feedback">
                          The confirm password field is required.
                        </div>
                      )}
                      {errors.confirm_password?.type === "pattern" && (
                        <div className="invalid-feedback">
                          confirm password must be of 8 characters long with
                          atleast one uppercase, one lowercase and one number.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="form-group">
                <label className="font-size-h6 font-weight-bolder text-dark">
                  Your Email
                </label>

                <input
                  className={`form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0 ${
                    errors.email && "is-invalid"
                  }`}
                  type="text"
                  name="username"
                  autoComplete="off"
                  placeholder="Email"
                  {...register("email", {
                    required: true,
                    pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  })}
                />
                {errors.email?.type === "required" && (
                  <div className="invalid-feedback">
                    The email field is required.
                  </div>
                )}
                {errors.email?.type === "pattern" && (
                  <div className="invalid-feedback">
                    The email must be a valid email address.
                  </div>
                )}
              </div> */}

              <div className="pb-lg-0 pb-5">
                <button
                  onClick={handleSubmit(onSubmit)}
                  style={{ display: "none" }}
                ></button>
                <a
                  onClick={handleSubmit(onSubmit)}
                  href="#!"
                  id="kt_login_submit"
                  className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                >
                  Update
                </a>
                {/* <button
                  type="button"
                  id="kt_login_google"
                  className="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"
                >
                  <span className="svg-icon svg-icon-md">
                    <GoogleIcon />
                  </span>{" "}
                  Sign in with Google
                </button> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetCredential;
