import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Textarea,
  RenderInputFields,
  SubmitButton,
  Input,
  CKEditorInput,
} from "../Form/Form";

const Edit = (props) => {
  const { id: faqId } = props.match.params;
  const history = useHistory();
  const ckEditorRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    reset,
    setError,
    clearErrors,
  } = useForm();

  const { response: responseFetchFaq, request: requestFetchFaq } = useRequest();

  const { response, request } = useRequest();

  useEffect(() => {
    if (faqId) {
      requestFetchFaq("GET", `faq/${faqId}`);
      document.title = "Edit Faq - Kura";
    }
  }, [faqId]);

  useEffect(() => {
    if (responseFetchFaq) {
      const { question, answer } = responseFetchFaq.faq;
      // setValue("question", question);
      // setValue("answer", answer);

      reset({ question, answer });
    }
  }, [responseFetchFaq]);

  useEffect(() => {
    if (response) {
      toast.success("FAQ has been updated successfully.");
      history.push("/faq");
    }
  }, [response]);

  const onSubmit = (data) => {
    const { question, answer } = data;

    if (answer.length === 0) {
      setError("answer", {
        type: "manual",
      });
      return;
    }

    request("PUT", "faq", {
      question,
      answer,
      id: faqId,
    });
  };

  const InputFields = [
    [
      {
        Component: Input,
        type: "text",
        label: "Question",
        name: "question",
        colClass: "col-lg-12",
        registerFields: {
          required: true,
        },
      },
    ],
    [
      // {
      //   Component: Textarea,
      //   label: "Answer",
      //   name: "answer",
      //   colClass: "col-lg-12",
      //   registerFields: {
      //     required: true,
      //   },
      // },
      {
        Component: CKEditorInput,
        colClass: "col-xl-12",
        label: "Answer",
        name: "answer",
        registerFields: {
          required: true,
        },
        otherRegisterFields: {
          manual: true,
          feedback: "Answer is required",
        },

        inputData: {
          onInstanceReady: (editor) => {
            ckEditorRef.current = editor;
          },
        },
        getValues,
        setValue,
        trigger,
        clearErrors,
        isEdit: true,
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Faq"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/faq" },
            name: "Back To FAQs",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">Edit Faq</h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
