import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import {
  SearchInput,
  SearchSubmitButton,
  Input,
  RenderInputFields,
  SubmitButton,
  ExportButton,
} from "../Form/Form";
import {
  addOneToDate,
  downloadExcel,
  downloadPDF,
  intersect,
} from "../../util/fn";
import { useHistory } from "react-router-dom";
import moment from "moment";

const OBJ_TABLE = {
  "scholarship Id": "scholarshipId",
  Category: "type",
  Name: "name",
  // Title: "title",
  Eligiblity: "eligibility",
  Expired: "deadline",
  "Created At": "createdAt",
  status: "isActive",
  Reported: "isReported",
};

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  isActive = "",
  type = "",
  name = "",
  title = "",
  eligibility = "",
  expired = "",
  dateFrom = "1970-01-01",
  dateTo,
  scholarshipId = ""
) => {
  if (sortBy.length > 0) {
    sortBy = OBJ_TABLE[sortBy];
  } else {
    sortBy = "updatedAt";
  }

  dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";

  dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());
  order = order.length > 0 ? order : "asc";

  return `scholarship/all?page=${page}&per_page=${per_page}&sortBy=${sortBy}&order=${order}&isActive=${isActive}&type=${type}&name=${name}&title=${title}&eligibility=${eligibility}&expired=${expired}&dateFrom=${dateFrom}&dateTo=${dateTo}&scholarshipId=${scholarshipId}`;
};

const ViewAllScholarship = () => {
  const history = useHistory();

  const [scholarship, setScholarship] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "",
    order: "desc",
  });
  const [showModal, setShowModal] = useState(false);
  const [viewModalData, setViewModalData] = useState({ id: "", newDate: "" });

  const [scholarshipTypes, setScholarshipTypes] = useState([]);

  const MySwal = withReactContent(Swal);

  const { records_per_page, date_format } = useSelector(
    (state) => state.setting
  );
  const { permissions, roleId } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    clearErrors,
    setError,
  } = useForm();

  const { request, response } = useRequest();
  const { request: requestChangeStatus, response: responseChangeStatus } =
    useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();
  const { request: requestSendCreds, response: responseSendCreds } =
    useRequest();
  const { request: loginRequest, response: loginResponce } = useRequest();

  const { response: typesRes, request: typesReq } = useRequest();
  const { response: renewRes, request: renewReq } = useRequest();

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      typesReq("GET", "admin/scholarship-types");
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = "Scholarship - Kura";
  }, [records_per_page]);

  useEffect(() => {
    if (typesRes && typesRes?.status) {
      setScholarshipTypes(typesRes.types);
    }
  }, [typesRes]);

  useEffect(() => {
    if (response) {
      setScholarship(response.scholarship);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  useEffect(() => {
    if (responseChangeStatus) {
      const { id, status } = responseChangeStatus;

      const oldScholarship = [...scholarship];
      const indexToChange = oldScholarship.findIndex(
        (scholar) => scholar._id == id
      );
      oldScholarship[indexToChange].isActive = status;

      setScholarship(oldScholarship);
    }
  }, [responseChangeStatus]);

  useEffect(() => {
    if (responseDelete) {
      const { id } = responseDelete;
      let newScholarship = [...scholarship];
      newScholarship = newScholarship.filter((scholar) => scholar._id != id);
      setScholarship(newScholarship);
      toast.success(responseDelete.message);
    }
  }, [responseDelete]);

  useEffect(() => {
    if (responseSendCreds) {
      toast.success("Login credentials sent successfully");
    }
  }, [responseSendCreds]);

  useEffect(() => {
    if (loginResponce) {
      window.open(
        `http://192.168.235.200:3002?token=${loginResponce?.token}`,
        "_blank",
        "noopener,noreferrer"
      );
    }
  }, [loginResponce]);

  const fetchMoreData = ({ selected }) => {
    setScholarship([]);
    const {
      isActive,
      type,
      name,
      title,
      eligibility,
      expired,
      dateFrom,
      dateTo,
      scholarshipId,
    } = getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        type,
        name,
        title,
        eligibility,
        expired,
        dateFrom,
        dateTo,
        scholarshipId
      )
    );
  };

  const deleteHandler = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You want to delete this scholarship?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestDelete("DELETE", "scholarship", { id });
      } else if (result.isDismissed) {
      }
    });
  };

  const onSearchHandler = (data) => {
    let {
      isActive,
      type,
      name,
      title,
      eligibility,
      expired,
      dateFrom,
      dateTo,
      scholarshipId,
    } = data;

    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        type,
        name,
        title,
        eligibility,
        expired,
        dateFrom,
        dateTo,
        scholarshipId
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("isActive");
    resetField("firstName");
    resetField("lastName");
    resetField("email");
    resetField("contact");
    resetField("dateFrom");
    resetField("dateTo");
    resetField("scholarshipId");
    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const {
      isActive,
      type,
      name,
      title,
      eligiblity,
      expired,
      dateFrom,
      dateTo,
      scholarshipId,
    } = getValues();
    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        type,
        name,
        title,
        eligiblity,
        expired,
        dateFrom,
        dateTo,
        scholarshipId
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    const {
      isActive,
      type,
      name,
      title,
      eligiblity,
      expired,
      dateFrom,
      dateTo,
      scholarshipId,
    } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          newOrder,
          isActive,
          type,
          name,
          title,
          eligiblity,
          expired,
          dateFrom,
          dateTo,
          scholarshipId
        )
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          "desc",
          isActive,
          type,
          name,
          title,
          eligiblity,
          expired,
          dateFrom,
          dateTo,
          scholarshipId
        )
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  const changeStatusHandler = (id) => {
    const scholars = scholarship.find((scholar) => scholar._id == id);
    const status = scholars.isActive == true ? false : true;

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestChangeStatus("PUT", "scholarship/status", {
          id,
          status,
        });
      } else if (result.isDismissed) {
      }
    });
  };

  //   const sendCredentials = (id) => {
  //     requestSendCreds("POST", "user/send-credentials", { id });
  //   };

  const loginUser = (id) => {
    loginRequest("POST", "admin/login-user", { id });
  };

  const viewReportHandler = (id) => {
    console.log("id>>>>>", id);
    history.push(`/report/viewlist/scholarship/${id}`);
  };

  const InputFields = [
    {
      label: "Name",
      name: "name",
      required: false,
    },
    {
      label: "Title",
      name: "title",
      required: false,
    },
    {
      label: "Eligiblity",
      name: "eligibility",
      required: false,
    },
    {
      label: "Scholarship Id",
      name: "scholarshipId",
      required: false,
    },
    {
      label: "Type",
      name: "type",
      required: false,
      isSelectInput: true,
      children: (
        <>
          <option value="">Select</option>
          {scholarshipTypes.length > 0 &&
            scholarshipTypes.map((type, i) => {
              return (
                <option key={i} value={type.type}>
                  {type.type}
                </option>
              );
            })}
        </>
      ),
    },
    {
      label: "Expired",
      name: "expired",
      required: false,
      isSelectInput: true,
      children: (
        <>
          <option value="">Select</option>
          <option value={false}>Expired</option>
          <option value={true}>Not Expired</option>
        </>
      ),
    },

    {
      label: "Status",
      name: "isActive",
      required: false,
      isSelectInput: true,
      children: (
        <>
          <option value="">Select</option>
          <option value={true}>Activated</option>
          <option value={false}>Deactivated</option>
        </>
      ),
    },

    {
      label: "Date From",
      name: "dateFrom",
      isDate: true,
      clearErrors,
    },
    {
      label: "Date To",
      name: "dateTo",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback: "'To Date' cannot be smaller than 'From Date'",
      },
    },
  ];

  const viewModalHandler = (id, data) => {
    setViewModalData({ id });
    setShowModal(true);
  };

  const onSubmit = (data) => {
    let { newDate } = data;

    if (!newDate || new Date(newDate) <= new Date()) {
      setError("newDate", {
        type: "manual",
        message: "Cannot be less then today's date",
      });
      return;
    }
    setShowModal(false);
    if (!viewModalData.id) {
      toast.error("Insufficient data provided.");
    }

    renewReq("PUT", "scholarship/renew", {
      id: viewModalData.id,
      newDate,
    });
  };

  useEffect(() => {
    if (renewRes) {
      const { id, newDate } = renewRes;

      setCurrentSort({ sortBy: "", order: "desc" });
      setPage(1);
      request("GET", searchQueryHandler(1, perPage, "", "desc"));
      return;

      const oldScholarship = [...scholarship];
      const indexToChange = oldScholarship.findIndex(
        (scholar) => scholar._id == id
      );
      oldScholarship[indexToChange].deadline = newDate;

      setScholarship(oldScholarship);
    }
  }, [renewRes]);

  const modelInputFields = [
    [
      {
        Component: Input,
        label: "Deadline",
        type: "date",
        name: "newDate",
        registerFields: {
          required: false,
        },
        otherRegisterFields: {
          manual: true,
          feedback: "Cannot be less then today's date",
        },
        isDate: true,
      },
    ],
  ];

  const getDownloadData = () => {
    let data = JSON.parse(JSON.stringify(scholarship));

    data = data.map((item) => {
      return {
        "Scholarship Id": item.scholarshipId,
        Category: item.type,
        Name: item.name,
        Eligiblity: item.eligibility,
        Expired:
          new Date(item.deadline) <= new Date() ? "Expired" : "Not Expired",
        "Created At": moment(item.createdAt).format(date_format),
        Status: item.isActive ? "Active" : "Inactive",
        Reported: item.isReported ? "True" : "False",
      };
    });

    return data;
  };

  const downloadExcelHandler = () => {
    const data = getDownloadData();

    downloadExcel(data, "Scholarship");
  };

  const generatePdfDocument = async () => {
    const data = getDownloadData();

    const headings = data.length > 0 ? Object.keys(data[0]) : [];

    await downloadPDF(headings.length, headings, data, "Scholarship");
  };

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Scholarship"
        links={[{ to: "/", name: "Dashboard" }]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <ExportButton
                      downloadExcelHandler={downloadExcelHandler}
                      downloadPdfHandler={generatePdfDocument}
                    />

                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>

                    {(roleId === 1 ||
                      (roleId === 2 &&
                        permissions &&
                        intersect(permissions, ["b4"]))) && (
                      <Link to="/scholarship/add" className="btn btn-primary">
                        Add New Scholarship
                      </Link>
                    )}
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={scholarship}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      renderAs={{
                        deadline: (val) =>
                          new Date(val) <= new Date() ? (
                            <span
                              style={{
                                height: "fit-content",
                                padding: "5px 10px",
                              }}
                              className="label label-lg label-light-danger label-inline"
                            >
                              Expired
                            </span>
                          ) : (
                            <span
                              style={{
                                height: "fit-content",
                                padding: "5px 10px",
                              }}
                              className="label label-lg label-light-success label-inline"
                            >
                              Not Expired
                            </span>
                          ),
                        isReported: (val) => (val ? "True" : "False"),
                      }}
                      links={[
                        // {
                        //   isLink: true,
                        //   to: `/wallet/view`,
                        //   name: "Wallet",
                        //   extraData: true,
                        // },
                        {
                          isLink: false,
                          name: "Deactivate",
                          click: changeStatusHandler,
                          title: "Click To Activate",
                          access: ["b3"],
                        },
                        {
                          isLink: false,
                          name: "Activate",
                          click: changeStatusHandler,
                          title: "Click To Deactivate",
                          access: ["b3"],
                        },
                        // {
                        //   isLink: true,
                        //   to: "/scholarship/view",
                        //   name: "View",
                        //   extraData: true,
                        // },
                        {
                          isLink: true,
                          to: "/scholarship/edit",
                          name: "Edit",
                          extraData: true,
                          access: ["b5"],
                        },
                        {
                          isLink: false,
                          name: "Delete",
                          click: deleteHandler,
                          access: ["b6"],
                        },
                        {
                          isLink: false,
                          name: "Renew",
                          click: viewModalHandler,
                          title: "Click To Renew",
                          access: ["b7"],
                        },
                        {
                          isLink: false,
                          name: "Report",
                          click: viewReportHandler,
                          title: "View report",
                          access: ["b8"],
                        },
                      ]}
                      onlyDate={{
                        createdAt: "date",
                        startDate: "dateTime",
                        endDate: "dateTime",
                        // deadline: "dateTime",
                      }}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={scholarship.length}
                      />
                    )}
                    <Modal
                      isOpen={showModal}
                      ariaHideApp={false}
                      className="model_block"
                      onRequestClose={() => setShowModal(false)}
                    >
                      <div
                        className="modal-dialog modal-dialog-centered modal-lg"
                        role="document"
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Select new deadline</h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={() => setShowModal(false)}
                            >
                              <i aria-hidden="true" className="ki ki-close"></i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <RenderInputFields
                                InputFields={modelInputFields}
                                errors={errors}
                                register={register}
                              />

                              <div className="row"></div>

                              <SubmitButton
                                handleSubmit={handleSubmit}
                                onSubmit={onSubmit}
                                name="Update"
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAllScholarship;
