import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Input, RenderInputFields, SubmitButton } from "../Form/Form";

const Edit = () => {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  const { response, request } = useRequest();
  const { response: editRes, request: editReq } = useRequest();

  const history = useHistory();

  useEffect(() => {
    editReq("GET", `master/city/${id}`);
    document.title = "Edit City- Kura";
  }, []);

  useEffect(() => {
    if (editRes) {
      reset({       
        city: editRes?.city?.city || editRes?.city?.name,
        town: editRes?.city?.town,
        council: editRes?.city?.council,
        state: editRes?.city?.state,
        country: editRes?.city?.country,
      });
    }
  }, [editRes]);

  useEffect(() => {
    if (response) {
      toast.success("City has been updated successfully.");
      history.push("/city");
    }
  }, [response]);

  const onSubmit = (data) => {
    for (let a in data) {
      data[a] = data[a] ? data[a] : undefined;
    }

    const { city, town, council, state, country } = data;

    request("PUT", "master/city", {
      id,      
      city,
      town,
      council,
      state,
      country,
    });
  };

  const InputFields = [
    [      
      {
        Component: Input,
        label: "City",
        type: "text",
        name: "city",
        registerFields: {
          // required: true,
        },
      },
      {
        Component: Input,
        label: "Town",
        type: "text",
        name: "town",
        registerFields: {
          // required: true,
        },
      },
      {
        Component: Input,
        label: "L.G.A/Council",
        type: "text",
        name: "council",
        registerFields: {
          // required: true,
        },
      },
      {
        Component: Input,
        label: "State",
        type: "text",
        name: "state",
        registerFields: {
          // required: true,
        },
      },
      {
        Component: Input,
        label: "Country",
        type: "text",
        name: "country",
        registerFields: {
          // required: true,
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit City"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/city", name: "Back To City" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Edit City
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
