import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const intersect = (arr1, arr2) => {
  for (let i = 0; i < arr1.length; i++) {
    for (let j = 0; j < arr2.length; j++) {
      if (arr1[i] === arr2[j]) {
        return true
      }
    }
  }
  return false
}


const ZeroLevel = ({ menu, pathname }) => {
  const [active, setActive] = useState(false);

  const { name, Svg, path, highlight, subHighlight, access } = menu;

  const { permissions, roleId } = useSelector((state) => state.auth);

  const updateActive = () => {
    setActive((prev) => !prev);
  };

  useEffect(() => {
    if (
      path == pathname ||
      highlight.includes(pathname) ||
      subHighlight.includes(pathname.split("/").slice(0, 3).join("/"))
    ) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [pathname]);

  if (roleId === 2 && !intersect(permissions, access)) {
    return null;
  }

  return (
    <li
      onClick={updateActive}
      className={`menu-item ${active ? "menu-item-active" : ""}`}
    >
      <Link to={path} className={`menu-link`}>
        <span className="svg-icon menu-icon">
          <Svg />
        </span>
        <span className="menu-text">{name}</span>
      </Link>
    </li>
  );
};

export default ZeroLevel;
