import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  RenderInputFields,
  SubmitButton,
  SelectInput,
} from "../Form/Form";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    unregister,
  } = useForm();

  const [unlimitedPostsSelected, setUnlimitedPostsSelected] = useState(false);
  const [unlimitedEditsSelected, setUnlimitedEditsSelected] = useState(false);
  const [unlimitedMessageSelected, setUnlimitedMessageSelected] =
    useState(false);
  const [id, setId] = useState(0);
  const [features, setFeatures] = useState([
    {
      id,
    },
  ]);

  const { response, request } = useRequest();

  const history = useHistory();

  useEffect(() => {
    document.title = "Add subscription plan - Kura";
  }, []);

  useEffect(() => {
    if (response) {
      toast.success("Plan added successfully.");
      history.push("/subscription-plan");
    }
  }, [response]);

  const onSubmit = (data) => {
    const { name, price, duration, messages, isDefault, isPopular } = data;

    let arr = [];
    for (let i = 0; i < features.length; i++) {
      const feature = data[`features-${features[i].id}`];
      arr.push(feature);
    }

    request("POST", "subscription-plan", {
      name,
      features: arr,
      price,
      duration,
      isUnlimitedMessages: unlimitedMessageSelected,
      messages,
      isDefault,
      isPopular
    });
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "Name",
        type: "text",
        name: "name",
        registerFields: {
          required: true,
        },
      },
      {
        Component: Input,
        label: "Price",
        type: "number",
        name: "price",
        registerFields: {
          required: true,
        },
      },
      {
        Component: SelectInput,
        label: "Duration (months)",
        name: "duration",
        registerFields: {
          required: true,
        },
        children: (
          <>
            <option value={undefined}>Select</option>
            <option value={0}>0 month</option>
            <option value={1}>1 month</option>
            <option value={3}>3 months</option>
            {/* <option value={6}>6 month</option> */}
            <option value={12}>1 year</option>
            <option value={1200}>Unlimited</option>
          </>
        ),
      },
      {
        Component: Input,
        label: "Default Plan",
        type: "checkbox",
        name: "isDefault",
        registerFields: {
          required: false,
        },
      },
      {
        Component: Input,
        label: "Make Popular",
        type: "checkbox",
        name: "isPopular",
        registerFields: {
          required: false,
        },
      },
    ],
  ];

  const featureAddHandler = () => {
    setId((prev) => prev + 1);
    setFeatures((prev) => [...prev, { id: id + 1 }]);
  };

  const featureDeleteHandler = (id) => {
    let newOldArr = [...features];

    newOldArr.forEach((data) => {
      if (data.id == id) {
        unregister(`features-${id}`);
      }
    });

    let newArr = newOldArr.filter((data) => data.id != id);

    setFeatures(newArr);
  };

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add Subscription Plan"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/subscription-plan", name: "Back To Subscription Plans" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Add New Subscription Plan
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="form-group col-xl-6">
                      <label>Messages</label>
                      <br />
                      <input
                        type="radio"
                        className="btn btn-primary ml-2 mb-2"
                        name="isUnlimitedMessages"
                        // value={true}
                        // {...register('isUnlimitedMessages')}
                        onChange={(e) => {
                          setUnlimitedMessageSelected(!e.target.checked);
                        }}
                        checked={!unlimitedMessageSelected}
                      />{" "}
                      Limited
                      <input
                        type="radio"
                        className="btn btn-primary ml-2 mb-2"
                        name="isUnlimitedMessages"
                        // value={false}
                        // {...register('isUnlimitedMessages')}
                        onChange={(e) => {
                          setUnlimitedMessageSelected(e.target.checked);
                        }}
                      />{" "}
                      Unlimited
                      <br />
                      {!unlimitedMessageSelected && (
                        <input
                          type="number"
                          className="form-control form-control-solid form-control-lg"
                          name="messages"
                          placeholder="Enter message count"
                          {...register("messages")}
                        />
                      )}
                    </div>

                    <div className="row"></div>
                    <div className="form-group col-xl-6">
                      <label htmlFor="features">Features</label>
                      <button
                        type="button"
                        onClick={featureAddHandler}
                        className="btn btn-primary ml-2 mb-2"
                      >
                        Add{" "}
                      </button>
                      {features &&
                        features.map((data) => (
                          <div key={data.id} className="d-flex mb-2">
                            <div className="d-flex">
                              <input
                                id="features"
                                className="form-control form-control-solid form-control-lg"
                                {...register(`features-${data.id}`, {
                                  required: true,
                                })}
                              />
                              {errors &&
                                errors[`features-${data.id}`]?.type ==
                                  "required" && (
                                  <span style={{ color: "#F64E60" }}>
                                    This field is required
                                  </span>
                                )}
                            </div>
                            <button
                              type="button"
                              onClick={() => featureDeleteHandler(data.id)}
                              className="btn btn-danger ml-2"
                            >
                              Delete
                            </button>
                          </div>
                        ))}
                    </div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
