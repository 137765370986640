import React, { useState, useEffect } from "react";
import ApexChart from "react-apexcharts";
import moment from "moment";

import useRequest from "../../../hooks/useRequest";

const UserGenderChart = () => {
  const [options, setOptions] = useState({
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.round(val) + "%";
      },
    },
  });
  const [studentOptions, setStudentOptions] = useState({
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.round(val) + "%";
      },
    },
  });
  const [ageOptions, setAgeOptions] = useState({
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.round(val) + "%";
      },
    },
  });
  const [degreeOptions, setDegreeOptions] = useState({
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.round(val) + "%";
      },
    },
  });
  const [courseLevelOptions, setCourseLevelOptions] = useState({
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.round(val) + "%";
      },
    },
  });

  const [series, setSeries] = useState([]);
  const [studentSeries, setStudentSeries] = useState([]);
  const [ageSeries, setAgeSeries] = useState([]);
  const [degreeSeries, setDegreeSeries] = useState([]);
  const [courseLevelSeries, setCourseLevelSeries] = useState([]);

  const [totalGenders, setTotalGender] = useState(0);
  const [totalStudents, setTotalStudents] = useState(0);
  const [totalAge, setTotalAge] = useState(0);
  const [totalDegree, setTotalDegree] = useState(0);
  const [totalCourseLevel, setTotalCourseLevel] = useState(0);

  const { response, request } = useRequest();
  const { response: responseStudent, request: requestStudent } = useRequest();
  const { response: responseAge, request: requestAge } = useRequest();
  const { response: responseDegree, request: requestDegree } = useRequest();
  const { response: responseCourseLevel, request: requestCourseLevel } =
    useRequest();

  useEffect(() => {
    request("GET", `dashboard/user-graph?by=gender`);
    requestStudent("GET", `dashboard/user-graph?by=student`);
    requestAge("GET", `dashboard/user-graph?by=age`);
    requestDegree("GET", `dashboard/user-graph?by=degree`);
    requestCourseLevel("GET", `dashboard/user-graph?by=courseLevel`);
  }, []);

  useEffect(() => {
    if (response) {
      let graphData = response.user;

      let extraLables = [];
      let extraSeriesData = [];

      if (graphData.otherCount > 0) {
        extraLables.push(`Unspecified  ${graphData.otherCount}`);
        extraSeriesData.push(graphData.otherCount);
      }

      setOptions({
        graphData,
        labels: [
          `Male  ${graphData.maleCount}`,
          `Female  ${graphData.femaleCount}`,
          ...extraLables
        ],
        // colors: ["#771100"],
      });
      setTotalGender(graphData.maleCount + graphData.femaleCount);

      setSeries([graphData.maleCount, graphData.femaleCount, ...extraSeriesData]);
    }
  }, [response]);

  useEffect(() => {
    if (responseStudent) {
      let graphStudentData = responseStudent.user;

      let extraLables = [];
      let extraSeriesData = [];

      if (graphStudentData.otherCount > 0) {
        extraLables.push(`Unspecified  ${graphStudentData.otherCount}`);
        extraSeriesData.push(graphStudentData.otherCount);
      }

      setStudentOptions({
        graphStudentData,
        labels: [
          `Student  ${graphStudentData.studentCount}`,
          `Non - Student  ${graphStudentData.nonStudentCount}`,
          ...extraLables,
        ],
      });
      setTotalStudents(
        graphStudentData.studentCount +
          graphStudentData.nonStudentCount +
          graphStudentData.otherCount
      );

      setStudentSeries([
        graphStudentData.studentCount,
        graphStudentData.nonStudentCount,
        ...extraSeriesData,
      ]);
    }
  }, [responseStudent]);

  useEffect(() => {
    if (responseAge) {
      let arr = responseAge.user;

      let graphData = {};
      arr.forEach((val) => {
        graphData[val.name] = val.count;
      });
      setAgeOptions({
        graphData,
        labels: arr.map((val) => `${val.name} ${val.count}`),
      });
      setAgeSeries(arr.map((val) => val.count));
    }
  }, [responseAge]);

  useEffect(() => {
    if (responseDegree) {
      let arr = responseDegree.user;

      let graphData = {};
      arr.forEach((val) => {
        graphData[val.name] = val.count;
      });
      setDegreeOptions({
        graphData,
        labels: arr.map((val) => `${val.name} ${val.count}`),
      });
      setDegreeSeries(arr.map((val) => val.count));
    }
  }, [responseDegree]);

  useEffect(() => {
    if (responseCourseLevel) {
      let arr = responseCourseLevel.user;

      let graphData = {};
      arr.forEach((val) => {
        graphData[val.name] = val.count;
      });
      setCourseLevelOptions({
        graphData,
        labels: arr.map((val) => `${val.name} ${val.count}`),
      });
      setCourseLevelSeries(arr.map((val) => val.count));
    }
  }, [responseCourseLevel]);

  // const byHandler = (by) => {
  //   request("GET", `user/graph?by=${by}`);
  //   request("GET", `user/graph?by=${by}`);
  // };

  return (
    <div className="row">
      <div className="col-xl-6">
        <div className="card card-custom gutter-b card-stretch gutter-b">
          <div className="card-header h-auto border-0">
            <div className="card-title py-5">
              <h3 className="card-label">
                <span className="d-block text-dark font-weight-bolder">
                  Users (Male/Female)
                </span>
              </h3>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                {/* <div id="kt_charts_widget_5_chart"></div> */}
                <ApexChart
                  options={options}
                  series={series}
                  type="donut"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-6">
        <div className="card card-custom gutter-b card-stretch gutter-b">
          <div className="card-header h-auto border-0">
            <div className="card-title py-5">
              <h3 className="card-label">
                <span className="d-block text-dark font-weight-bolder">
                  Users (Students/Non-Students)
                </span>
              </h3>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                {/* <div id="kt_charts_widget_5_chart"></div> */}
                <ApexChart
                  options={studentOptions}
                  series={studentSeries}
                  type="donut"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-6">
        <div className="card card-custom gutter-b card-stretch gutter-b">
          <div className="card-header h-auto border-0">
            <div className="card-title py-5">
              <h3 className="card-label">
                <span className="d-block text-dark font-weight-bolder">
                  Users (Age Group)
                </span>
              </h3>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                {/* <div id="kt_charts_widget_5_chart"></div> */}
                <ApexChart
                  options={ageOptions}
                  series={ageSeries}
                  type="donut"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-6">
        <div className="card card-custom gutter-b card-stretch gutter-b">
          <div className="card-header h-auto border-0">
            <div className="card-title py-5">
              <h3 className="card-label">
                <span className="d-block text-dark font-weight-bolder">
                  Users (Degree)
                </span>
              </h3>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                {/* <div id="kt_charts_widget_5_chart"></div> */}
                <ApexChart
                  options={degreeOptions}
                  series={degreeSeries}
                  type="donut"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-6">
        <div className="card card-custom gutter-b card-stretch gutter-b">
          <div className="card-header h-auto border-0">
            <div className="card-title py-5">
              <h3 className="card-label">
                <span className="d-block text-dark font-weight-bolder">
                  Users (Course Level)
                </span>
              </h3>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                {/* <div id="kt_charts_widget_5_chart"></div> */}
                <ApexChart
                  options={courseLevelOptions}
                  series={courseLevelSeries}
                  type="donut"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserGenderChart;
