import React, { useState, useEffect } from "react";
import ApexChart from "react-apexcharts";
import moment from "moment";

import useRequest from "../../../hooks/useRequest";




const UserGenderChart = () => {
  const [expireOptions, setExpireOptions] = useState({
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.round(val) + "%"
      },
    }
  }
  );
  const [statusOptions, setStatusOptions] = useState({
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.round(val) + "%"
      },
    }
  }
  );

  const [expireSeries, setExpireSeries] = useState([]);
  const [statusSeries, setStatusSeries] = useState([]);

  // const [totalGenders, setTotalGender] = useState(0);
  // const [totalStudents, setTotalStudents] = useState(0);

  const { response: expireRes, request: expireReq } = useRequest();
  const { response: typeRes, request: typeReq } = useRequest();


  useEffect(() => {
    expireReq("GET", `dashboard/scholarship-graph?by=expired`);
    typeReq("GET", `dashboard/scholarship-graph?by=type`);
  }, []);

  useEffect(() => {
    if (expireRes) {
      let graphData = expireRes.scholarship;

      console.log(graphData)

      setExpireOptions({ graphData, labels: [`Expired  ${graphData.expiredCount}`, `Not-Expired  ${graphData.runningCount}`], colors: ['#FDBD00', '#771100'] });
      // setTotalGender(graphData.expiredCount + graphData.runningCount)
      setExpireSeries([graphData.expiredCount, graphData.runningCount]);
    }
  }, [expireRes]);

  useEffect(() => {
    if (typeRes) {
      let arr = typeRes.scholarship;

      let graphData = {};
      arr.forEach((val) => {
        graphData[val.name] = val.count
      })

      setStatusOptions(
        {
          graphData,
          labels: arr.map(val => `${val.name} ${val.count}`)
        }
      );
      setStatusSeries(arr.map(val => val.count));
    }
  }, [typeRes]);


  return (
    <div className="row">
      <div className="col-xl-6">
        <div className="card card-custom gutter-b card-stretch gutter-b">
          <div className="card-header h-auto border-0">
            <div className="card-title py-5">
              <h3 className="card-label">
                <span className="d-block text-dark font-weight-bolder">
                  Scholarships (Expired/Non-Expired)
                </span>
              </h3>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                {/* <div id="kt_charts_widget_5_chart"></div> */}
                <ApexChart
                  options={expireOptions}
                  series={expireSeries}
                  type="donut"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-6">
        <div className="card card-custom gutter-b card-stretch gutter-b">
          <div className="card-header h-auto border-0">
            <div className="card-title py-5">
              <h3 className="card-label">
                <span className="d-block text-dark font-weight-bolder">
                  Scholarships (Status)
                </span>
              </h3>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                {/* <div id="kt_charts_widget_5_chart"></div> */}
                <ApexChart
                  options={statusOptions}
                  series={statusSeries}
                  type="donut"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserGenderChart;
