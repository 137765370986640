import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";

import { SubTab } from "../Cms/TabNInput";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { API } from "../../constant/api";

const Activated = () => (
  <span className="label label-lg label-light-success label-inline">
    Activated
  </span>
);

const Deactivated = () => (
  <span className="label label-lg label-light-danger label-inline">
    Deactivated
  </span>
);

const ViewOne = (props) => {
  const { id } = props.match.params;

  const [scholarshipData, setScholarshipData] = useState([]);

  const { response: responsescholarshipData, request: requestscholarshipData } =
    useRequest();

  const { date_format } = useSelector((state) => state.setting);

  useEffect(() => {
    requestscholarshipData("GET", `scholarship/${id}`);

    document.title = "View Scholarship - Kura";
  }, []);

  useEffect(() => {
    if (responsescholarshipData) {
      const {
        name,
        title,
        type,
        description,
        photo,
        eligibility,
        externalLink,
        isActive,
        createdAt,
      } = responsescholarshipData.scholarship;
      setScholarshipData([
        { title: "Name", value: name ?? "-" },
        { title: "Title", value: title ?? "-" },
        { title: "type", value: type ?? "-" },
        { title: "Description", value: description ?? "-" },
        { title: "Eligibility", value: eligibility ?? "-" },
        { title: "ExternalLink", value: externalLink ?? "-" },
        {
          title: "Registered On",
          value: <Moment format={date_format}>{createdAt}</Moment>,
        },
        {
          title: "Status",
          value: isActive ? <Activated /> : <Deactivated />,
        },
        {
          title: "Picture",
          value:
            photo?.length > 0 ? (
              <div className="d-flex" style={{ gap: "20px" }}>
                <img
                  style={{ height: "100px" }}
                  src={`${API.PORT}/${photo}`}
                  width="100px"
                  alt=""
                  data-fancybox
                />
              </div>
            ) : (
              <p>No picture found!</p>
            ),
        },
      ]);
    }
  }, [responsescholarshipData]);

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="View Scholarship"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/scholarship", name: "Back To Scholarship" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card card-custom gutter-b">
            <div className="card-header card-header-tabs-line">
              <div className="card-toolbar">
                <ul
                  className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                  role="tablist"
                >
                  {["Scholarship Information"].map((data, index) => (
                    <SubTab key={index} name={data} index={index} />
                  ))}
                </ul>
              </div>
            </div>

            <div className="card-body px-0">
              <div className="tab-content px-10">
                <div
                  className={`tab-pane active`}
                  id={`kt_apps_contacts_view_tab_3`}
                  role="tabpanel"
                >
                  {scholarshipData.length > 0 &&
                    scholarshipData.map((scholarship, index) => (
                      <div key={index} className="form-group row my-2">
                        <label className="col-4 col-form-label">
                          {scholarship.title}
                        </label>
                        <div className="col-8">
                          <span className="form-control-plaintext font-weight-bolder">
                            {scholarship.value}
                          </span>
                        </div>
                      </div>
                    ))}

                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOne;
