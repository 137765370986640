import React, { useState, useEffect } from "react";
import ApexChart from "react-apexcharts";

const names = {
  bookStore: "Bookstore",
  ehub: "Up-cycle",
  freelanceExpert: "Expert",
  freelanceProject: "Project",
  roomMate: "Room Mate",
  vacantRoom: "Vacant Room",
};

const Chart = ({ data }) => {
  const [options, setOptions] = useState({
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [
        "Bookstore",
        "Up-cycle",
        "Expert",
        "Project",
        "Room Mate",
        "Vacant Room",
      ],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
  });

  const [series, setSeries] = useState([
    {
      name: "New Scholarships",
      data: [30, 40, 45, 50, 49, 60],
    },
  ]);

  useEffect(() => {
    if (data) {
      console.log(data);
      let catg = Object.keys(data);

      setOptions((prev) => {
        return {
          ...prev,
          xaxis: {
            categories: catg.map((v) => names[v]),
          },
        };
      });

      setSeries([
        {
          name: "New Scholarships",
          data: catg.map((v) => {
            return data[v].total;
          }),
        },
      ]);
    }
  }, [data]);

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card card-custom gutter-b card-stretch gutter-b">
          <div className="card-header h-auto border-0">
            <div className="card-title py-5">
              <h3 className="card-label">
                <span className="d-block text-dark font-weight-bolder">
                  Total Listings
                </span>
              </h3>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                {/* <div id="kt_charts_widget_5_chart"></div> */}
                <ApexChart
                  options={options}
                  series={series}
                  type="bar"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Chart.defaultProps = {
//   data: {},
// };

export default Chart;
