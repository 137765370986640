import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { API } from "../../constant/api";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Textarea,
  RenderInputFields,
  SubmitButton,
  Input,
  CKEditorInput,
} from "../Form/Form";

const Edit = (props) => {
  const history = useHistory();
  const ckEditorRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    reset,
    setError,
    clearErrors,
  } = useForm();

  const [benefitSectionImage, setOldBenefitSectionImage] = useState(null);

  const { response: responseFetchHomePage, request: requestFetchHomePage } =
    useRequest();

  const { response, request } = useRequest();

  useEffect(() => {
    requestFetchHomePage("GET", `marketing-site/home-page`);
    document.title = "Marketing Site - Kura";
  }, []);

  useEffect(() => {
    if (responseFetchHomePage) {
      if(!responseFetchHomePage.data) return
      const { heroSectionText, videoSectionLink, benefitSectionImage } =
        responseFetchHomePage.data;
        
      reset({ heroSectionText, videoSectionLink });

      if (benefitSectionImage) {
        setOldBenefitSectionImage(benefitSectionImage);
      }
    }
  }, [responseFetchHomePage]);

  useEffect(() => {
    if (response) {
      requestFetchHomePage("GET", `marketing-site/home-page`);
      toast.success("Home page data has been updated successfully.");
    }
  }, [response]);

  const onSubmit = (data) => {
    const { heroSectionText, videoSectionLink, benefitSectionImage } = data;

    if (data.heroSectionText.trim().length == 0) {
      setError("heroSectionText", {
        type: "required",
      });
      return;
    }

    let formData = new FormData();
    formData.append("heroSectionText", heroSectionText);
    formData.append("videoSectionLink", videoSectionLink);
    if (benefitSectionImage && benefitSectionImage[0]) {
      formData.append("benefitSectionImage", benefitSectionImage[0]);
    }

    request("POST", "marketing-site/home-page", formData);
  };

  const InputFields = [
    [
      {
        Component: CKEditorInput,
        colClass: "col-xl-12",
        label: "Hero Section Text",
        name: "heroSectionText",
        registerFields: {
          required: true,
        },
        getValues,
        setValue,
        trigger,
        inputData: {
          onInstanceReady: (editor) => {
            ckEditorRef.current = editor;
          },
        },
        clearErrors,
        isEdit: true,
      },
      {
        Component: Input,
        label: "Benefit Section Image",
        type: "file",
        name: "benefitSectionImage",
        registerFields: {
          //   required: true,
        },
        children: benefitSectionImage ? (
          <img
            src={`${API.PORT}/${benefitSectionImage}`}
            width="100px"
            data-fancybox
          />
        ) : (
          ""
        ),
      },
      {
        Component: Input,
        type: "text",
        label: "Video Section Link",
        name: "videoSectionLink",
        colClass: "col-lg-6",
        registerFields: {
          required: true,
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb title="Home Page" links={[{ to: "/", name: "Dashboard" }]} />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
