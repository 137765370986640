import { Dashboard, Settings, SystemManagement, Seeker } from "./Svg";

export const menu = [
  {
    name: "Dashboard",
    path: "/",
    Svg: Dashboard,
    subMenu: [],
    highlight: ["/my-profile", "/change-password"],
    subHighlight: [],
    access: ["o1"],
  },
  {
    name: "Users",
    path: "/users",
    Svg: Seeker,
    subMenu: [],
    highlight: ["/users/add"],
    subHighlight: [
      "/users/view",
      "/users/edit",
      "/users/change-password",
      "/users/logs",
    ],
    access: ["a"],
  },
  {
    name: "Ads Management",
    Svg: SystemManagement,
    subMenu: [
      {
        name: "Scholarship",
        path: "/scholarship",
        Svg: Seeker,
        subMenu: [],
        highlight: ["/scholarship/add"],
        subHighlight: ["/scholarship/view", "/scholarship/edit"],
        access: ["b"],
      },
      {
        name: "Up-cycle",
        path: "/ehub",
        Svg: Seeker,
        subMenu: [],
        highlight: [],
        subHighlight: ["/ehub/view", "/ehub/edit"],
        access: ["l"],
      },
      {
        name: "Accommodation",
        path: "/accommodation",
        Svg: Seeker,
        subMenu: [],
        highlight: [],
        subHighlight: ["/accommodation/view", "/accommodation/edit"],
        access: ["t"],
      },
      {
        name: "Book Store",
        path: "/bookstore",
        Svg: Seeker,
        subMenu: [],
        highlight: [],
        subHighlight: ["/bookstore/edit", "/bookstore/add"],
        access: ["u"],
      },
      {
        name: "Book Store Consent",
        path: "/bookstore-consent",
        Svg: Seeker,
        subMenu: [],
        highlight: [],
        subHighlight: ["/bookstore-consent", "/bookstore-consent/view"],
        access: ["w"],
      },
      {
        name: "Beta-help",
        path: "/freelance",
        Svg: Seeker,
        subMenu: [],
        highlight: [],
        subHighlight: ["/freelance/view", "/freelance/edit"],
        access: ["v"],
      },
    ],
    highlight: [
      "/scholarship",
      "/ehub",
      "/accommodation",
      "/bookstore",
      "/bookstore-consent",
      "/freelance",
    ],
    subHighlight: [
      "/scholarship/view",
      "/scholarship/edit",
      "/ehub/view",
      "/ehub/edit",
      "/accommodation/view",
      "/accommodation/edit",
      "/bookstore/edit",
      "/bookstore/add",
      "/bookstore-consent",
      "/bookstore-consent/view",
      "/freelance/view",
      "/freelance/edit",
    ],
    access: ["b", "l", "t", "u", "v", "w"],
  },
  {
    name: "Reports",
    path: "/report",
    Svg: Seeker,
    subMenu: [],
    highlight: [],
    subHighlight: ["/scholarship/view"],
    access: ["C"],
  },

  {
    name: "Sub Admin Management",
    Svg: SystemManagement,
    subMenu: [
      {
        name: "Sub - Admin ",
        path: "/sub-admin",
        // Svg: Seeker,
        subMenu: [],
        highlight: ["/sub-admin/add"],
        subHighlight: [
          "/sub-admin/view",
          "/sub-admin/edit",
          "/sub-admin/change-password",
          "/sub-admin/logs",
        ],
        access: ["c"],
      },
      {
        name: "Admin Roles ",
        path: "/admin-roles",
        // Svg: Seeker,
        subMenu: [],
        highlight: ["/admin-roles/add"],
        subHighlight: ["/admin-roles/edit"],
        access: ["y"],
      },
    ],
    highlight: ["/setting/prefix/Payment", "/system-images/add"],
    subHighlight: ["/system-images/edit", "/seo-page/edit"],
    access: ["c", "y"],
  },

  {
    name: "Subscription Management",
    Svg: SystemManagement,
    subMenu: [
      {
        name: "Subscription plans",
        path: "/subscription-plan",
        Svg: Seeker,
        subMenu: [],
        highlight: ["/subscription-plan/add"],
        subHighlight: ["/subscription-plan/view", "/subscription-plan/edit"],
        access: ["d"],
      },
      {
        name: "Subscriptions",
        path: "/subscription",
        Svg: Seeker,
        subMenu: [],
        highlight: ["/subscription/add"],
        subHighlight: ["/subscription/view"],
        access: ["Q"],
      },
      {
        name: "Transaction Logs",
        path: "/transaction",
        Svg: Seeker,
        subMenu: [],
        highlight: ["/transaction"],
        subHighlight: [],
        access: ["T"],
      },
    ],
    highlight: ["/setting/prefix/Payment", "/system-images/add"],
    subHighlight: ["/system-images/edit", "/seo-page/edit"],
    access: ["d", "Q", "T"],
  },

  {
    name: "System Management",
    Svg: SystemManagement,
    subMenu: [
      {
        name: "Cms Pages",
        path: "/cms",
        highlight: ["/cms/add"],
        subHighlight: ["/cms/view", "/cms/edit"],
        access: ["e"],
      },
      {
        name: "Seo Pages",
        path: "/seo-pages",
        highlight: ["/seo-page/add"],
        subHighlight: ["/seo-page/edit"],
        access: ["f"],
      },
      {
        name: "Email Templates",
        path: "/email-template",
        highlight: ["/email-template/add"],
        subHighlight: ["/email-template/edit"],
        access: ["g"],
      },
      {
        name: "Email Logs",
        path: "/email-logs",
        highlight: [],
        subHighlight: [],
        access: ["h"],
      },
      {
        name: "FAQs",
        path: "/faq",
        highlight: ["/faq/add"],
        subHighlight: ["/faq/edit", "/faq/view"],
        access: ["i"],
      },
      {
        name: "System Images",
        path: "/system-images",
        highlight: ["/system-images", "/system-images/add"],
        subHighlight: ["/system-images/edit"],
        access: ["j"],
      },
      {
        name: "Contact Us",
        path: "/contact-us",
        highlight: ["/contact-us/add"],
        subHighlight: ["/contact-us/view", "/contact-us/edit"],
        access: ["x"],
      },
      {
        name: "Notifications",
        path: "/notifications",
        highlight: ["/notifications/send"],
        subHighlight: ["/notifications/view"],
        access: ["F"],
      },
      {
        name: "Notification Template",
        path: "/notification-template",
        highlight: ["/notification-template/add"],
        subHighlight: ["/notification-template/edit"],
        access: ["G"],
      },
      {
        name: "Feedbacks",
        path: "/feedback",
        highlight: [],
        subHighlight: [],
        access: ["R"],
      },
    ],
    highlight: ["/setting/prefix/Payment", "/system-images/add"],
    subHighlight: ["/system-images/edit", "/seo-page/edit"],
    access: ["e", "f", "g", "h", "i", "j", "x", "F", "G", "R"],
  },
  {
    name: "Master",
    Svg: SystemManagement,
    subMenu: [
      {
        name: "Course Level",
        path: "/course-level",
        highlight: ["/course-level", "/course-level/add"],
        subHighlight: ["/course-level/edit"],
        access: ["p"],
      },
      {
        name: "Scholarship Types",
        path: "/scholarship-types",
        highlight: ["/scholarship-types/add"],
        subHighlight: ["/scholarship-types/view", "/scholarship-types/edit"],
        access: ["n"],
      },
      {
        name: "Up-cycle Types",
        path: "/ehub-types",
        highlight: ["/ehub-types/add"],
        subHighlight: ["/ehub-types/view", "/ehub-types/edit"],
        access: ["m"],
      },
      {
        name: "Bookstore Categories",
        path: "/bookstore-categories",
        highlight: ["/bookstore-categories/add"],
        subHighlight: [
          "/bookstore-categories/view",
          "/bookstore-categories/edit",
        ],
        access: ["q"],
      },
      {
        name: "Experts Categories",
        path: "/expert-categories",
        highlight: ["/expert-categories/add"],
        subHighlight: ["/expert-categories/view", "/expert-categories/edit"],
        access: ["z"],
      },
      {
        name: "Beta-help Types",
        path: "/freelance-types",
        highlight: ["/freelance-types/add"],
        subHighlight: ["/freelance-types/view", "/freelance-types/edit"],
        access: ["r"],
      },
      {
        name: "Beta-help Student Levels",
        path: "/freelance-student-level",
        highlight: ["/freelance-student-level/add"],
        subHighlight: [
          "/freelance-student-level/view",
          "/freelance-student-level/edit",
        ],
        access: ["s"],
      },
      {
        name: "Religions",
        path: "/religions",
        highlight: ["/religions/add"],
        subHighlight: ["/religions/view", "/religions/edit"],
        access: ["A"],
      },
      {
        name: "Age Range",
        path: "/age-range",
        highlight: ["/age-range/add"],
        subHighlight: ["/age-range/view", "/age-range/edit"],
        access: ["B"],
      },
      {
        name: "Report Reasons",
        path: "/report-reason",
        highlight: ["/report-reason/add"],
        subHighlight: ["/report-reason/edit"],
        access: ["D"],
      },
      {
        name: "Room Category",
        path: "/room-category",
        highlight: ["/room-category/add"],
        subHighlight: ["/room-category/edit"],
        access: ["E"],
      },
      {
        name: "Study Level",
        path: "/study-level",
        highlight: ["/study-level/add"],
        subHighlight: ["/study-level/edit"],
        access: ["H"],
      },
      {
        name: "Sclolarship Tags",
        path: "/scholarship-tag",
        highlight: ["/scholarship-tag/add"],
        subHighlight: ["/scholarship-tag/edit"],
        access: ["I"],
      },
      {
        name: "Consent Document Types",
        path: "/consent-document-types",
        highlight: ["/consent-document-types/add"],
        subHighlight: [
          "/consent-document-types/view",
          "/consent-document-types/edit",
        ],
        access: ["J"],
      },
      {
        name: "Consent Designation Types",
        path: "/consent-designation-types",
        highlight: ["/consent-designation-types/add"],
        subHighlight: [
          "/consent-designation-types/view",
          "/consent-designation-types/edit",
        ],
        access: ["K"],
      },
      {
        name: "Item Conditions",
        path: "/item-condition",
        highlight: ["/item-condition/add"],
        subHighlight: ["/item-condition/view", "/item-condition/edit"],
        access: ["L"],
      },
      {
        name: "Schools",
        path: "/school",
        highlight: ["/school/add"],
        subHighlight: ["/school/view", "/school/edit"],
        access: ["M"],
      },
      {
        name: "Cities",
        path: "/city",
        highlight: ["/city/add"],
        subHighlight: ["/city/view", "/city/edit"],
        access: ["N"],
      },
      {
        name: "Contact Us Subjects",
        path: "/contact-us-subject",
        highlight: ["/contact-us-subject/add"],
        subHighlight: ["/contact-us-subject/view", "/contact-us-subject/edit"],
        access: ["P"],
      },
      {
        name: "Degree",
        path: "/degree",
        highlight: ["/degree/add"],
        subHighlight: ["/degree/view", "/degree/edit"],
        access: ["S"],
      },
    ],
    highlight: ["/setting/prefix/Payment", "/system-images/add"],
    subHighlight: ["/system-images/edit", "/seo-page/edit"],
    access: [
      "p",
      "n",
      "m",
      "q",
      "z",
      "r",
      "s",
      "A",
      "B",
      "D",
      "E",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "P",
      "S"
    ],
  },
  {
    name: "Marketing Site Management",
    Svg: Settings,
    subMenu: [
      {
        name: "Home Page",
        path: "/marketing-site/home-page",
        highlight: [],
        subHighlight: [],
        access: ["O1"],
      },
    ],
    highlight: ["/marketing-site"],
    subHighlight: [],
    access: ["O"],
  },
  {
    name: "Settings",
    Svg: Settings,
    subMenu: [
      {
        name: "Site Setting",
        path: "/setting/prefix/Site",
        highlight: [],
        subHighlight: [],
        access: ["k"],
      },
      {
        name: "Reading Setting",
        path: "/setting/prefix/Reading",
        highlight: [],
        subHighlight: [],
        access: ["k2"],
      },
      {
        name: "Social Setting",
        path: "/setting/prefix/Social",
        highlight: [],
        subHighlight: [],
        access: ["k3"],
      },
    ],
    highlight: ["/setting", "/setting/add", "/employee-count/add"],
    subHighlight: ["/setting/edit", "/employee-count/edit"],
    access: ["k"],
  },
];
