
export const permissions = [
    {
        name: 'Users',
        key: 'a',
        permissions: [
            {
                name: "View",
                key: "a1",
            },
            {
                name: "Add User",
                key: "a2"
            },
            {
                name: "Change Permission",
                key: "a3"
            },
            {
                name: "View One",
                key: "a4"
            },
            {
                name: "Edit User",
                key: "a5"
            },
            {
                name: "Delete User",
                key: "a6",
            },
            {
                name: "Change Password",
                key: "a7",
            },
            {
                name: "Send Credentials",
                key: "a8"
            },
            {
                name: "Change status",
                key: "a9"
            },
            {
                name: "Enable user",
                key: "a10"
            },
            {
                name: "View logs",
                key: "a11"
            },
            {
                name: "View logs",
                key: "a12"
            }
        ]
    },
    {
        name: 'Scholarships',
        key: 'b',
        permissions: [
            {
                name: "View",
                key: "b1",
            },
            {
                name: "View One",
                key: "b2"
            },
            {
                name: "Change Status",
                key: "b3"
            },
            {
                name: "Add",
                key: "b4"
            },
            {
                name: "Edit",
                key: "b5"
            },
            {
                name: "Delete",
                key: "b6",
            },
            {
                name: "Renew",
                key: "b7",
            },
            {
                name: "Report",
                key: "b8",
            }
        ]
    },
    {
        name: 'Sub Admins',
        key: 'c',
        permissions: [
            {
                name: "View",
                key: "c1",
            },
            {
                name: "View One",
                key: "c2"
            },
            {
                name: "Change Status",
                key: "c3"
            },
            {
                name: "Add",
                key: "c4"
            },
            {
                name: "Edit",
                key: "c5"
            },
            {
                name: "Delete",
                key: "c6",
            },
            {
                name: "Change Password",
                key: "c7"
            },
            {
                name: "Send Credential",
                key: "c8",
            },
            {
                name: "Enable sub admin",
                key: "c9"
            },
            {
                name: "View logs",
                key: "c11"
            }
        ]
    },
    {
        name: 'Subscription Plans',
        key: 'd',
        permissions: [
            {
                name: "View",
                key: "d1",
            },
            {
                name: "View One",
                key: "d2"
            },
            {
                name: "Change Status",
                key: "d3"
            },
            {
                name: "Add",
                key: "d4"
            },
            {
                name: "Edit",
                key: "d5"
            },
            {
                name: "Delete",
                key: "d6",
            }
        ]
    },
    {
        name: 'CMS Pages',
        key: 'e',
        permissions: [
            {
                name: "View",
                key: "e1",
            },
            {
                name: "View One",
                key: "e2"
            },
            {
                name: "Edit",
                key: "e3"
            }
        ]
    },
    {
        name: 'SEO Pages',
        key: 'f',
        permissions: [
            {
                name: "View",
                key: "f1",
            },
            {
                name: "Edit",
                key: "f2"
            },
            {
                name: "Delete",
                key: "f3"
            },
        ]
    },
    {
        name: 'Email Templates',
        key: 'g',
        permissions: [
            {
                name: "View",
                key: "g1",
            },
            {
                name: "Edit",
                key: "g2"
            },
        ]
    },
    {
        name: 'Email Logs',
        key: 'h',
        permissions: [
            {
                name: "View",
                key: "h1",
            },
            {
                name: "View One",
                key: "h2"
            }
        ]
    },
    {
        name: 'FAQs',
        key: 'i',
        permissions: [
            {
                name: "View",
                key: "i1",
            },
            {
                name: "View One",
                key: "i2"
            },
            {
                name: "Change Status",
                key: "i3"
            },
            {
                name: "Add",
                key: "i4"
            },
            {
                name: "Edit",
                key: "i5"
            },
            {
                name: "Delete",
                key: "i6",
            }
        ]
    },
    {
        name: 'System Images',
        key: 'j',
        permissions: [
            {
                name: "View",
                key: "j1",
            },
            {
                name: "Edit",
                key: "j2"
            }
        ]
    },
    {
        name: "Settings",
        key: 'k',
        permissions: [
            {
                name: 'Site Settings',
                key: 'k1',
            },
            {
                name: 'Reading Settings',
                key: 'k2',
            },
            {
                name: 'Social Settings',
                key: 'k3',
            },
        ]
    },
    {
        name: "Up-cycle",
        key: 'l',
        permissions: [
            {
                name: "View",
                key: "l1",
            },
            {
                name: "View One",
                key: "l2"
            },
            {
                name: "Change Status",
                key: "l3"
            },
            {
                name: "Add",
                key: "l4"
            },
            {
                name: "Edit",
                key: "l5"
            },
            {
                name: "Delete",
                key: "l6",
            },
            {
                name: "Approve",
                key: "l7",
            },
            {
                name: "Report",
                key: "l8",
            },
            {
                name: "View Creator",
                key: "l9",
            },
        ]
    },
    {
        name: "Up-cycle types",
        key: 'm',
        permissions: [
            {
                name: "View",
                key: "m1",
            },
            {
                name: "View One",
                key: "m2"
            },
            {
                name: "Change Status",
                key: "m3"
            },
            {
                name: "Add",
                key: "m4"
            },
            {
                name: "Edit",
                key: "m5"
            },
            {
                name: "Delete",
                key: "m6",
            },
            {
                name: "Approve",
                key: "m7",
            },
            {
                name: "Report",
                key: "m8",
            },
            {
                name: "View Creator",
                key: "m9",
            },
        ]
    },
    {
        name: "Scholarship types",
        key: 'n',
        permissions: [
            {
                name: "View",
                key: "n1",
            },
            {
                name: "View One",
                key: "n2"
            },
            {
                name: "Change Status",
                key: "n3"
            },
            {
                name: "Add",
                key: "n4"
            },
            {
                name: "Edit",
                key: "n5"
            },
            {
                name: "Delete",
                key: "n6",
            }
        ]
    },
    // {
    //     name: "Dashboard",
    //     key: 'o',
    //     permissions: [
    //         {
    //             name: "View",
    //             key: "o1",
    //         },            
    //     ]
    // },    
    {
        name: "Course level",
        key: 'p',
        permissions: [
            {
                name: "View",
                key: "p1",
            },
            {
                name: "View One",
                key: "p2"
            },
            {
                name: "Change Status",
                key: "p3"
            },
            {
                name: "Add",
                key: "p4"
            },
            {
                name: "Edit",
                key: "p5"
            },
            {
                name: "Delete",
                key: "p6",
            }
        ]
    },
    {
        name: "Bookstore Categories",
        key: 'q',
        permissions: [
            {
                name: "View",
                key: "q1",
            },
            {
                name: "View One",
                key: "q2"
            },
            {
                name: "Change Status",
                key: "q3"
            },
            {
                name: "Add",
                key: "q4"
            },
            {
                name: "Edit",
                key: "q5"
            },
            {
                name: "Delete",
                key: "q6",
            }
        ]
    },
    {
        name: "Beta-help Types",
        key: 'r',
        permissions: [
            {
                name: "View",
                key: "r1",
            },
            {
                name: "View One",
                key: "r2"
            },
            {
                name: "Change Status",
                key: "r3"
            },
            {
                name: "Add",
                key: "r4"
            },
            {
                name: "Edit",
                key: "r5"
            },
            {
                name: "Delete",
                key: "r6",
            }
        ]
    },
    {
        name: "Beta-help Student Levels",
        key: 's',
        permissions: [
            {
                name: "View",
                key: "s1",
            },
            {
                name: "View One",
                key: "s2"
            },
            {
                name: "Change Status",
                key: "s3"
            },
            {
                name: "Add",
                key: "s4"
            },
            {
                name: "Edit",
                key: "s5"
            },
            {
                name: "Delete",
                key: "s6",
            }
        ]
    },
    {
        name: "Accommodation",
        key: 't',
        permissions: [
            {
                name: "View",
                key: "t1",
            },
            {
                name: "View One",
                key: "t2"
            },
            {
                name: "Change Status",
                key: "t3"
            },
            {
                name: "Add",
                key: "t4"
            },
            {
                name: "Edit",
                key: "t5"
            },
            {
                name: "Delete",
                key: "t6",
            },
            {
                name: "View One Room Mate",
                key: "t7"
            },
            {
                name: "View One Vacant Room",
                key: "t8"
            },
            {
                name: "Approve",
                key: "t9",
            },
            {
                name: "Report",
                key: "t10",
            },
            {
                name: "View Creator",
                key: "t11",
            },
        ]
    },
    {
        name: "Book Store",
        key: 'u',
        permissions: [
            {
                name: "View",
                key: "u1",
            },
            {
                name: "View One",
                key: "u2"
            },
            {
                name: "Change Status",
                key: "u3"
            },
            {
                name: "Add",
                key: "u4"
            },
            {
                name: "Edit",
                key: "u5"
            },
            {
                name: "Delete",
                key: "u6",
            },
            {
                name: "Approve",
                key: "u7"
            },
            {
                name: "Report",
                key: "u8"
            },
            {
                name: "View Creator",
                key: "u9",
            }
        ]
    },
    {
        name: "Beta-help",
        key: 'v',
        permissions: [
            {
                name: "View",
                key: "v1",
            },
            {
                name: "View One",
                key: "v2"
            },
            {
                name: "Change Status",
                key: "v3"
            },
            {
                name: "Add",
                key: "v4"
            },
            {
                name: "Edit",
                key: "v5"
            },
            {
                name: "Delete",
                key: "v6",
            },
            {
                name: "View One Expert",
                key: "v7"
            },
            {
                name: "View One Project",
                key: "v8"
            },
            {
                name: "Approve",
                key: "v9",
            },
            {
                name: "Report",
                key: "v10"
            },
            {
                name: "View Creator",
                key: "v11"
            },
        ]
    },
    {
        name: "Bookstore concent",
        key: 'w',
        permissions: [
            {
                name: "View",
                key: "w1",
            },
            {
                name: "View One",
                key: "w2"
            },
            {
                name: "Change Status",
                key: "w3"
            },
            {
                name: "Approve",
                key: "w4"
            },
            {
                name: "View Creator",
                key: "w5"
            },
        ]
    },
    {
        name: "Contact Us",
        key: 'x',
        permissions: [
            {
                name: "View",
                key: "x1",
            },
            {
                name: "View One",
                key: "x2"
            },
        ]
    },
    {
        name: "Admin Roles",
        key: 'y',
        permissions: [
            {
                name: "View",
                key: "y1",
            },
            {
                name: "View One",
                key: "y2"
            },
            {
                name: "Change Status",
                key: "y3"
            },
            {
                name: "Add",
                key: "y4"
            },
            {
                name: "Edit",
                key: "y5"
            },
            {
                name: "Delete",
                key: "y6",
            }
        ]
    },
    {
        name: "Experts Categories",
        key: 'z',
        permissions: [
            {
                name: "View",
                key: "z1",
            },
            {
                name: "View One",
                key: "z2"
            },
            {
                name: "Change Status",
                key: "z3"
            },
            {
                name: "Add",
                key: "z4"
            },
            {
                name: "Edit",
                key: "z5"
            },
            {
                name: "Delete",
                key: "z6",
            }
        ]
    },
    {
        name: "Religions",
        key: 'A',
        permissions: [
            {
                name: "View",
                key: "A1",
            },
            {
                name: "View One",
                key: "A2"
            },
            {
                name: "Change Status",
                key: "A3"
            },
            {
                name: "Add",
                key: "A4"
            },
            {
                name: "Edit",
                key: "A5"
            },
            {
                name: "Delete",
                key: "A6",
            }
        ]
    },
    {
        name: "Age Range",
        key: 'B',
        permissions: [
            {
                name: "View",
                key: "B1",
            },
            {
                name: "View One",
                key: "B2"
            },
            {
                name: "Change Status",
                key: "B3"
            },
            {
                name: "Add",
                key: "B4"
            },
            {
                name: "Edit",
                key: "B5"
            },
            {
                name: "Delete",
                key: "B6",
            }
        ]
    },
    {
        name: "Report",
        key: 'C',
        permissions: [
            {
                name: "View",
                key: "C1",
            },
            {
                name: "View One",
                key: "C2"
            }
        ]
    },
    {
        name: "Report Reasons",
        key: 'D',
        permissions: [
            {
                name: "View",
                key: "D1",
            },
            {
                name: "View One",
                key: "D2"
            },
            {
                name: "Change Status",
                key: "D3"
            },
            {
                name: "Add",
                key: "D4"
            },
            {
                name: "Edit",
                key: "D5"
            },
            {
                name: "Delete",
                key: "D6",
            }
        ]
    },
    {
        name: "Room Category",
        key: 'E',
        permissions: [
            {
                name: "View",
                key: "E1",
            },
            {
                name: "View One",
                key: "E2"
            },
            {
                name: "Change Status",
                key: "E3"
            },
            {
                name: "Add",
                key: "E4"
            },
            {
                name: "Edit",
                key: "E5"
            },
            {
                name: "Delete",
                key: "E6",
            }
        ]
    },
    {
        name: "Notification",
        key: 'F',
        permissions: [
            {
                name: "View",
                key: "F1",
            },
            {
                name: "View One",
                key: "F2"
            },
            {
                name: "Resend",
                key: "F3"
            },
            {
                name: "Send Notification",
                key: "F4"
            },
        ]
    },
    {
        name: "Notification Template",
        key: 'G',
        permissions: [
            {
                name: "View",
                key: "G1",
            },
            {
                name: "View One",
                key: "G2"
            },
            {
                name: "Change Status",
                key: "G3"
            },
            {
                name: "Add",
                key: "G4"
            },
            {
                name: "Edit",
                key: "G5"
            },
        ]
    },
    {
        name: "Study Level",
        key: 'H',
        permissions: [
            {
                name: "View",
                key: "H1",
            },
            {
                name: "View One",
                key: "H2"
            },
            {
                name: "Change Status",
                key: "H3"
            },
            {
                name: "Add",
                key: "H4"
            },
            {
                name: "Edit",
                key: "H5"
            },
        ]
    },
    {
        name: "Scholarship Tag",
        key: 'I',
        permissions: [
            {
                name: "View",
                key: "I1",
            },
            {
                name: "View One",
                key: "I2"
            },
            {
                name: "Change Status",
                key: "I3"
            },
            {
                name: "Add",
                key: "I4"
            },
            {
                name: "Edit",
                key: "I5"
            },
        ]
    },
    {
        name: "Consent Document Types",
        key: 'J',
        permissions: [
            {
                name: "View",
                key: "J1",
            },
            {
                name: "View One",
                key: "J2"
            },
            {
                name: "Change Status",
                key: "J3"
            },
            {
                name: "Add",
                key: "J4"
            },
            {
                name: "Edit",
                key: "J5"
            },
        ]
    },
    {
        name: "Consent Designation Types",
        key: 'K',
        permissions: [
            {
                name: "View",
                key: "K1",
            },
            {
                name: "View One",
                key: "K2"
            },
            {
                name: "Change Status",
                key: "K3"
            },
            {
                name: "Add",
                key: "K4"
            },
            {
                name: "Edit",
                key: "K5"
            },
        ]
    },
    {
        name: "Item Conditions",
        key: 'L',
        permissions: [
            {
                name: "View",
                key: "L1",
            },
            {
                name: "View One",
                key: "L2"
            },
            {
                name: "Change Status",
                key: "L3"
            },
            {
                name: "Add",
                key: "L4"
            },
            {
                name: "Edit",
                key: "L5"
            },
        ]
    },
    {
        name: "School",
        key: 'M',
        permissions: [
            {
                name: "View",
                key: "M1",
            },
            {
                name: "View One",
                key: "M2"
            },
            {
                name: "Change Status",
                key: "M3"
            },
            {
                name: "Add",
                key: "M4"
            },
            {
                name: "Edit",
                key: "M5"
            },
        ]
    },
    {
        name: "City",
        key: 'N',
        permissions: [
            {
                name: "View",
                key: "N1",
            },
            {
                name: "View One",
                key: "N2"
            },
            {
                name: "Change Status",
                key: "N3"
            },
            {
                name: "Add",
                key: "N4"
            },
            {
                name: "Edit",
                key: "N5"
            },
        ]
    },
    {
        name: "Marketing Site Management",
        key: 'O',
        permissions: [
            {
                name: "Home Page",
                key: "O1",
            },            
        ]
    },
    {
        name: "Contact US Subjects",
        key: 'P',
        permissions: [
            {
                name: "View",
                key: "P1",
            },
            {
                name: "View One",
                key: "P2"
            },
            {
                name: "Change Status",
                key: "P3"
            },
            {
                name: "Add",
                key: "P4"
            },
            {
                name: "Edit",
                key: "P5"
            },
        ]
    },
    {
        name: "Subscriptions",
        key: 'Q',
        permissions: [
            {
                name: "View",
                key: "Q1",
            },
            {
                name: "View One",
                key: "Q2"
            },            
        ]
    },
    {
        name: "Feedback",
        key: 'R',
        permissions: [
            {
                name: "View",
                key: "R1",
            },
            {
                name: "View One",
                key: "R2"
            },            
        ]
    },
    {
        name: "Degree",
        key: 'S',
        permissions: [
            {
                name: "View",
                key: "S1",
            },
            {
                name: "View One",
                key: "S2"
            },
            {
                name: "Change Status",
                key: "S3"
            },
            {
                name: "Add",
                key: "S4"
            },
            {
                name: "Edit",
                key: "S5"
            },
        ]
    },
    {
        name: 'Transaction Logs',
        key: 'T',
        permissions: [
            {
                name: "View",
                key: "T1",
            },
            {
                name: "View One",
                key: "T2"
            }
        ]
    },
    
]

//booked keys ---
// a
// b
// c
// d
// e
// f
// g
// h
// i
// k
// l
// m
// n
// o
// p
// q
// r
// s
// t 
// u 
// v
// w 
// x 
// y 

//reamaining ---

// Up-cycle 
// course level
