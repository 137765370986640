import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { useSelector } from "react-redux";

import {
  View,
  Edit,
  Delete,
  MakeDeactivate,
  MakeActivate,
  ChangePassword,
  SendCreds,
  Wallet,
  Login,
  Log,
  Store,
  Driver,
  Cms,
  Review,
  HelpCentre,
  Reports,
} from "../../util/Svg";
import { API } from "../../constant/api";
import { intersect } from "../../util/fn";

const truncate = (input) =>
  input.length > 200 ? `${input.substring(0, 200)}...` : input;

const Table = ({
  mainData,
  tableHeading,
  tableData,
  links,
  sortingHandler,
  currentSort,
  onlyDate,
  page,
  dontShowSort = [],
  renderAs = {},
  isHighlight,
  isApproved,
  // status,
}) => {
  const { date_format, date_time_format } = useSelector(
    (state) => state.setting
  );
  const { permissions, roleId } = useSelector((state) => state.auth);

  return (
    <div className="table-responsive">
      <table
        className="table dataTable table-head-custom table-head-bg table-borderless table-vertical-center"
        id="taskTable"
      >
        <thead>
          <tr className="text-uppercase">
            {tableHeading.map((heading, index) => (
              <th
                onClick={() => sortingHandler(heading)}
                key={index}
                className={`${
                  currentSort.sortBy == heading
                    ? `sorting_${currentSort.order}`
                    : dontShowSort.includes(heading)
                    ? ""
                    : "sorting"
                }`}
                style={{
                  width:
                    heading === "answer" || heading === "description"
                      ? "400px"
                      : "",
                }}
              >
                <a className="no_sort">{heading}</a>
              </th>
            ))}
            {links && links.length > 0 ? (
              <th className="text-left ActionText">Action</th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {mainData.length > 0 &&
            mainData.map((data) => (
              <tr
                key={data._id}
                className={`${
                  isHighlight
                    ? isHighlight(data)
                      ? "highlighted-row"
                      : ""
                    : ""
                }`}
              >
                {tableData.map((tData, index) => {
                  let value;
                  if (tData == "description") {
                    value = (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: truncate(data[tData]),
                        }}
                      ></p>
                    );
                  } else if (tData == "userId" || data[tData] == "userId") {
                    value = (
                      <Link to={`/users/view/${data._id}`}>
                        {data.userId ? data.userId : data[tData]}
                      </Link>
                    );
                  } else if (
                    tData == "user_uniqueId" ||
                    data[tData] == "user_uniqueId"
                  ) {
                    if (data[tData]) {
                      value = (
                        <Link to={`/users/view/${data.userId}`}>
                          {data.user_uniqueId
                            ? data.user_uniqueId
                            : data[tData]}
                        </Link>
                      );
                    } else {
                      value = "Not Available";
                    }
                  } else if (
                    tData == "scholarshipId" ||
                    data[tData] == "scholarshipId"
                  ) {
                    value = (
                      <Link to={`/scholarship/view/${data._id}`}>
                        {data.scholarshipId ? data.scholarshipId : data[tData]}
                      </Link>
                    );
                  } else if (tData == "ehubId" || data[tData] == "ehubId") {
                    value = (
                      <Link to={`/ehub/view/${data._id}`}>
                        {data.ehubId ? data.ehubId : data[tData]}
                      </Link>
                    );
                  } else if (
                    tData == "accommodationId" ||
                    data[tData] == "accommodationId"
                  ) {
                    value = (
                      <Link
                        to={
                          data.roomId
                            ? `/accommodation/vacant-room/view/${data._id}`
                            : `/accommodation/room-mate/view/${data._id}`
                        }
                      >
                        {/* {console.log(data)} */}
                        {data.roomId ? data.roomId : data.roomMateId}
                      </Link>
                    );
                  } else if (
                    tData == "bookstoreId" ||
                    data[tData] == "bookstoreId"
                  ) {
                    value = (
                      <Link to={`/bookstore/view/${data._id}`}>
                        {data.bookstoreId ? data.bookstoreId : data[tData]}
                      </Link>
                    );
                  } else if (
                    tData == "freelanceId" ||
                    data[tData] == "freelanceId"
                  ) {
                    value = (
                      <Link
                        to={
                          data.expertId
                            ? `/freelance/expert/view/${data._id}`
                            : `/freelance/project/view/${data._id}`
                        }
                      >
                        {data.expertId ? data.expertId : data.projectId}
                      </Link>
                    );
                  } else if (tData == "type") {
                    value = data.type ? data.type : data.category;
                  } else if (data[tData] == "projectId") {
                    value = (
                      <Link to={`/freelance/project/view/${data._id}`}>
                        {data.projectId ? data.projectId : data[tData]}
                      </Link>
                    );
                  } else if (data[tData] == "expertId") {
                    value = (
                      <Link to={`/freelance/expert/view/${data._id}`}>
                        {data.expertId ? data.expertId : data[tData]}
                      </Link>
                    );
                  } else if (data[tData] == "roomId") {
                    value = (
                      <Link to={`/accommodation/vacant-room/view/${data._id}`}>
                        {data.roomId ? data.roomId : data[tData]}
                      </Link>
                    );
                  } else if (data[tData] == "roomMateId") {
                    value = (
                      <Link to={`/accommodation/room-mate/view/${data._id}`}>
                        {data.roomMateId ? data.roomMateId : data[tData]}
                      </Link>
                    );
                  } else if (Object.keys(onlyDate).includes(tData)) {
                    if (onlyDate[tData] === "date") {
                      value = (
                        <Moment format={date_format}>{data[tData]}</Moment>
                      );
                    } else if (onlyDate[tData] === "dateTime") {
                      value = (
                        <Moment format={date_time_format}>{data[tData]}</Moment>
                      );
                    }
                  } else if (Object.keys(renderAs).includes(tData)) {
                    value = renderAs[tData](data[tData], data._id, data);
                  } else if (tData == "isActive") {
                    if (data[tData] == true) {
                      value = (
                        <span className="label label-lg label-light-success label-inline">
                          Active
                        </span>
                      );
                    } else {
                      value = (
                        <span className="label label-lg label-light-danger label-inline">
                          Inactive
                        </span>
                      );
                    }
                  }
                  // else if (tData == "deadline") {
                  //   if (new Date(data[tData]) > new Date()) {
                  //     value = (
                  //       <span className="label label-lg label-light-success label-inline">
                  //         Not Expired
                  //       </span>
                  //     );
                  //   } else {
                  //     value = (
                  //       <span className="label label-lg label-light-danger label-inline">
                  //         Expired
                  //       </span>
                  //     );
                  //   }
                  // }
                  else if (tData == "message") {
                    value = truncate(data[tData]);
                  } else if (tData == "amount") {
                    value = `#${data[tData]}`;
                  } else if (tData == "image") {
                    value = (
                      <img
                        src={`${API.PORT}/${data[tData]}`}
                        alt=""
                        data-fancybox
                        height={50}
                        width={80}
                        style={{ cursor: "pointer" }}
                      />
                    );
                  } else if (tData === "answer") {
                    value = (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: truncate(data[tData]),
                        }}
                      ></p>
                    );
                  } else {
                    value = data[tData];
                  }

                  return (
                    <td key={index} className="py-5">
                      <div className="d-flex align-items-center">
                        <div className="text-dark-75 mb-1  font-size-lg">
                          {value}
                          {/* <span className="False_text">False</span>  
                     <span className="true_text">True</span>   */}

                          {/* {tData === "description" ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: truncate(data[tData]),
                              }}
                            ></p>
                          ) : tData === "createdAt" ? (
                            onlyDate ? (
                              <Moment format={date_format}>
                                {data[tData]}
                              </Moment>
                            ) : (
                              <Moment format={date_time_format}>
                                {data[tData]}
                              </Moment>
                            )
                          ) : tData === "isActive" ? (
                            data[tData] == "true" ? (
                              <span className="label label-lg label-light-success label-inline">
                                Activated
                              </span>
                            ) : (
                              <span className="label label-lg label-light-danger label-inline">
                                Deactivated
                              </span>
                            )
                          ) : (
                            data[tData]
                          )} */}
                        </div>
                      </div>
                    </td>
                  );
                })}

                <td className="text-left pr-2">
                  {links?.map((link, index) => {
                    let name = link.name;
                    let svg;

                    if (name == "Edit") {
                      svg = <Edit />;
                    } else if (name == "Delete") {
                      svg = <Delete />;
                    } else if (name == "View") {
                      svg = <View />;
                    } else if (name == "Deactivate" && data.isActive != true) {
                      svg = <MakeActivate />;
                    } else if (name == "Activate" && data.isActive != false) {
                      svg = <MakeDeactivate />;
                    } else if (
                      name == "Approved" &&
                      data.status == "pending" &&
                      !data.isReported
                    ) {
                      svg = <MakeActivate />;
                    } else if (
                      name == "Recover" &&
                      data.isAccountDeleted == true
                    ) {
                      svg = <MakeActivate />;
                    } else if (
                      name == "ApproveConsentFromBookstore" &&
                      data.consentStatus == "pending"
                    ) {
                      svg = <MakeActivate />;
                    } else if (name == "ChangePassword" || name == "Creator") {
                      svg = <ChangePassword />;
                    } else if (name == "SendCreds") {
                      svg = <SendCreds />;
                    } else if (name == "Wallet") {
                      svg = <Wallet />;
                    } else if (name == "Login") {
                      svg = <Wallet />;
                    } else if (name == "Enable") {
                      svg = <MakeActivate />;
                    } else if (name == "Log") {
                      svg = <Log />;
                    } else if (name == "Report" && data.isReported === true) {
                      svg = <Reports />;
                    } else if (
                      name == "Renew" &&
                      new Date(data.deadline) <= new Date()
                    ) {
                      svg = <Log />;
                    }

                    if (roleId === 2 && link.access && link.access.length > 0) {
                      if (!intersect(permissions, link.access)) {
                        return null;
                      }
                    }

                    if (link.show) {
                      const show = link.show(data);
                      if (!show) {
                        return null;
                      }
                    }

                    if (link.name == "Report" && !data.isReported) {
                      return null;
                    }

                    if (link.isLink) {
                      return (
                        <Link
                          key={index}
                          to={
                            link.extraData
                              ? {
                                  pathname: `${link.to}/${
                                    link?.secondaryLink
                                      ? data[link.secondaryId]
                                      : data._id
                                  }`,
                                  page,
                                }
                              : `${link.to}/${data._id}`
                          }
                          className="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          data-container="body"
                          data-boundary="window"
                          title={link.title || name}
                          data-original-title={link.title || name}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            {svg}
                          </span>
                        </Link>
                      );
                    } else {
                      return (
                        <Fragment key={index}>
                          {svg && (
                            <a
                              key={index}
                              className={`btn btn-icon btn-light mr-2 ${
                                name === "Delete"
                                  ? "btn-hover-danger confirmDelete"
                                  : "btn-hover-primary"
                              }  btn-sm `}
                              data-toggle="tooltip"
                              data-placement="top"
                              data-container="body"
                              data-boundary="window"
                              title={link.title || name}
                              data-original-title={link.title || name}
                            >
                              <span
                                onClick={() => link.click(data._id, data)}
                                className={`svg-icon svg-icon-md ${
                                  name === "Delete"
                                    ? "svg-icon-danger"
                                    : "svg-icon-primary"
                                }`}
                              >
                                {svg}
                              </span>
                            </a>
                          )}
                        </Fragment>
                      );
                    }
                  })}
                </td>
              </tr>
            ))}
          {mainData.length == 0 && (
            <tr>
              <td colSpan="6" style={{ textAlign: "center" }}>
                {" "}
                Record not found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
