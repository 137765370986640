import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { SubTab } from "../Cms/TabNInput";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { API } from "../../constant/api";
import Table from "../Table/Table";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Activated = () => (
  <span className="label label-lg label-light-success label-inline">
    Activated
  </span>
);

const Deactivated = () => (
  <span className="label label-lg label-light-danger label-inline">
    Deactivated
  </span>
);

const ViewOne = (props) => {
  const OBJ_TABLE = {
    idType: "idType",
    "Add Type": "addType",
    Price: "price",
    "registered on": "createdAt",
    status: "status",
    Reported: "isReported",
  };

  const MySwal = withReactContent(Swal);

  const { id: seekerId } = props.match.params;

  const [userData, setUserData] = useState([]);
  const [ads, setAds] = useState([]);
  const [isPrivilageUser, setIsPrivilageUser] = useState(false);

  const { response: responseUserData, request: requestUserData } = useRequest();
  const { response: responseChangeStatus, request: requestChangeStatus } =
    useRequest();
  const { response: responseDelete, request: requestDelete } = useRequest();
  const { response: responsePrivilageChange, request: requestPrivilageChange } =
    useRequest();

  const { date_format, date_time_format } = useSelector(
    (state) => state.setting
  );

  useEffect(() => {
    requestUserData("GET", `user/${seekerId}`);

    document.title = "View User - Kura";
  }, []);

  useEffect(() => {
    if (responseUserData) {
      const {
        name,
        userName,
        email,
        gender,
        dob,
        contact,
        profilePic,
        userType,
        userId,
        school,
        city,
        address,
        isAccountDisabled,
        isEmailVerified,
        isActive,
        createdAt,
        isPrivilage,
        planName,
        planPrice
      } = responseUserData.user;
      setAds(responseUserData.ads);
      setUserData([
        { title: "Name", value: name ?? "-" },
        { title: "Username", value: userName ?? "-" },
        { title: "Email", value: email ?? "-" },
        { title: "Gender", value: gender ?? "-" },
        {
          title: "Date Of Birth",
          value: <Moment format={date_format}>{dob}</Moment>,
        },
        { title: "Phone Number", value: contact ?? "-" },
        {
          title: "Profile Picture",
          value: profilePic ? (
            <div className="d-flex" style={{ gap: "20px" }}>
              <div>
                <img
                  src={`${API.PORT}/${profilePic}`}
                  width="100px"
                  alt=""
                  data-fancybox
                />
              </div>
            </div>
          ) : (
            <p>No pictures found !</p>
          ),
        },
        { title: "User Type", value: userType ?? "-" },
        { title: "User ID", value: userId ?? "-" },
        { title: "School", value: school ?? "-" },
        { title: "City", value: city ?? "-" },
        // { title: "Address", value: address ?? "-" },
        {
          title: "Disabled By User",
          value: isAccountDisabled ? "True" : "False",
        },
        { title: "Email Verified", value: isEmailVerified ? "True" : "False" },
        {
          title: "Registered On",
          value: <Moment format={date_format}>{createdAt}</Moment>,
        },
        {
          title: "Status",
          value: isActive ? <Activated /> : <Deactivated />,
        },
        {
          title: "Privilage User",
          value: isPrivilage ? "True" : "False",
        },
        {
          title: "Subscribed Plan",
          value: planName ?? "-",
        },
        {
          title: "Active Subscription",
          value: planPrice ? (planPrice > 0 ? "true" : "False") : "False",
        },
      ]);

      if (isPrivilage) {
        setIsPrivilageUser(true);
      }
    }
  }, [responseUserData]);

  const changeStatusHandler = (id, data) => {
    const status = data.isActive == true ? false : true;

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (data.addType === "Book") {
          requestChangeStatus("PUT", "bookstore/status", {
            id,
            status,
          });
        } else if (data.addType === "Up-cycle") {
          requestChangeStatus("PUT", "ehub/status", {
            id,
            status,
          });
        } else if (data.addType === "Project") {
          requestChangeStatus("PUT", "freelance-project/status", {
            id,
            status,
          });
        } else if (data.addType === "Expert") {
          requestChangeStatus("PUT", "freelance-expert/status", {
            id,
            status,
          });
        } else if (data.addType === "Vacant Room") {
          requestChangeStatus("PUT", "vacant-room/status", {
            id,
            status,
          });
        } else if (data.addType === "Room Mate") {
          requestChangeStatus("PUT", "room-mate/status", {
            id,
            status,
          });
        }

        requestChangeStatus("PUT", "user/status", {
          id,
          status,
        });
      } else if (result.isDismissed) {
      }
    });
  };

  useEffect(() => {
    if (responseChangeStatus) {
      const { id, status } = responseChangeStatus;

      const oldAds = [...ads];
      const indexToChange = oldAds.findIndex((ad) => ad._id == id);
      oldAds[indexToChange].isActive = status;

      setAds(oldAds);
    }
  }, [responseChangeStatus]);

  const deleteHandler = (id, data) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You want to delete this ad?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (data.addType === "Book") {
          requestDelete("DELETE", "bookstore", { id });
        } else if (data.addType === "Up-cycle") {
          requestDelete("DELETE", "ehub", { id });
        } else if (data.addType === "Project") {
          requestDelete("DELETE", "freelance-project", { id });
        } else if (data.addType === "Expert") {
          requestDelete("DELETE", "freelance-expert", { id });
        } else if (data.addType === "Vacant Room") {
          requestDelete("DELETE", "vacant-room", { id });
        } else if (data.addType === "Room Mate") {
          requestDelete("DELETE", "room-mate", { id });
        }
      } else if (result.isDismissed) {
      }
    });
  };

  useEffect(() => {
    if (responseDelete && responseDelete?.status) {
      const { id } = responseDelete;
      let newAds = [...ads];
      newAds = newAds.map((ad) => {
        if (ad._id == id) {
          ad.isDeleted = true;
        }
        return ad;
      });
      setAds(newAds);
      toast.success("Deleted successfully.");
    }
  }, [responseDelete]);

  // const privilageHandler = (e) => {
  //   const { checked } = e.target;

  //   MySwal.fire({
  //     title: "Are you sure?",
  //     text: "You want to change this privilege status. Privilege users can post bookstore without any consent approval!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes",
  //     cancelButtonText: "No",
  //     reverseButtons: true,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       requestPrivilageChange("PUT", "user/privilage-status", {
  //         id: seekerId,
  //         status: checked,
  //       });
  //     } else if (result.isDismissed) {
  //     }
  //   });
  // };

  // useEffect(() => {
  //   if (responsePrivilageChange) {
  //     const { id, newStatus } = responsePrivilageChange;
  //     setIsPrivilageUser(newStatus);
  //   }
  // }, [responsePrivilageChange]);

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="View User"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/users", name: "Back To Users" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card card-custom gutter-b">
            <div className="card-header card-header-tabs-line">
              <div className="card-toolbar">
                <ul
                  className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                  role="tablist"
                >
                  {["User Information"].map((data, index) => (
                    <SubTab key={index} name={data} index={index} />
                  ))}
                </ul>
              </div>
            </div>

            <div className="card-body px-0">
              <div className="tab-content px-10">
                <div
                  className={`tab-pane active`}
                  id={`kt_apps_contacts_view_tab_3`}
                  role="tabpanel"
                >
                  {userData.length > 0 &&
                    userData.map((user, index) => (
                      <div key={index} className="form-group row my-2">
                        <label className="col-4 col-form-label">
                          {user.title}
                        </label>
                        <div className="col-8">
                          <span className="form-control-plaintext font-weight-bolder">
                            {user.value}
                          </span>
                        </div>
                      </div>
                    ))}
                  {/* <div className="form-group row my-2">
                    <label className="col-4 col-form-label">
                      Privilege User
                    </label>
                    <div className="col-8">
                      <span className="form-control-plaintext font-weight-bolder">
                        <input
                          onChange={privilageHandler}
                          type="checkbox"
                          checked={isPrivilageUser}
                        />{" "}
                        Make Privilege
                      </span>
                    </div>
                  </div> */}
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body" style={{ backgroundColor: "white" }}>
            <div className="dataTables_wrapper ">
              <Table
                currentSort={""}
                sortingHandler={""}
                mainData={ads}
                tableHeading={Object.keys(OBJ_TABLE)}
                tableData={Object.values(OBJ_TABLE)}
                renderAs={{
                  isReported: (val) =>
                    val.toString().charAt(0).toUpperCase() +
                    val.toString().slice(1),
                  price: (val) => (val ? val : "-"),
                }}
                links={[
                  {
                    isLink: false,
                    name: "Deactivate",
                    click: changeStatusHandler,
                    title: "Click To Activate",
                    access: ["a9"],
                  },
                  {
                    isLink: false,
                    name: "Activate",
                    click: changeStatusHandler,
                    title: "Click To Deactivate",
                    access: ["a9"],
                  },
                  {
                    isLink: false,
                    name: "Delete",
                    click: deleteHandler,
                    access: ["a6"],
                    show: (user) => {
                      return !user.isAccountDeleted;
                    },
                  },
                ]}
                onlyDate={{
                  createdAt: "date",
                  startDate: "dateTime",
                  endDate: "dateTime",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOne;
