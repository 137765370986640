import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { SearchInput, SearchSubmitButton } from "../Form/Form";
import { addOneToDate, intersect } from "../../util/fn";

const OBJ_TABLE = {
  "user id": "userId",
  Name: "name",
  email: "email",
  "email verified": "isEmailVerified",
  "phone number": "contact",
  "registered on": "createdAt",
  status: "isActive",
  "disabled by user": "isAccountDisabled",
};

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  isActive = "",
  userId = "",
  name = "",
  email = "",
  city = "",
  school = "",
  isEmailVerified = "",
  contact = "",
  dateFrom = "1970-01-01",
  dateTo,
  isAccountDisabled
) => {
  if (sortBy.length > 0) {
    if (sortBy == "registered on") {
      sortBy = "createdAt";
    } else if (sortBy === "phone number") {
      sortBy = "contact";
    }
  } else {
    sortBy = "createdAt";
  }
  order = order.length > 0 ? order : "desc";

  dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";

  dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());

  return `user/all?page=${page}&per_page=${per_page}&sortBy=${sortBy}&order=${order}&isActive=${isActive}&userId=${userId}&name=${name}&email=${email}&city=${city}&school=${school}&isEmailVerified=${isEmailVerified}&contact=${contact}&dateFrom=${dateFrom}&dateTo=${dateTo}&isAccountDisabled=${isAccountDisabled}&role=seeker`;
};

const ViewAll = () => {
  const [seekers, setSeekers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "registered on",
    order: "desc",
  });

  const [cities, setCities] = useState([]);
  const [schools, setSchools] = useState([]);

  const MySwal = withReactContent(Swal);

  const { records_per_page } = useSelector((state) => state.setting);
  const { permissions, roleId } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    clearErrors,
    setError,
  } = useForm();

  const { request, response } = useRequest();
  const { request: requestChangeStatus, response: responseChangeStatus } =
    useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();
  const { request: requestSendCreds, response: responseSendCreds } =
    useRequest();
  const { request: loginRequest, response: loginResponce } = useRequest();
  const { request: enableAccountRequest, response: enableAccountResponce } =
    useRequest();

  const { request: cityListReq, response: cityListRes } = useRequest();
  const { request: schoolListReq, response: schoolListRes } = useRequest();
  const { request: recoverReq, response: recoverRes } = useRequest();

  useEffect(() => {
    cityListReq("GET", "admin/cities");
    schoolListReq("GET", "admin/schools");
  }, []);

  useEffect(() => {
    if (cityListRes && cityListRes.status) {
      setCities(cityListRes.data);
    }
  }, [cityListRes]);

  useEffect(() => {
    if (schoolListRes && schoolListRes.status) {
      setSchools(schoolListRes.data);
    }
  }, [schoolListRes]);

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = "Users - Kura";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      setSeekers(response.users);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  useEffect(() => {
    if (responseChangeStatus) {
      const { id, status } = responseChangeStatus;

      const oldSeekers = [...seekers];
      const indexToChange = oldSeekers.findIndex((seeker) => seeker._id == id);
      oldSeekers[indexToChange].isActive = status;

      setSeekers(oldSeekers);
    }
  }, [responseChangeStatus]);

  useEffect(() => {
    if (enableAccountResponce) {
      const { id, isAccountDisabled, isAccountDeleted } = enableAccountResponce;

      const oldSeekers = [...seekers];
      const indexToChange = oldSeekers.findIndex((seeker) => seeker._id == id);
      oldSeekers[indexToChange].isAccountDisabled = isAccountDisabled;
      oldSeekers[indexToChange].isAccountDeleted = isAccountDeleted;

      setSeekers(oldSeekers);
    }
  }, [enableAccountResponce]);

  useEffect(() => {
    if (responseDelete && responseDelete?.status) {
      const { id } = responseDelete;
      let newSeeker = [...seekers];
      // newSeeker = newSeeker.filter((seeker) => seeker._id != id);
      newSeeker = newSeeker.map((seeker) => {
        if (seeker._id == id) {
          // console.log({...seeker})
          seeker.isAccountDeleted = true;
          seeker.isAccountDisabled = true;
          // console.log({...seeker})
        }

        return seeker;
      });
      setSeekers(newSeeker);
      toast.success("User has been deleted successfully.");
    }
  }, [responseDelete]);

  useEffect(() => {
    if (responseSendCreds) {
      toast.success("Login credentials sent successfully");
    }
  }, [responseSendCreds]);

  useEffect(() => {
    if (loginResponce) {
      window.open(
        `http://192.168.235.200:3002?token=${loginResponce?.token}`,
        "_blank",
        "noopener,noreferrer"
      );
    }
  }, [loginResponce]);

  const fetchMoreData = ({ selected }) => {
    setSeekers([]);
    const {
      isActive,
      userId,
      name,
      email,
      city,
      school,
      isEmailVerified,
      contact,
      dateFrom,
      dateTo,
      isAccountDisabled,
    } = getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        userId,
        name,
        email,
        city,
        school,
        isEmailVerified,
        contact,
        dateFrom,
        dateTo,
        isAccountDisabled
      )
    );
  };

  const deleteHandler = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You want to delete this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestDelete("DELETE", "user", { id });
      } else if (result.isDismissed) {
      }
    });
  };

  const onSearchHandler = (data) => {
    let {
      isActive,
      userId,
      name,
      email,
      city,
      school,
      isEmailVerified,
      contact,
      dateFrom,
      dateTo,
      isAccountDisabled,
    } = data;

    if (dateFrom && dateTo) {
      if (Moment(dateFrom).isAfter(dateTo)) {
        setError("dateTo", {
          type: "manual",
        });
        return;
      }
    }

    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        userId,
        name,
        email,
        city,
        school,
        isEmailVerified,
        contact,
        dateFrom,
        dateTo,
        isAccountDisabled
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("isActive");
    resetField("firstName");
    resetField("lastName");
    resetField("email");
    resetField("contact");
    resetField("dateFrom");
    resetField("dateTo");
    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const {
      isActive,
      userId,
      name,
      email,
      city,
      school,
      isEmailVerified,
      contact,
      dateFrom,
      dateTo,
      isAccountDisabled,
    } = getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        userId,
        name,
        email,
        city,
        school,
        isEmailVerified,
        contact,
        dateFrom,
        dateTo,
        isAccountDisabled
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    const {
      isActive,
      userId,
      name,
      email,
      city,
      school,
      isEmailVerified,
      contact,
      dateFrom,
      dateTo,
      isAccountDisabled,
    } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          newOrder,
          isActive,
          userId,
          name,
          email,
          city,
          school,
          isEmailVerified,
          contact,
          dateTo,
          isAccountDisabled
        )
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          "desc",
          isActive,
          userId,
          name,
          email,
          city,
          school,
          isEmailVerified,
          contact,
          dateFrom,
          dateTo,
          isAccountDisabled
        )
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  const changeStatusHandler = (id) => {
    const user = seekers.find((seeker) => seeker._id == id);
    const status = user.isActive == true ? false : true;

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestChangeStatus("PUT", "user/status", {
          id,
          status,
        });
      } else if (result.isDismissed) {
      }
    });
  };

  const changeAccountDisableHandler = (id) => {
    const user = seekers.find((seeker) => seeker._id == id);
    // const status = user.isAccountDisabled == false;

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to Enable?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        enableAccountRequest("PUT", "user/enable-user", {
          id,
        });
      } else if (result.isDismissed) {
      }
    });
  };

  const sendCredentials = (id) => {
    requestSendCreds("POST", "user/send-credentials", { id });
  };

  const loginUser = (id) => {
    loginRequest("POST", "admin/login-user", { id });
  };

  const InputFields = [
    {
      label: "User Id",
      name: "userId",
      required: false,
    },
    {
      label: "Name",
      name: "name",
      required: false,
    },
    {
      label: "Email",
      name: "email",
      required: false,
    },
    {
      label: "Email Verified",
      name: "isEmailVerified",
      required: false,
      isSelectInput: true,
      children: (
        <>
          <option value="">Select</option>
          <option value={true}>True</option>
          <option value={false}>False</option>
        </>
      ),
    },
    {
      label: "Location/City",
      name: "city",
      required: false,
      isSelectInput: true,
      children: (
        <>
          <option value="">Select</option>
          {cities.map((val, i) => (
            <option key={i} value={val.name}>
              {val.name}
            </option>
          ))}
        </>
      ),
    },
    {
      label: "School",
      name: "school",
      required: false,
      isSelectInput: true,
      children: (
        <>
          <option value="">Select</option>
          {schools.map((val, i) => (
            <option key={i} value={val.name}>
              {val.name}
            </option>
          ))}
        </>
      ),
    },
    {
      label: "Status",
      name: "isActive",
      required: false,
      isSelectInput: true,
      children: (
        <>
          {/* <option value={undefined}>Select</option> */}
          <option value="">Select</option>
          <option value={true}>Activated</option>
          <option value={false}>Deactivated</option>
        </>
      ),
    },
    {
      label: "Phone Number",
      name: "contact",
      required: false,
    },
    {
      label: "Date From",
      name: "dateFrom",
      isDate: true,
      clearErrors,
    },
    {
      label: "Date To",
      name: "dateTo",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback: "'To Date' cannot be smaller than 'From Date'",
      },
    },
    {
      label: "Disabled by user",
      name: "isAccountDisabled",
      required: false,
      isSelectInput: true,
      children: (
        <>
          {/* <option value={undefined}>Select</option> */}
          <option value="">Select</option>
          <option value={false}>True</option>
          <option value={true}>False</option>
        </>
      ),
    },
  ];

  // const changeRecoverHandler = (id) => {
  //   recoverReq('PUT', 'user/recover', { id })
  // }

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb title="Users" links={[{ to: "/", name: "Dashboard" }]} />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>

                    {(roleId === 1 ||
                      (roleId === 2 &&
                        permissions &&
                        intersect(permissions, ["a2"]))) && (
                      <Link to="/users/add" className="btn btn-primary">
                        Add New User
                      </Link>
                    )}
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={seekers}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      renderAs={{
                        isEmailVerified: (val) =>
                          val.toString().charAt(0).toUpperCase() +
                          val.toString().slice(1),
                        isAccountDisabled: (val) =>
                          val.toString().charAt(0).toUpperCase() +
                          val.toString().slice(1),
                        name: (val) => val ?? "-",
                        email: (val) => val ?? "-",
                        contact: (val) => val ?? "-",
                      }}
                      links={[
                        // {
                        //   isLink: true,
                        //   to: `/wallet/view`,
                        //   name: "Wallet",
                        //   extraData: true,
                        // },
                        {
                          isLink: false,
                          name: "Deactivate",
                          click: changeStatusHandler,
                          title: "Click To Activate",
                          access: ["a9"],
                        },
                        {
                          isLink: false,
                          name: "Activate",
                          click: changeStatusHandler,
                          title: "Click To Deactivate",
                          access: ["a9"],
                        },

                        // {
                        //   isLink: true,
                        //   to: "/users/view",
                        //   name: "View",
                        //   extraData: true,
                        //   access: ["a4"],
                        // },
                        {
                          isLink: true,
                          to: "/users/edit",
                          name: "Edit",
                          extraData: true,
                          access: ["a5"],
                        },
                        {
                          isLink: true,
                          to: "/users/logs",
                          name: "Log",
                          extraData: true,
                          access: ["a11"],
                        },
                        {
                          isLink: false,
                          name: "Delete",
                          click: deleteHandler,
                          access: ["a6"],
                          show: (user) => {
                            return !user.isAccountDeleted;
                          },
                        },
                        {
                          isLink: false,
                          name: "Enable",
                          click: changeAccountDisableHandler,
                          title: "Click To Enable",
                          show: (user) => {
                            return (
                              user.isAccountDisabled || user.isAccountDeleted
                            );
                          },
                          access: ["a10"],
                        },
                        {
                          isLink: true,
                          to: "/users/change-password",
                          name: "ChangePassword",
                          extraData: true,
                          title: "Change Password",
                          access: ["a7"],
                        },
                        {
                          isLink: false,
                          name: "SendCreds",
                          click: sendCredentials,
                          title: "Send Credentials",
                          access: ["a8"],
                        },
                        // {
                        //   isLink: false,
                        //   name: "Login",
                        //   click: loginUser,
                        //   title: "Login",
                        // },
                      ]}
                      onlyDate={{
                        createdAt: "date",
                        startDate: "dateTime",
                        endDate: "dateTime",
                      }}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={seekers.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
