import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { ExportButton, SearchInput, SearchSubmitButton } from "../Form/Form";
import { ADMINURL } from "../../constant/api";
import { addOneToDate, downloadExcel, downloadPDF } from "../../util/fn";
import { useHistory } from "react-router-dom";
import moment from "moment";

const OBJ_TABLE = {
  "bookstore Id": "bookstoreId",
  "user name": "user_name",
  // Category: "category",
  authorName: "authorName",
  condition: "condition",
  price: "price",
  "Created At": "createdAt",
  isApproved: "status",
  status: "isActive",
  Reported: "isReported",
};

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  isActive = "",
  category = "",
  dateFrom = "1970-01-01",
  dateTo,
  user_name = "",
  user_customId = "",
  user_userName = "",
  bookstoreId = "",
  status = "",
  name = "",
  authorName = "",
  condition = ""
) => {
  if (sortBy.length > 0) {
    if (sortBy == "registered on" || sortBy == "Created At") {
      sortBy = "createdAt";
    }
  } else {
    sortBy = "createdAt";
  }
  order = order.length > 0 ? order : "desc";
  dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";

  dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());

  return `bookstore?page=${page}&per_page=${per_page}&sortBy=${sortBy}&order=${order}&isActive=${isActive}&category=${category}&dateFrom=${dateFrom}&dateTo=${dateTo}&user_name=${user_name}&user_customId=${user_customId}&user_userName=${user_userName}&bookstoreId=${bookstoreId}&status=${status}&name=${name}&authorName=${authorName}&condition=${condition}`;
};

const ViewAll = () => {
  const history = useHistory();
  const [bookstore, setbookstore] = useState([]);
  const [category, setCategory] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "Created At",
    order: "desc",
  });

  const MySwal = withReactContent(Swal);

  const { records_per_page, date_format } = useSelector(
    (state) => state.setting
  );
  const { permissions, roleId } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    clearErrors,
    setError,
  } = useForm();

  const { request, response } = useRequest();
  const { request: requestChangeStatus, response: responseChangeStatus } =
    useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();
  const { request: requestCheckApproved, response: responseCheckApproved } =
    useRequest();
  const { request: requestApproveConsent, response: responseApproveConsent } =
    useRequest();
  const { response: categoriesRes, request: categoriesReq } = useRequest();
  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = "Bookstore  - Kura";
    categoriesReq("GET", "admin/bookstore-categories");
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      setbookstore(response.bookstores);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  useEffect(() => {
    if (categoriesRes && categoriesRes?.status) {
      setCategory(categoriesRes.categories);
    }
  }, [categoriesRes]);

  useEffect(() => {
    if (responseChangeStatus) {
      const { id, newStatus } = responseChangeStatus;

      const oldbookstore = [...bookstore];
      const indexToChange = oldbookstore.findIndex(
        (scholar) => scholar._id == id
      );
      oldbookstore[indexToChange].isActive = newStatus;

      setbookstore(oldbookstore);
    }
  }, [responseChangeStatus]);

  useEffect(() => {
    if (responseCheckApproved) {
      const { id, status } = responseCheckApproved;

      const oldbookstore = [...bookstore];
      const indexToChange = oldbookstore.findIndex((hub) => hub._id == id);
      oldbookstore[indexToChange].status = status;

      setbookstore(oldbookstore);
    }
  }, [responseCheckApproved]);

  useEffect(() => {
    if (responseDelete) {
      const { id } = responseDelete;
      let newbookstore = [...bookstore];
      newbookstore = newbookstore.filter((ty) => ty._id != id);
      setbookstore(newbookstore);
      toast.success("Bookstore has been deleted successfully.");
    }
  }, [responseDelete]);

  const fetchMoreData = ({ selected }) => {
    setbookstore([]);
    const {
      isActive,
      category,
      dateFrom,
      dateTo,
      user_name,
      user_customId,
      user_userName,
      bookstoreId,
      status,
      name,
      authorName,
      condition,
    } = getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        category,
        dateFrom,
        dateTo,
        user_name,
        user_customId,
        user_userName,
        bookstoreId,
        status,
        name,
        authorName,
        condition
      )
    );
  };

  const deleteHandler = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You want to delete this bookstore?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestDelete("DELETE", "bookstore", { id });
      } else if (result.isDismissed) {
      }
    });
  };

  const onSearchHandler = (data) => {
    let {
      isActive,
      category,
      dateFrom,
      dateTo,
      user_name,
      user_customId,
      user_userName,
      bookstoreId,
      status,
      name,
      authorName,
      condition,
    } = data;

    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        category,
        dateFrom,
        dateTo,
        user_name,
        user_customId,
        user_userName,
        bookstoreId,
        status,
        name,
        authorName,
        condition
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("isActive");
    resetField("dateFrom");
    resetField("dateTo");
    resetField("user_name");
    resetField("dateFrom");
    resetField("dateTo");
    resetField("user_userName");
    resetField("user_customId");
    resetField("status");
    resetField("bookstoreId");
    resetField("name");
    resetField("authorName");
    resetField("condition");
    resetField("category");
    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const {
      isActive,
      category,
      dateFrom,
      dateTo,
      user_name,
      user_customId,
      user_userName,
      bookstoreId,
      status,
      name,
      authorName,
      condition,
    } = getValues();
    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        category,
        dateFrom,
        dateTo,
        user_name,
        user_customId,
        user_userName,
        bookstoreId,
        status,
        name,
        authorName,
        condition
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    const {
      isActive,
      category,
      dateFrom,
      dateTo,
      user_name,
      user_customId,
      user_userName,
      bookstoreId,
      status,
      name,
      authorName,
      condition,
    } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          newOrder,
          isActive,
          category,
          dateFrom,
          dateTo,
          user_name,
          user_customId,
          user_userName,
          bookstoreId,
          status,
          name,
          authorName,
          condition
        )
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          "desc",
          isActive,
          category,
          dateFrom,
          dateTo,
          user_name,
          user_customId,
          user_userName,
          bookstoreId,
          status,
          name,
          authorName,
          condition
        )
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  const openCreatorHandler = (id, data) => {
    // window.open(`${ADMINURL}/users/view/${data.userId}`, "_blank");
    history.push(`/users/view/${data.userId}`);
  };

  const changeStatusHandler = (id) => {
    const ehub = bookstore.find((hub) => hub._id == id);
    const status = ehub.isActive == true ? false : true;

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestChangeStatus("PUT", "bookstore/status", {
          id,
          status,
        });
      } else if (result.isDismissed) {
      }
    });
  };

  const changeApprovedHandler = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Approve",
      denyButtonText: `Not - Approve`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        requestCheckApproved("PUT", "bookstore/approve", {
          id,
          status: "approved",
        });
      } else if (result.isDenied) {
        requestCheckApproved("PUT", "bookstore/approve", {
          id,
          status: "rejected",
        });
      }
    });
  };

  const viewReportHandler = (data) => {
    history.push(`/report/viewlist/bookstore/${data}`);
  };

  const InputFields = [
    {
      label: "User (Name)",
      name: "user_name",
      required: false,
    },
    {
      label: "User Name",
      name: "user_userName",
      required: false,
    },
    {
      label: "User Id",
      name: "user_customId",
      required: false,
    },
    {
      label: "Bookstore Id",
      name: "bookstoreId",
      required: false,
    },
    {
      label: "Category",
      name: "category",
      required: false,
      isSelectInput: true,
      children: (
        <>
          <option value="">Select</option>
          {category.length > 0 &&
            category.map((type, i) => {
              return (
                <option key={i} value={type.category}>
                  {type.category}
                </option>
              );
            })}
        </>
      ),
    },
    {
      label: "isActive",
      name: "isActive",
      required: false,
      isSelectInput: true,
      children: (
        <>
          <option value="">Select</option>
          <option value={true}>Active</option>
          <option value={false}>Not active</option>
        </>
      ),
    },

    {
      label: "Date From",
      name: "dateFrom",
      isDate: true,
      clearErrors,
    },
    {
      label: "Date To",
      name: "dateTo",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback: "'To Date' cannot be smaller than 'From Date'",
      },
    },
  ];

  const changeConsentApprovedHandler = (id) => {
    // const scholars = accommodation.find((scholar) => scholar._id == id);
    // const status = scholars.isApproved == "true" ? "false" : "true";

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Approve",
      denyButtonText: `Not - Approve`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        requestApproveConsent("PUT", "bookstore-consent/approve-by-bookstore", {
          id,
          status: "approved",
        });
      } else if (result.isDenied) {
        requestApproveConsent("PUT", "bookstore-consent/approve-by-bookstore", {
          id,
          status: "rejected",
        });
      }
    });

    // MySwal.fire({
    //   title: "Are you sure?",
    //   text: "Want to change this status?",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonText: "Yes, change it",
    //   cancelButtonText: "No, cancel",
    //   reverseButtons: true,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     requestChangeStatus("PUT", "accommodation/status", {
    //       id,
    //       status,
    //     });
    //   } else if (result.isDismissed) {
    //   }
    // });
  };

  useEffect(() => {
    if (responseApproveConsent) {
      const { id, newStatus, consentId } = responseApproveConsent;

      // const oldBookstore = [...bookstore];
      // const indexToChange = oldBookstore.findIndex(
      //   (scholar) => scholar._id == id
      // );
      // oldBookstore[indexToChange].consentStatus = newStatus;

      let oldBookstore = bookstore.map((val) => {
        if (val.consentId === consentId) {
          return { ...val, consentStatus: newStatus };
        } else {
          return { ...val };
        }
      });

      setbookstore(oldBookstore);
    }
  }, [responseApproveConsent]);

  const getDownloadData = () => {
    let data = JSON.parse(JSON.stringify(bookstore));

    data = data.map((item) => {
      return {
        "Bookstore Id": item.bookstoreId,
        "User Name": item.user_name,
        "Author Name": item.authorName,
        Condition: item.condition,
        Price: item.price,
        "Created At": moment(item.createdAt).format(date_format),
        "Is Approved":
          item.status.charAt(0).toUpperCase() + item.status.slice(1),
        Status: item.isActive ? "Active" : "Inactive",
        Reported: item.isReported ? "True" : "False",
      };
    });

    return data;
  };

  const downloadExcelHandler = () => {
    const data = getDownloadData();

    downloadExcel(data, "Bookstore");
  };

  const generatePdfDocument = async () => {
    const data = getDownloadData();

    const headings = data.length > 0 ? Object.keys(data[0]) : [];

    await downloadPDF(headings.length, headings, data, "Bookstore");
  };

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb title="Bookstore" links={[{ to: "/", name: "Dashboard" }]} />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <ExportButton
                      downloadExcelHandler={downloadExcelHandler}
                      downloadPdfHandler={generatePdfDocument}
                    />

                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>

                    {/* {(roleId === 1 ||
                      (roleId === 2 &&
                        permissions &&
                        intersect(permissions, ["u4"]))) && (
                      <Link
                        to="/bookstore/add"
                        className="btn btn-primary"
                      >
                        Add New Book Store
                      </Link>
                    )} */}
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={bookstore}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      renderAs={{
                        status: (val) =>
                          val.charAt(0).toUpperCase() + val.slice(1),
                        isReported: (val) => (val ? "True" : "False"),
                      }}
                      links={[
                        // {
                        //   isLink: true,
                        //   to: `/wallet/view`,
                        //   name: "Wallet",
                        //   extraData: true,
                        // },
                        {
                          isLink: false,
                          name: "Deactivate",
                          click: changeStatusHandler,
                          title: "Click To Activate",
                          access: ["u3"],
                        },
                        {
                          isLink: false,
                          name: "Approved",
                          click: changeApprovedHandler,
                          title: "Click To Approve/Reject",
                          access: ["u7"],
                        },
                        {
                          isLink: false,
                          name: "Activate",
                          click: changeStatusHandler,
                          title: "Click To Deactivate",
                          access: ["u3"],
                        },
                        {
                          isLink: false,
                          name: "Delete",
                          click: deleteHandler,
                          access: ["u6"],
                        },
                        {
                          isLink: false,
                          name: "Creator",
                          click: openCreatorHandler,
                          title: "View Creator",
                          access: ["u9"],
                        },
                        {
                          isLink: false,
                          name: "Report",
                          click: viewReportHandler,
                          title: "View report",
                          access: ["u8"],
                        },
                      ]}
                      onlyDate={{
                        createdAt: "date",
                        startDate: "dateTime",
                        endDate: "dateTime",
                        deadline: "dateTime",
                      }}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={bookstore.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
