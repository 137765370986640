import React, { useRef, useEffect } from "react";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import ClassicEditor from "../../ckeditor5/build/ckeditor";
import { CKEditor } from "ckeditor4-react";
import { API } from "../../constant/api";

let currDate = new Date();
currDate = currDate.toISOString().split("T")[0];

export const Input = ({
  colClass,
  label,
  type,
  tooltip,
  name,
  min,
  errors,
  register,
  registerFields,
  inputData,
  otherRegisterFields,
  registerFieldsFeedback,
  children,
  isDate,
}) => {
  let [k, v] = name.split(".");
  let isKey = v ? (errors[k] ? errors[k][v] : errors[name]) : errors[name];

  const dateRef = useRef();

  const openDatePicker = () => {
    if (isDate) {
      dateRef.current.children[1].showPicker();
    }
    // dateRef.current.children;
  };

  return (
    <div className={`${colClass ? colClass : "col-xl-6"}`}>
      <div ref={dateRef} onClick={openDatePicker} className="form-group">
        <label>
          {label}{" "}
          {registerFields.required ? (
            <span className="text-danger">*</span>
          ) : (
            ""
          )}
          {tooltip?.show ? (
            <i className="fa fa-question-circle fa-1x" title={tooltip?.title} />
          ) : (
            ""
          )}
        </label>

        <input
          type={type}
          className={`form-control form-control-solid form-control-lg ${
            isKey && "is-invalid"
          }`}
          name={name}
          min={min}
          placeholder={label}
          {...register(name, registerFields)}
          {...inputData}
        />
        {registerFields?.required && isKey?.type === "required" && (
          <div className="invalid-feedback">
            The {label.toLowerCase()} field is required.
          </div>
        )}
        {registerFields?.minLength && isKey?.type === "minLength" && (
          <div className="invalid-feedback">
            The {label.toLowerCase()} field is invalid.
          </div>
        )}
        {registerFields?.maxLength && isKey?.type === "maxLength" && (
          <div className="invalid-feedback">
            The {label.toLowerCase()} field is invalid.
          </div>
        )}
        {registerFields?.min >= 0 && isKey?.type === "min" && (
          <div className="invalid-feedback">
            The {label.toLowerCase()} field is invalid.
          </div>
        )}
        {registerFields?.pattern && isKey?.type === "pattern" && (
          <div className="invalid-feedback">
            {registerFieldsFeedback?.pattern}
          </div>
        )}
        {otherRegisterFields?.manual && isKey?.type === "manual" && (
          <div className="invalid-feedback">{otherRegisterFields.feedback}</div>
        )}
      </div>
      {children}
    </div>
  );
};

export const CKEditorInput = ({
  colClass,
  label,
  name,
  errors,
  registerFields,
  getValues,
  setValue,
  trigger,
  inputData,
  otherRegisterFields,
  clearErrors,
  isEdit,
}) => {
  return (
    <div className={`${colClass ? colClass : "col-xl-6"}`}>
      <div className="form-group">
        <label>
          {label}{" "}
          {registerFields?.required ? (
            <span className="text-danger">*</span>
          ) : (
            ""
          )}
        </label>
        <div className={`${errors[name] && "is-invalid"}`}>
          {/* <CKEditor
            editor={ClassicEditor}
            data={getValues(name)}
            onChange={(event, editor) => {
              const data = editor.getData();
              setValue(name, data);
              trigger(name);
              clearErrors(name);
            }}
            {...inputData}
          /> */}
          {isEdit && getValues(name) && (
            <CKEditor
              initData={getValues(name)}
              config={{
                extraAllowedContent:
                  "p(*)[*]{*};div(*)[*]{*};li(*)[*]{*};ul(*)[*]{*};i(*)[*]{*}",
                allowedContent: true,
                protectedSource: [/<i[^>]*><\/i>/g],
                // removeEmpty: { i: false },
              }}
              onChange={({ editor }) => {
                const data = editor.getData();
                const isZero = data.trim().length == 0;
                setValue(name, isZero ? " " : data.trim());
                trigger(name);
                clearErrors(name);
              }}
              {...inputData}
            />
          )}
          {!isEdit && (
            <CKEditor
              initData={getValues(name)}
              config={{
                extraAllowedContent:
                  "p(*)[*]{*};div(*)[*]{*};li(*)[*]{*};ul(*)[*]{*};i(*)[*]{*}",
                allowedContent: true,
                protectedSource: [/<i[^>]*><\/i>/g],
                // removeEmpty: { i: false },
              }}
              onChange={({ editor }) => {
                const data = editor.getData();
                const isZero = data.trim().length == 0;
                setValue(name, isZero ? " " : data.trim());
                trigger(name);
                clearErrors(name);
              }}
              {...inputData}
            />
          )}
        </div>

        {registerFields?.required && errors[name]?.type === "required" && (
          <div className="invalid-feedback">
            The {label.toLowerCase()} field is required.
          </div>
        )}
        {otherRegisterFields?.manual && errors[name]?.type === "manual" && (
          <div className="invalid-feedback">{otherRegisterFields.feedback}</div>
        )}
      </div>
    </div>
  );
};

export const SelectInput = ({
  colClass,
  label,
  name,
  errors,
  register,
  registerFields,
  children,
  onChange,
  moreData,
}) => {
  return (
    <div className={`${colClass ? colClass : "col-xl-6"}`}>
      <div className="form-group">
        <label>
          {label}{" "}
          {registerFields.required ? (
            <span className="text-danger">*</span>
          ) : (
            ""
          )}
        </label>

        <select
          name={name}
          className={`form-control form-control-solid form-control-lg ${
            errors[name] && "is-invalid"
          }`}
          {...register(name, registerFields)}
          onChange={(e) => onChange && onChange(e.target.value)}
        >
          {children}
        </select>

        {registerFields.required && errors[name]?.type === "required" && (
          <div className="invalid-feedback">
            The {label.toLowerCase()} field is required.
          </div>
        )}
      </div>
      {moreData}
    </div>
  );
};

export const Textarea = ({
  colClass,
  label,
  name,
  errors,
  register,
  registerFields,
  inputData,
}) => {
  let [k, v] = name.split(".");
  let isKey = v ? (errors[k] ? errors[k][v] : errors[name]) : errors[name];

  const refRegister = register(name, registerFields);

  return (
    <div className={`${colClass ? colClass : "col-xl-6"}`}>
      <div className="form-group">
        <label>
          {label}{" "}
          {registerFields.required ? (
            <span className="text-danger">*</span>
          ) : (
            ""
          )}
        </label>
        <textarea
          className={`form-control form-control-solid form-control-lg ${
            isKey && "is-invalid"
          }`}
          name={name}
          cols="30"
          rows="5"
          placeholder={`Enter ${name}`}
          // ref={inputData?.ref ?? null}
          // {...register(name, registerFields)}
          {...refRegister}
          ref={(e) => {
            refRegister.ref(e);
            // dobMonthInput.current = e;
            if (inputData?.ref) {
              inputData.ref.current = e;
            }
          }}
          // {...inputData}
        ></textarea>
        {registerFields?.required && isKey?.type === "required" && (
          <div className="invalid-feedback">
            The {label.toLowerCase()} field is required.
          </div>
        )}
      </div>
    </div>
  );
};

export const SearchInput = ({
  label,
  name,
  register,
  required,
  errors,
  isDate,
  clearErrors,
  otherRegisterFields,
  extras = {},
  isSelectInput,
  children,
}) => {
  const dateRef = useRef();

  const openDatePicker = () => {
    // dateRef.current.children;
    // console.log(dateRef.current);
  };

  return (
    <div className="col-lg-3 mb-lg-0 mb-6 mt-2">
      <label>{label}</label>

      {!isDate ? (
        !isSelectInput ? (
          <input
            type="text"
            placeholder={label}
            className={`form-control ${errors[name] && "is-invalid"}`}
            {...register(name, { required })}
            {...extras}
          />
        ) : (
          <select
            name={name}
            className={`form-control ${errors[name] && "is-invalid"}`}
            {...register(name, { required })}
            {...extras}
          >
            {children}
          </select>
        )
      ) : (
        <div ref={dateRef} onClick={openDatePicker}>
          <input
            type="date"
            placeholder={label}
            max={currDate}
            className={`form-control ${errors[name] && "is-invalid"}`}
            {...register(name, { required })}
            onChange={() => clearErrors && clearErrors(errors[name])}
            {...extras}
          />
        </div>
      )}

      {required && errors[name]?.type === "required" && (
        <div className="invalid-feedback">
          The {label.toLowerCase()} field is required.
        </div>
      )}
      {otherRegisterFields?.manual && errors[name]?.type === "manual" && (
        <div className="invalid-feedback">{otherRegisterFields.feedback}</div>
      )}
    </div>
  );
};

export const RenderInputFields = ({ InputFields, errors, register }) => {
  return (
    <>
      {InputFields.map((inputMain, index) => {
        return (
          <div key={index} className="row">
            {inputMain.map((InputSub, index) => {
              return (
                <InputSub.Component
                  key={index}
                  {...InputSub}
                  errors={errors}
                  register={register}
                />
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export const SubmitButton = ({ handleSubmit, onSubmit, name, pxClass }) => {
  return (
    <>
      <button
        onClick={handleSubmit(onSubmit)}
        style={{ display: "none" }}
      ></button>
      <div
        className={`d-flex justify-content-between border-top mt-5 pt-10 ${pxClass}`}
      >
        {/* px-10 */}
        <div className="mr-2">
          <button
            onClick={handleSubmit(onSubmit)}
            type="button"
            className="btn btn-success font-weight-bold text-uppercase px-9 py-4"
          >
            {name}
          </button>
        </div>
      </div>
    </>
  );
};

export const SearchSubmitButton = ({
  handleSubmit,
  onSearchHandler,
  onResetHandler,
}) => {
  return (
    <>
      <button
        onClick={handleSubmit(onSearchHandler)}
        style={{ display: "none" }}
      ></button>
      <div className="row mt-8">
        <div className="col-lg-12">
          <button
            className="btn btn-primary btn-primary--icon"
            id="kt_search"
            onClick={handleSubmit(onSearchHandler)}
          >
            <span>
              <span>Search</span>
              <i className="la la-search ml-1 pr-0"></i>
            </span>
          </button>
          &nbsp;&nbsp;
          <button
            className="btn btn-secondary btn-secondary--icon"
            id="kt_reset"
            data-toggle="collapse"
            data-target="#collapseOne6"
            onClick={onResetHandler}
          >
            <span>
              <i className="la la-close"></i>
              <span>Clear Search</span>
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export const OtherInput = ({
  label,
  type,
  name,
  errors,
  register,
  registerFields,
  otherRegisterFields,
  patternError,
}) => {
  return (
    <div className="form-group">
      <input
        className={`form-control ${
          errors[name] && "is-invalid"
        } form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6`}
        type={type}
        name={name}
        autoComplete="off"
        placeholder={`Enter ${label}`}
        {...register(name, registerFields)}
      />
      {registerFields?.required && errors[name]?.type === "required" && (
        <div className="invalid-feedback">
          The {label.toLowerCase()} field is required.
        </div>
      )}
      {registerFields?.pattern && errors[name]?.type === "pattern" && (
        <div className="invalid-feedback">{patternError}</div>
      )}
      {otherRegisterFields?.manual && errors[name]?.type === "manual" && (
        <div className="invalid-feedback">{otherRegisterFields.feedback}</div>
      )}
    </div>
  );
};

export const Pictures = ({ label, name, pictures }) => {
  pictures.map((data, i) => <img src={`${API.PORT}/${data}`} width="100px" />);
};

export const ExportButton = ({ downloadExcelHandler, downloadPdfHandler }) => {
  return (
    <div class="btn-group" role="group">
      <button
        type="button"
        class="btn btn-primary btn-primary--icon dropdown-toggle mr-2"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        Export
      </button>
      <div class="dropdown-menu">
        {downloadExcelHandler && (
          <a
            class="dropdown-item cursor-pointer"
            onClick={downloadExcelHandler}
          >
            As Excel
          </a>
        )}
        {downloadPdfHandler && (
          <a class="dropdown-item cursor-pointer" onClick={downloadPdfHandler}>
            As PDF
          </a>
        )}
      </div>
    </div>
  );
};
