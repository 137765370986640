import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";

import { SubTab } from "../Cms/TabNInput";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const Activated = () => (
  <span className="label label-lg label-light-success label-inline">
    Activated
  </span>
);

const Deactivated = () => (
  <span className="label label-lg label-light-danger label-inline">
    Deactivated
  </span>
);

const ViewOne = (props) => {
  const { id } = props.match.params;

  const [freelanceData, setFreelanceData] = useState([]);

  const { response: responsefreelanceData, request: requestfreelanceData } =
    useRequest();

  const { date_format } = useSelector((state) => state.setting);

  useEffect(() => {
    requestfreelanceData("GET", `freelance/${id}`);

    document.title = "View freelance - Kura";
  }, []);

  /*
address
contact
createdAt
description
price
status
studentLevel
time
type
*/
  useEffect(() => {
    if (responsefreelanceData) {
      const {
        description,
        status,
        createdAt,
        expertId,
        isPublished,
        category,
        isSold
      } = responsefreelanceData.freelanceData;
      setFreelanceData([
        { title: "Expert Id", value: expertId ?? "-" },
        { title: "Category", value: category ?? "-" },

        { title: "Description", value: description ?? "-" },
        { title: "Published", value: "true"  },
        // { title: "Student Level", value: studentLevel ?? "-" },
        // { title: "Contact", value: contact ?? "-" },
        // { title: "Price", value: price ?? "-" },
        // { title: "Time", value: time ?? "-" },
        {
          title: "Registered On",
          value: <Moment format={date_format}>{createdAt}</Moment>,
        },
        {
          title: "Status",
          value: status ?? "-",
        },
        {
          title: "Sold",
          value: isSold ? "True" : "False",
        },
      ]);
    }
  }, [responsefreelanceData]);

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="View Beta-help"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/freelance", name: "Back To Beta-help" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card card-custom gutter-b">
            <div className="card-header card-header-tabs-line">
              <div className="card-toolbar">
                <ul
                  className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                  role="tablist"
                >
                  {["Beta-help Information"].map((data, index) => (
                    <SubTab key={index} name={data} index={index} />
                  ))}
                </ul>
              </div>
            </div>

            <div className="card-body px-0">
              <div className="tab-content px-10">
                <div
                  className={`tab-pane active`}
                  id={`kt_apps_contacts_view_tab_3`}
                  role="tabpanel"
                >
                  {freelanceData.length > 0 &&
                    freelanceData.map((freelance, index) => (
                      <div key={index} className="form-group row my-2">
                        <label className="col-4 col-form-label">
                          {freelance.title}
                        </label>
                        <div className="col-8">
                          <span className="form-control-plaintext font-weight-bolder">
                            {freelance.value}
                          </span>
                        </div>
                      </div>
                    ))}

                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOne;
