import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Input, RenderInputFields, SubmitButton,SelectInput } from "../Form/Form";

const CourseAdd = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const { response, request } = useRequest();

  const history = useHistory();

  useEffect(() => {
    document.title = "Add Course Levels - Kura";
  }, []);

  useEffect(() => {
    if (response) {
      toast.success("Course - Level has been added successfully.");
      history.push("/course-level");
    }
  }, [response]);

  const onSubmit = (data) => {
    const {
      level
    } = data;

    request("POST", "master/course-level", {
        level
    });
  };

  const InputFields = [
    [
      // {
      //   Component: Input,
      //   label: "First Name",
      //   type: "text",
      //   name: "firstName",
      //   registerFields: {
      //     required: true,
      //     pattern: /^[A-Za-z ]+$/,
      //   },
      //   registerFieldsFeedback: {
      //     pattern: "Name can only contain letters.",
      //   },
      // },
      {
        Component: Input,
        label: "Course Level",
        type: "text",
        name: "level",
        registerFields: {
          required: true,
          // pattern: /^[A-Za-z ]+$/,
        },
        // registerFieldsFeedback: {
        //   pattern: "Level can only contain letters.",
        // },
      },
    ],

    
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add Course - Level"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/course-level", name: "Back To Course" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Add New Course
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseAdd;
