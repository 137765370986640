import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Input, RenderInputFields, SubmitButton } from "../Form/Form";

const userAccess = [
  {
    name: "View Access",
    key: "view",
    permissions: [
      {
        name: "Bookstore",
        key: "view_bookstore",
      },
      {
        name: "Up-cycle",
        key: "view_e-hub",
      },
      {
        name: "Beta-help Project",
        key: "view_freelanceProject",
      },
      {
        name: "Beta-help Expert",
        key: "view_freelanceExpert",
      },
      {
        name: "Room Mate",
        key: "view_roomMate",
      },
      {
        name: "Vacant Room",
        key: "view_vacantRoom",
      },
    ],
  },
  {
    name: "Post Access",
    key: "post",
    permissions: [
      {
        name: "Bookstore",
        key: "post_bookstore",
      },
      {
        name: "Up-cycle",
        key: "post_e-hub",
      },
      {
        name: "Beta-help Project",
        key: "post_freelanceProject",
      },
      {
        name: "Beta-help Expert",
        key: "post_freelanceExpert",
      },
      {
        name: "Room Mate",
        key: "post_roomMate",
      },
      {
        name: "Vacant Room",
        key: "post_vacantRoom",
      },
    ],
  },
  {
    name: "Privilage Access",
    key: "isPrivilage",
    permissions: [
      {
        name: "Make Privilage User",
        key: "isPrivilage",
      },
    ],
  },
];

const Edit = (props) => {
  const { id: seekerId } = props.match.params;
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();  

  const { response: responseFetchUser, request: requestFetchSeeker } =
    useRequest();

  const { response, request } = useRequest();

  useEffect(() => {
    if (seekerId) {
      requestFetchSeeker("GET", `user/${seekerId}`);
      document.title = "Edit User - Kura";
    }
  }, [seekerId]);

  useEffect(() => {
    if (responseFetchUser) {
      const {
        name,
        email,
        contact,
        viewPermissions,
        postPermissions,
        isPrivilage,
      } = responseFetchUser.user;
      setValue("name", name);
      setValue("email", email);
      setValue("contact", contact);
      setValue("isPrivilage", isPrivilage ? true : false);
      if (viewPermissions) {
        for (let a in viewPermissions) {
          setValue(`view_${a}`, viewPermissions[a]);
        }
      }
      if (postPermissions) {
        for (let a in postPermissions) {
          setValue(`post_${a}`, postPermissions[a]);
        }
      }
    }
  }, [responseFetchUser]);

  useEffect(() => {
    if (response) {
      toast.success("User has been updated successfully.");
      history.push("/users");
    }
  }, [response]);

  const onSubmit = (data) => {
    const { name, email, contact, isPrivilage } = data;

    let viewPermissions = {};
    let postPermissions = {};
    for (let a in data) {
      if (a.substring(0, 5) === "view_") {
        viewPermissions[a.substring(5)] = data[a];
      }
      if (a.substring(0, 5) === "post_") {
        postPermissions[a.substring(5)] = data[a];
      }
    }

    const { email: oldEmail, contact: oldContact } = responseFetchUser.user;

    let updates = {};

    // if (email.trim() != oldEmail) {
    //   updates.email = email;
    // }
    updates.email = email;
    if (contact != oldContact) {
      updates.contact = contact;
    }

    // console.log({
    //   name,
    //   ...updates,
    //   userId: seekerId,
    //   viewPermissions,
    //   postPermissions,
    //   isPrivilage,
    // });
    // return;

    request("PUT", "user", {
      name,
      ...updates,
      userId: seekerId,
      viewPermissions,
      postPermissions,
      isPrivilage,
    });
  };

  const InputFields = [
    [
      // {
      //   Component: Input,
      //   label: "First Name",
      //   type: "text",
      //   name: "firstName",
      //   registerFields: {
      //     required: true,
      //     pattern: /^[A-Za-z ]+$/,
      //   },
      //   registerFieldsFeedback: {
      //     pattern: "Name can only contain letters.",
      //   },
      // },
      {
        Component: Input,
        label: "Name",
        type: "text",
        name: "name",
        registerFields: {
          required: true,
          pattern: /^[A-Za-z ]+$/,
        },
        registerFieldsFeedback: {
          pattern: "Name can only contain letters.",
        },
      },
      {
        Component: Input,
        label: "Email",
        type: "email",
        name: "email",
        registerFields: {
          required: true,
          pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        },
        registerFieldsFeedback: {
          pattern: "The email field must be a valid email address.",
        },
      },
      {
        Component: Input,
        label: "Phone Number",
        type: "text",
        name: "contact",
        id : "contact_input_in_edit_user",
        registerFields: {
          // required: true,
          pattern: /^[+]?\d{2,20}$/,          
        },
        registerFieldsFeedback: {
          pattern: "Please provide a valid contact number.",
        },
      },
    ],
  ];  

  const selectAllHandler = (bool, key) => {
    let filteredAccess = userAccess.find((a) => a.key === key);
    filteredAccess.permissions.forEach((a) => {
      setValue(a.key, bool);
    });
  };

  let changes = watch();

  useEffect(() => {
    userAccess.forEach((val) => {
      let isAllSelected = true;
      let allSelectElem = document.getElementById(`sa_${val.key}`);
      if (!allSelectElem) return;
      allSelectElem.checked = true;
      val.permissions.forEach((subVal) => {
        if (changes[subVal.key] === false) {
          isAllSelected = false;
        }
      });
      if (!isAllSelected) {
        allSelectElem.checked = false;
      }
    });
  }, [changes]);  

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit User"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/users" /*backPageNum: page */ },
            name: "Back To Users",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Edit User
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="card card-custom gutter-b">
                      <div className="card-body">
                        {userAccess.map((perm, ind) => {
                          return (
                            <div
                              key={ind}
                              className="row mb-7"
                              style={{ flexDirection: "column" }}
                            >
                              <div className="col-12">
                                <div className="row ">
                                  <div className="col-0">
                                    <h4>{perm.name}</h4>
                                  </div>
                                  <div className="col-10">
                                    <label className="mr-2">All</label>
                                    <input
                                      type="checkBox"
                                      id={`sa_${perm.key}`}
                                      onClick={(e) =>
                                        selectAllHandler(
                                          e.target.checked,
                                          perm.key
                                        )
                                      }
                                    />
                                  </div>                                  
                                </div>
                              </div>
                              <div className="mt-3">
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "20px",
                                  }}
                                >
                                  {perm.permissions.map((val, i) => {
                                    return (
                                      <div key={i}>
                                        <label className="mr-2">
                                          {val.name}
                                        </label>
                                        <input                                          
                                          type="checkBox"
                                          name={val.key}
                                          {...register(val.key)}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              <div className="row"></div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
