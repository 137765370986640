import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { ExportButton, SearchInput, SearchSubmitButton } from "../Form/Form";
import {
  addOneToDate,
  downloadExcel,
  downloadPDF,
  intersect,
} from "../../util/fn";
import { ADMINURL } from "../../constant/api";
import moment from "moment";

const OBJ_TABLE = {
  "Up-cycle Id": "ehubId",
  "user name": "user_name",
  // title: "title",
  brand: "brand",
  // condition: "condition",
  Type: "type",
  price: "price",
  "Created at": "createdAt",
  isApproved: "status",
  status: "isActive",
  Reported: "isReported",
};

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  isActive = "",
  type = "",
  brand = "",
  title = "",
  dateFrom = "1970-01-01",
  dateTo,
  condition = "",
  user_name = "",
  user_customId = "",
  user_userName = "",
  ehubId = "",
  status = ""
) => {
  if (sortBy.length > 0) {
    if (sortBy == "registered on" || sortBy == "Created At") {
      sortBy = "createdAt";
    } else if (sortBy === "phone number") {
      sortBy = "contact";
    }
  } else {
    sortBy = "createdAt";
  }
  order = order.length > 0 ? order : "desc";

  dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";

  dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());

  return `ehub/all?page=${page}&per_page=${per_page}&sortBy=${sortBy}&order=${order}&isActive=${isActive}&type=${type}&brand=${brand}&title=${title}&dateFrom=${dateFrom}&dateTo=${dateTo}&condition=${condition}&user_name=${user_name}&user_customId=${user_customId}&user_userName=${user_userName}&ehubId=${ehubId}&status=${status}`;
};

const ViewAll = () => {
  const history = useHistory();
  const [ehub, setEhub] = useState([]);
  const [ehubTypes, setEhubTypes] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "Created At",
    order: "desc",
  });

  const MySwal = withReactContent(Swal);

  const { records_per_page, date_format } = useSelector(
    (state) => state.setting
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    clearErrors,
    setError,
  } = useForm();

  const { request, response } = useRequest();
  const { request: requestChangeStatus, response: responseChangeStatus } =
    useRequest();
  const { request: requestCheckApproved, response: responseCheckApproved } =
    useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();
  const { response: typesRes, request: typesReq } = useRequest();

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = "Up-cycle - Kura";
    typesReq("GET", "admin/ehub-types");
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      setEhub(response.ehub);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  useEffect(() => {
    if (typesRes && typesRes?.status) {
      setEhubTypes(typesRes.types);
    }
  }, [typesRes]);

  useEffect(() => {
    if (responseChangeStatus) {
      const { id, status } = responseChangeStatus;

      const oldScholarship = [...ehub];
      const indexToChange = oldScholarship.findIndex(
        (scholar) => scholar._id == id
      );
      oldScholarship[indexToChange].isActive = status;

      setEhub(oldScholarship);
    }
  }, [responseChangeStatus]);

  useEffect(() => {
    if (responseCheckApproved) {
      const { id, status } = responseCheckApproved;

      const oldEhub = [...ehub];
      const indexToChange = oldEhub.findIndex((hub) => hub._id == id);
      oldEhub[indexToChange].status = status;

      setEhub(oldEhub);
    }
  }, [responseCheckApproved]);

  useEffect(() => {
    if (responseDelete) {
      const { id } = responseDelete;
      let newEhub = [...ehub];
      newEhub = newEhub.filter((scholar) => scholar._id != id);
      setEhub(newEhub);
      toast.success("Up-cycle has been deleted successfully.");
    }
  }, [responseDelete]);

  const fetchMoreData = ({ selected }) => {
    setEhub([]);
    const {
      isActive,
      type,
      brand,
      title,
      dateFrom,
      dateTo,
      condition,
      user_name,
      user_customId,
      user_userName,
      ehubId,
      status,
    } = getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        type,
        brand,
        title,
        dateFrom,
        dateTo,
        condition,
        user_name,
        user_customId,
        user_userName,
        ehubId,
        status
      )
    );
  };

  const deleteHandler = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You want to delete this ehub?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestDelete("DELETE", "ehub", { id });
      } else if (result.isDismissed) {
      }
    });
  };

  const onSearchHandler = (data) => {
    let {
      isActive,
      type,
      brand,
      title,
      dateFrom,
      dateTo,
      condition,
      user_name,
      user_customId,
      user_userName,
      ehubId,
      status,
    } = data;

    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        type,
        brand,
        title,
        dateFrom,
        dateTo,
        condition,
        user_name,
        user_customId,
        user_userName,
        ehubId,
        status
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("isActive");
    resetField("type");
    resetField("brand");
    resetField("title");
    resetField("user_name");
    resetField("dateFrom");
    resetField("dateTo");
    resetField("user_userName");
    resetField("user_customId");
    resetField("ehubId");
    resetField("condition");
    resetField("status");
    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const {
      isActive,
      type,
      brand,
      title,
      dateFrom,
      dateTo,
      condition,
      user_name,
      user_customId,
      user_userName,
      ehubId,
      status,
    } = getValues();
    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        isActive,
        type,
        brand,
        title,
        dateFrom,
        dateTo,
        condition,
        user_name,
        user_customId,
        user_userName,
        ehubId,
        status
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    const {
      isActive,
      type,
      brand,
      title,
      dateFrom,
      dateTo,
      condition,
      user_name,
      user_customId,
      user_userName,
      ehubId,
      status,
    } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          newOrder,
          isActive,
          type,
          brand,
          title,
          dateFrom,
          dateTo,
          condition,
          user_name,
          user_customId,
          user_userName,
          ehubId,
          status
        )
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          "desc",
          isActive,
          type,
          brand,
          title,
          dateFrom,
          dateTo,
          condition,
          user_name,
          user_customId,
          user_userName,
          ehubId,
          status
        )
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  const changeStatusHandler = (id) => {
    const scholars = ehub.find((scholar) => scholar._id == id);
    const status = scholars.isActive == true ? false : true;

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestChangeStatus("PUT", "ehub/status", {
          id,
          status,
        });
      } else if (result.isDismissed) {
      }
    });
  };

  const openCreatorHandler = (id, data) => {
    // window.open(`${ADMINURL}/users/view/${data.userId}`, "_blank");
    history.push(`/users/view/${data.userId}`);
  };

  const changeApprovedHandler = (id) => {
    // const scholars = ehub.find((scholar) => scholar._id == id);
    // const status = scholars.isApproved == "true" ? "false" : "true";

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Approve",
      denyButtonText: `Not - Approve`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        requestCheckApproved("PUT", "ehub/approve", {
          id,
          status: "approved",
        });
      } else if (result.isDenied) {
        requestCheckApproved("PUT", "ehub/approve", {
          id,
          status: "rejected",
        });
      }
    });

    // MySwal.fire({
    //   title: "Are you sure?",
    //   text: "Want to change this status?",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonText: "Yes, change it",
    //   cancelButtonText: "No, cancel",
    //   reverseButtons: true,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     requestChangeStatus("PUT", "ehub/status", {
    //       id,
    //       status,
    //     });
    //   } else if (result.isDismissed) {
    //   }
    // });
  };

  const viewReportHandler = (data) => {
    history.push(`/report/viewlist/e-hub/${data}`);
  };

  const getDownloadData = () => {
    let data = JSON.parse(JSON.stringify(ehub));

    data = data.map((item) => {
      return {
        "Up-cycle Id": item.ehubId,
        "User Name": item.user_name,
        Brand: item.brand,
        Type: item.type,
        Price: item.price,
        "Created At": moment(item.createdAt).format(date_format),
        Status: item.isActive ? "Active" : "Inactive",
        Reported: item.isReported ? "True" : "False",
      };
    });

    return data;
  };

  const downloadExcelHandler = () => {
    const data = getDownloadData();

    downloadExcel(data, "Up Cycle");
  };

  const generatePdfDocument = async () => {
    const data = getDownloadData();

    const headings = data.length > 0 ? Object.keys(data[0]) : [];

    await downloadPDF(headings.length, headings, data, "Up Cycle");
  };

  const InputFields = [
    {
      label: "Brand",
      name: "brand",
      required: false,
    },
    {
      label: "Title",
      name: "title",
      required: false,
    },
    {
      label: "User (Name)",
      name: "user_name",
      required: false,
    },
    {
      label: "User Name",
      name: "user_userName",
      required: false,
    },
    {
      label: "User Id",
      name: "user_customId",
      required: false,
    },
    {
      label: "Up-cycle Id",
      name: "ehubId",
      required: false,
    },
    {
      label: "Condition",
      name: "condition",
      required: false,
      isSelectInput: true,
      children: (
        <>
          <option value="">Select</option>
          <option value="new">New</option>
          <option value="user">User</option>
        </>
      ),
    },
    {
      label: "Type",
      name: "type",
      required: false,
      isSelectInput: true,
      children: (
        <>
          <option value="">Select</option>
          {ehubTypes.length > 0 &&
            ehubTypes.map((type, i) => {
              return (
                <option key={i} value={type.type}>
                  {type.type}
                </option>
              );
            })}
        </>
      ),
    },

    {
      label: "Is Active",
      name: "isActive",
      required: false,
      isSelectInput: true,
      children: (
        <>
          <option value="">Select</option>
          <option value="true">Activated</option>
          <option value="false">Deactivated</option>
        </>
      ),
    },
    {
      label: "Status",
      name: "status",
      required: false,
      isSelectInput: true,
      children: (
        <>
          <option value="">Select</option>
          <option value="pending">Pending</option>
          <option value="approved">Approved</option>
          <option value="rejected">Rejected</option>
        </>
      ),
    },

    {
      label: "Date From",
      name: "dateFrom",
      isDate: true,
      clearErrors,
    },
    {
      label: "Date To",
      name: "dateTo",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback: "'To Date' cannot be smaller than 'From Date'",
      },
    },
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb title="Up-cycle" links={[{ to: "/", name: "Dashboard" }]} />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <ExportButton
                      downloadExcelHandler={downloadExcelHandler}
                      downloadPdfHandler={generatePdfDocument}
                    />

                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>

                    {/* <Link to="/ehub/add" className="btn btn-primary">
                      Add New Up-cycle
                    </Link> */}
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={ehub}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      renderAs={{
                        status: (val) =>
                          val.charAt(0).toUpperCase() + val.slice(1),
                        isReported: (val) => (val ? "True" : "False"),
                      }}
                      links={[
                        // {
                        //   isLink: true,
                        //   to: `/wallet/view`,
                        //   name: "Wallet",
                        //   extraData: true,
                        // },
                        {
                          isLink: false,
                          name: "Deactivate",
                          click: changeStatusHandler,
                          title: "Click To Activate",
                          access: ["l3"],
                        },
                        {
                          isLink: false,
                          name: "Activate",
                          click: changeStatusHandler,
                          title: "Click To Deactivate",
                          access: ["l3"],
                        },
                        {
                          isLink: false,
                          name: "Approved",
                          click: changeApprovedHandler,
                          title: "Click To Approve/Reject",
                          access: ["l7"],
                        },
                        // {
                        //   isLink: true,
                        //   to: "/ehub/view",
                        //   name: "View",
                        //   extraData: true,
                        // },
                        {
                          isLink: false,
                          name: "Creator",
                          click: openCreatorHandler,
                          title: "View Creator",
                          access: ["l9"],
                        },
                        // {
                        //   isLink: true,
                        //   to: "/ehub/edit",
                        //   name: "Edit",
                        //   extraData: true,
                        // },
                        {
                          isLink: false,
                          name: "Delete",
                          click: deleteHandler,
                          access: ["l6"],
                        },
                        {
                          isLink: false,
                          name: "Report",
                          click: viewReportHandler,
                          title: "View report",
                          access: ["l8"],
                        },
                      ]}
                      onlyDate={{
                        createdAt: "date",
                        startDate: "dateTime",
                        endDate: "dateTime",
                      }}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={ehub.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
