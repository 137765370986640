import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import moment from "moment";
import Select from "react-select";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { API } from "../../constant/api";

import {
  Input,
  RenderInputFields,
  SubmitButton,
  SelectInput,
  Textarea,
} from "../Form/Form";

const EditScholarship = (props) => {
  const { id: scholarId } = props.match.params;
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const [scholarshipTypes, setScholarshipTypes] = useState([]);
  const [scholarshipTags, setScholarshipTags] = useState([]);

  const {
    response: responseFetchScholarship,
    request: requestFetchScholarship,
  } = useRequest();
  const { response: typesRes, request: typesReq } = useRequest();
  const { response: tagRes, request: tagReq } = useRequest();

  const { response, request } = useRequest();
  const [showPhoto, setShowPhoto] = useState();
  useEffect(() => {
    if (scholarId) {
      typesReq("GET", "admin/scholarship-types");
      tagReq("GET", "admin/scholarship-tags");
      requestFetchScholarship("GET", `scholarship/${scholarId}`);
      document.title = "Edit Scholarship - Kura";
    }
  }, [scholarId]);

  useEffect(() => {
    if (typesRes && typesRes?.status) {
      setScholarshipTypes(typesRes.types);
    }
  }, [typesRes]);

  useEffect(() => {
    if (tagRes && tagRes?.status) {
      setScholarshipTags(tagRes.tags);
    }
  }, [tagRes]);

  useEffect(() => {
    if (responseFetchScholarship) {
      let {
        type,
        name,
        title,
        eligibility,
        deadline,
        externalLink,
        description,
        photo,
        tags,
      } = responseFetchScholarship.scholarship;
      setShowPhoto(photo);
      setValue("type", type);
      setValue("name", name);
      setValue("title", title);
      setValue("eligibility", eligibility);
      setValue("deadline", moment(deadline).utc().format("YYYY-MM-DD"));
      setValue("externalLink", externalLink);
      setValue("description", description);
      tags = scholarshipTags.filter((v) => tags.includes(v.tag));
      tags = tags.map((v) => {
        return {
          value: v._id,
          label: v.tag,
        };
      });
      setValue("tags", tags);
      // console.log(tags);
      // setValue("photo", photo);
      // setValue(
      //   "photo",
      //   "https://play-lh.googleusercontent.com/6UgEjh8Xuts4nwdWzTnWH8QtLuHqRMUB7dp24JYVE2xcYzq4HA8hFfcAbU-R-PC_9uA1"
      // );
    }
  }, [responseFetchScholarship]);

  useEffect(() => {
    if (response) {
      toast.success("Scholarship has been updated successfully.");
      history.push("/scholarship");
    }
  }, [response]);

  const onSubmit = (data) => {
    let {
      type,
      name,
      title,
      eligibility,
      deadline,
      externalLink,
      description,
      photo,
      tags,
    } = data;

    if (tags[0].value === "all") {
      tags = scholarshipTags.map((v) => v.tag);
    } else {
      tags = tags.map((v) => v.label);
    }

    const dataToUpdate = {
      type,
      name,
      title,
      eligibility,
      deadline,
      externalLink,
      description,
      scholarId,
      tags,
    };

    let formData = new FormData();

    if (photo && photo[0]) {
      formData.append("photo", photo[0]);
    }

    formData.append("dataToUpdate", JSON.stringify(dataToUpdate));

    request("PUT", "scholarship", formData);
  };

  const InputFields = [
    [
      {
        Component: SelectInput,
        label: "Type",
        name: "type",
        registerFields: {
          required: true,
        },
        children: (
          <>
            <option value="">Select</option>
            {scholarshipTypes.length > 0 &&
              scholarshipTypes.map((type, i) => {
                return (
                  <option key={i} value={type.type}>
                    {type.type}
                  </option>
                );
              })}
          </>
        ),
      },
      {
        Component: Input,
        label: "Name",
        type: "text",
        name: "name",
        registerFields: {
          required: true,
        },
      },
      // {
      //   Component: Input,
      //   label: "Title",
      //   type: "text",
      //   name: "title",
      //   registerFields: {
      //     required: true,
      //     pattern: /^[A-Za-z ]+$/,
      //   },
      //   registerFieldsFeedback: {
      //     pattern: "Title can only contain letters.",
      //   },
      // },
      {
        Component: Textarea,
        label: "Eligibility",
        type: "text",
        name: "eligibility",
        registerFields: {
          required: true,
          //   pattern:
          //     /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        },
        // registerFieldsFeedback: {
        //   pattern: "The email field must be a valid email address.",
        // },
      },
      {
        Component: Input,
        label: "Deadline",
        type: "date",
        name: "deadline",
        registerFields: {
          required: true,
        },
        isDate: true,
      },
      {
        Component: Input,
        label: "External Link",
        type: "text",
        name: "externalLink",
        registerFields: {
          required: true,
          pattern:
            /^http:\/\/|(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        },
        registerFieldsFeedback: {
          pattern: "Invalid link.",
        },
      },
      {
        Component: Input,
        label: "Photo",
        type: "file",
        name: "photo",
        registerFields: {
          //   required: true,
        },
        children: showPhoto ? (
          <img src={`${API.PORT}/${showPhoto}`} width="100px" />
        ) : (
          ""
        ),
      },
      {
        Component: Textarea,
        label: "Description",
        type: "text",
        name: "description",
        registerFields: {
          required: true,
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Scholarship"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/scholarship" /*backPageNum: page */ },
            name: "Back To Scholarship",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Edit Scholarship
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="col-xl-6">
                      <div className="form-group">
                        <label>
                          Scholarship Coverage/Benefits/Tags
                          <span className="text-danger">*</span>
                        </label>
                        <Controller
                          control={control}
                          name="tags"
                          {...register("tags", {
                            required: true,
                          })}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              isMulti
                              onChange={(val) => {
                                const isSelectAllAvailable = val.find(
                                  (v) => v.value == "all"
                                );

                                if (isSelectAllAvailable) {
                                  onChange([
                                    { value: "all", label: "Select All" },
                                  ]);
                                } else {
                                  return onChange(val);
                                }
                              }}
                              defaultValue={[]}
                              value={value}
                              // inputRef={ref}

                              options={[
                                { value: "all", label: "Select All" },
                                ...scholarshipTags.map((val) => {
                                  return {
                                    value: val._id,
                                    label: val.tag,
                                  };
                                }),
                              ]}
                              className={`basic-multi-select ${
                                errors.toUser ? "is-invalid" : ""
                              }`}
                              classNamePrefix="select"
                              menuPosition="fixed"
                            />
                          )}
                        />
                        {errors?.tags?.type === "required" && (
                          <div
                            style={{ display: "block" }}
                            className="invalid-feedback"
                          >
                            Please select tags.
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditScholarship;
