import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { SelectInput, RenderInputFields, SubmitButton } from "../Form/Form";

const getViewAddPath = {
  "e-hub": "/ehub/view",
  freelanceProject: "/freelance/project/view",
  freelanceExpert: "/freelance/expert/view",
  scholarship: "/scholarship/view",
  vacantRoom: "/accommodation/vacant-room/view",
  roomMate: "/accommodation/room-mate/view",
  bookstore: "/bookstore/view",
  user: "/users/view",
};
const ViewOne = (props) => {
  const { id: reportId } = props.match.params;
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [reportData, setReportData] = useState([]);
  const [resolved, setResolved] = useState(false);

  const { response: getReportRes, request: getReportReq } = useRequest();

  const { response, request } = useRequest();

  useEffect(() => {
    if (reportId) {
      getReportReq("GET", `report/${reportId}`);
      document.title = "Reports - Kura";
    }
  }, [reportId]);

  useEffect(() => {
    if (getReportRes) {
      const obj = getReportRes.report;
      let arr = [
        {
          title: "Add Type",
          value: (
            <>
              {obj.addType}{" "}
              <Link
                style={{ marginLeft: "20px" }}
                to={`${getViewAddPath[obj.addType]}/${obj.addId}`}
              >
                {obj.addType === "user" ? "View User" : "View Add"}
              </Link>
            </>
          ),
        },
        { title: "Reason", value: obj.reason },
        { title: "Description", value: obj.description },
        { title: "Resolved", value: obj.isResolved ? "True" : "False" },
        { title: "Reportee Name", value: obj.reporteeData.name },
        {
          title: "Reportee Id",
          value: (
            <>
              <Link to={`/users/view/${obj.reporteeData._id}`}>
                {obj.reporteeData.userId}
              </Link>
            </>
          ),
        },
      ];

      if (obj.isResolved) {
        setResolved(true);
      }

      setReportData(arr);
    }
  }, [getReportRes]);

  useEffect(() => {
    if (response) {
      toast.success("Report has been resolved successfully.");
      history.push("/report");
    }
  }, [response]);

  const onSubmit = (data) => {
    const { status } = data;
    request("PUT", "report/resolve", {
      id: reportId,
      status,
    });
  };

  const InputFields = [
    [
      {
        Component: SelectInput,
        label: "Approve or reject item",
        name: "status",
        registerFields: {
          // required: true,
        },
        isSelectInput: true,
        children: (
          <>
            {/* <option value={undefined}>Select</option> */}
            <option value="">Select</option>
            <option value="approved">Approve</option>
            <option value="rejected">Reject</option>
          </>
        ),
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="View report"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/report" /*backPageNum: page */ },
            name: "Back To Reports",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  {reportData.length > 0 &&
                    reportData.map((val, ind) => {
                      return (
                        <div key={ind} className="form-group row my-2">
                          <label className="col-4 col-form-label">
                            {val.title}
                          </label>
                          <div className="col-8">
                            <span className="form-control-plaintext font-weight-bolder">
                              {val.value}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  {!resolved && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <RenderInputFields
                        InputFields={InputFields}
                        errors={errors}
                        register={register}
                      />

                      <div className="row"></div>
                      <SubmitButton
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit}
                        name="Update"
                      />
                    </form>
                  )}
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOne;
