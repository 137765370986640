import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Input, RenderInputFields, SubmitButton } from "../Form/Form";

const Edit = () => {
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        reset,
    } = useForm();

    const { response, request } = useRequest();
    const { response: editRes, request: editReq } = useRequest();

    const history = useHistory();

    useEffect(() => {
        editReq("GET", `master/room-category/${id}`);
        document.title = "Edit Room Category - Kura";
    }, []);

    useEffect(() => {
        if (editRes) {
            reset({
                category: editRes?.roomCategory?.category,
            });
        }
    }, [editRes]);

    useEffect(() => {
        if (response) {
            toast.success("Category has been updated successfully.");
            history.push("/room-category");
        }
    }, [response]);

    const onSubmit = (data) => {
        const { category } = data;
        request("PUT", "master/room-category", {
            category,
            id,
        });
    };

    const InputFields = [
        [
            {
                Component: Input,
                label: "Category",
                type: "text",
                name: "category",
                registerFields: {
                    required: true,
                },
            },
        ],
    ];

    return (
        <div
            className="content  d-flex flex-column flex-column-fluid"
            id="kt_content"
        >
            <Breadcrumb
                title="Edit Room Category Types"
                links={[
                    { to: "/", name: "Dashboard" },
                    { to: "/room-category", name: "Back To Room Category" },
                ]}
            />

            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-1"></div>
                                <div className="col-xl-10">
                                    <h3 className="mb-10 font-weight-bold text-dark">
                                        Edit Room Category
                                    </h3>

                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <RenderInputFields
                                            InputFields={InputFields}
                                            errors={errors}
                                            register={register}
                                        />

                                        <div className="row"></div>

                                        <SubmitButton
                                            handleSubmit={handleSubmit}
                                            onSubmit={onSubmit}
                                            name="Submit"
                                        />
                                    </form>
                                </div>
                                <div className="col-xl-1"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Edit;
