import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Input, RenderInputFields, SubmitButton } from "../Form/Form";

const EditTypes = () => {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  const { response, request } = useRequest();
  const { response: typeEditresponse, request: typeEditRequest } = useRequest();

  const history = useHistory();

  useEffect(() => {
    typeEditRequest("GET", `master/bookstore-categories/${id}`);
    document.title = "Edit Bookstore Categories - Kura";
  }, []);

  useEffect(() => {
    if (typeEditresponse) {
      reset({
        category: typeEditresponse?.bookstoreCategory?.category,
      });
    }
  }, [typeEditresponse]);

  useEffect(() => {
    if (response) {
      toast.success("Categories has been updated successfully.");
      history.push("/bookstore-categories");
    }
  }, [response]);

  const onSubmit = (data) => {
    const { category } = data;

    request("PUT", "master/bookstore-categories", {
      category,
      id,
    });
  };

  const InputFields = [
    [
      // {
      //   Component: Input,
      //   label: "First Name",
      //   type: "text",
      //   name: "firstName",
      //   registerFields: {
      //     required: true,
      //     pattern: /^[A-Za-z ]+$/,
      //   },
      //   registerFieldsFeedback: {
      //     pattern: "Name can only contain letters.",
      //   },
      // },
      {
        Component: Input,
        label: "Categories",
        type: "text",
        name: "category",
        registerFields: {
          required: true,
        },
        registerFieldsFeedback: {
          pattern: "Categories can only contain letters.",
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Scholarship Types"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/bookstore-categories", name: "Back To Bookstore Categories" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Edit Bookstore Categories
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTypes;
