import Login from "../pages/Login/Login";
import ForgotPassword from "../components/ForgotPassword/ForgotPassword";
import ResetPassword from "../components/ResetPassword/ResetPassword";
import UserCredential from "../pages/UserCredential/UserCredential";

import Dashboard from "../pages/Dashboard/Dashboard";
import ChangePassword from "../components/ChangePassword/ChangePassword";
import Profile from "../components/Profile/Profile";

import ViewAllSettings from "../components/Settings/ViewAll";
import ViewPrefix from "../components/Settings/ViewPrefix";
import AddSetting from "../components/Settings/Add";
import EditSetting from "../components/Settings/Edit";
import ViewAllCms from "../components/Cms/ViewAll";
import ViewOneCms from "../components/Cms/NewViewOne";
import AddCms from "../components/Cms/NewAdd";
import EditCms from "../components/Cms/NewEdit";
import ViewAllEmailTemplate from "../components/EmailTemplate/ViewAll";
import AddEmailTemplate from "../components/EmailTemplate/Add";
import EditEmailTemplate from "../components/EmailTemplate/Edit";
import ViewAllEmailLogs from "../components/EmailLog/ViewAll";
import ViewAllStudents from "../components/Students/ViewAll";
import ViewAllSubAdmin from "../components/Subadmin/ViewAllSubAdmin";
import AddSubadmin from "../components/Subadmin/Add";
import AddOld from "../components/Subadmin/AddOld";
import EditSubadmin from "../components/Subadmin/EditSubadmin";

import ViewSubadminLogs from "../components/Subadmin/ViewLogs";

import AddStudent from "../components/Students/Add";
import EditStudent from "../components/Students/Edit";
import ViewStudent from "../components/Students/ViewOne";

import ViewStudentLogs from "../components/Students/ViewLogs";

import ChangePasswordStudent from "../components/Students/ChangePassword";
import ChangePasswordSubadmin from "../components/Subadmin/ChangePassword";
import ViewAllFaqs from "../components/Faq/ViewAll";
import AddFaq from "../components/Faq/Add";
import EditFaq from "../components/Faq/Edit";
import AddImage from "../components/Image/Add";
import ViewAllImage from "../components/Image/ViewAll";
import EditImage from "../components/Image/Edit";
import SeoPageAdd from "../components/SeoPage/Add";
import SeoPageViewAll from "../components/SeoPage/ViewAll";
import SeoPageEdit from "../components/SeoPage/Edit";
import ViewAllScholarship from "../components/Scholarship/ViewAllScholarship";
import AddScholarship from "../components/Scholarship/AddScholarship";
import EditScholarship from "../components/Scholarship/EditScholarship";
import ViewOneScholarship from "../components/Scholarship/ViewOne";
// import ViewOneScholarship from "../components/Report/ViewList";
import ViewAllCourseLevel from "../components/CourseLevel/ViewAll";
import CourseLevelAdd from "../components/CourseLevel/Add";
import CourseLevelEdit from "../components/CourseLevel/Edit";

import ViewAllSubscriptionPlans from "../components/SubscriptionPlan/viewAll";
import AddSubscriptionPlan from "../components/SubscriptionPlan/add";
import EditSubscriptionPlan from "../components/SubscriptionPlan/edit";
// import ViewAllEhubs from "../components/Ehubs/ViewAll";
import ViewAllEhubTypes from "../components/EhubTypes/ViewAll";
import AddEhubTypes from "../components/EhubTypes/Add";
import EditEhubTypes from "../components/EhubTypes/Edit";

import ViewAllScholarshipTypes from "../components/ScholarshipTypes/ViewAll";
import AddScholarshipTypes from "../components/ScholarshipTypes/Add";
import EditScholarshipTypes from "../components/ScholarshipTypes/Edit";
import ViewAllEhub from "../components/Ehubs/ViewAll";
import EditEhub from "../components/Ehubs/Edit";
import ViewOneEhub from "../components/Ehubs/ViewOne";

import ViewAllAccommodation from "../components/Accommodation/ViewAll";
import EditAccommodation from "../components/Accommodation/Edit";
import ViewOneVacantAccommodation from "../components/Accommodation/ViewOne";
import ViewOneAccommodationRoomMate from "../components/Accommodation/ViewOneRoom";

import ViewAllBookstoreCategory from "../components/BookstoreCategory/ViewAll";
import AddBookstoreCategory from "../components/BookstoreCategory/Add";
import EditBookstoreCategory from "../components/BookstoreCategory/Edit";

import ViewAllBookstore from "../components/Bookstore/ViewAll";
import EditBookstore from "../components/Bookstore/Edit";
import ViewOneBookstore from "../components/Bookstore/ViewOne";
import ViewReportList from "../components/Report/ViewList";

import ViewAllFreelanceTypes from "../components/FreelanceTypes/ViewAll";
import AddFreelanceTypes from "../components/FreelanceTypes/Add";
import EditFreelanceTypes from "../components/FreelanceTypes/Edit";

import ViewAllFreelanceStudentLevel from "../components/freelanceStudentLevel/ViewAll";
import AddFreelanceStudentLevel from "../components/freelanceStudentLevel/Add";
import EditFreelanceStudentLevel from "../components/freelanceStudentLevel/Edit";

import ViewAllFreelance from "../components/Freelance/ViewAll";
import EditFreelance from "../components/Freelance/Edit";
import ViewFreelanceExpert from "../components/Freelance/ViewOne";
import ViewFreelanceProject from "../components/Freelance/ViewProjectOne";

import ViewAllBookstoreConsent from "../components/BookstoreConcent/ViewAll";
import ViewBookstoreConsent from "../components/BookstoreConcent/ViewOne";
import ViewContactUs from "../components/contactus/ViewContactUs";
import ViewOneContactUs from "../components/contactus/ViewOne";
import ViewSingleContact from "../components/contactus/ViewSingleContact";

import ViewAllAdminRoles from "../components/AdminRole/ViewAll";
import AddAdminRole from "../components/AdminRole/Add";
import EditAdminRole from "../components/AdminRole/Edit";

import ViewAllExpertCategory from "../components/ExpertCategory/ViewAll";
import AddExpertCategory from "../components/ExpertCategory/Add";
import EditExpertCategory from "../components/ExpertCategory/Edit";

import ViewAllReligions from "../components/Religions/ViewAll";
import AddReligion from "../components/Religions/Add";
import EditReligion from "../components/Religions/Edit";

import ViewAllAgeRange from "../components/AgeRange/ViewAll";
import AddAgeRange from "../components/AgeRange/Add";
import EditAgeRange from "../components/AgeRange/Edit";

import ViewAllReports from "../components/Report/ViewAll";
import ViewOneReport from "../components/Report/ViewOne";

import ViewAllReportReason from "../components/ReportReason/ViewAll";
import AddReportReason from "../components/ReportReason/Add";
import EditReportReason from "../components/ReportReason/Edit";

import ViewAllRoomCategory from "../components/RoomCategory/ViewAll";
import AddRoomCategory from "../components/RoomCategory/Add";
import EditRoomCategory from "../components/RoomCategory/Edit";

import ViewAllNotification from "../components/Notification/ViewAll";
import ViewOneNotification from "../components/Notification/ViewOne";
import SendNotification from "../components/Notification/Send";

import ViewAllNotificationTemplate from "../components/notificationTemplate/ViewAll";
import AddNotificationTemplate from "../components/notificationTemplate/Add";
import EditNotificationTemplate from "../components/notificationTemplate/Edit";

import ViewAllStudyLevel from "../components/StudyLevel/ViewAll";
import AddStudyLevel from "../components/StudyLevel/Add";
import EditStudyLevel from "../components/StudyLevel/Edit";

import ViewAllScholarshipTag from "../components/ScholarshipTag/ViewAll";
import AddScholarshipTag from "../components/ScholarshipTag/Add";
import EditScholarshipTag from "../components/ScholarshipTag/Edit";

import ViewAllConsentDocumentTypes from "../components/ConsentDocumentTypes/ViewAll";
import AddConsentDocumentTypes from "../components/ConsentDocumentTypes/Add";
import EditConsentDocumentTypes from "../components/ConsentDocumentTypes/Edit";

import ViewAllConsentDesignationTypes from "../components/ConsentDesignationTypes/ViewAll";
import AddConsentDesignationTypes from "../components/ConsentDesignationTypes/Add";
import EditConsentDesignationTypes from "../components/ConsentDesignationTypes/Edit";

import ViewAllItemCondition from "../components/ItemCondition/ViewAll";
import AddItemCondition from "../components/ItemCondition/Add";
import EditItemCondition from "../components/ItemCondition/Edit";

import ViewAllSchool from "../components/School/ViewAll";
import AddSchool from "../components/School/Add";
import EditSchool from "../components/School/Edit";

import ViewAllCity from "../components/City/ViewAll";
import AddCity from "../components/City/Add";
import EditCity from "../components/City/Edit";

import ViewAllContactUsSubject from "../components/ContactUsSubject/ViewAll";
import AddContactUsSubject from "../components/ContactUsSubject/Add";
import EditContactUsSubject from "../components/ContactUsSubject/Edit";

import MarketingSiteHomePage from "../components/MarketingSite/HomePage";

import ViewAllSubscription from "../components/Subscription/ViewAll";
import ViewOneSubscription from "../components/Subscription/ViewOne";

import ViewAllFeedback from "../components/Feedback/ViewAll";
import ViewOneFeedback from "../components/Feedback/ViewOne";

import ViewAllDegree from "../components/Degree/ViewAll";
import AddDegree from "../components/Degree/Add";
import EditDegree from "../components/Degree/Edit";

import ViewAllTransaction from "../components/Transaction/ViewAll";

export const privateRoutes = [
  { path: "/", component: Dashboard, access: ["o1"] },
  { path: "/setting", component: ViewAllSettings, access: ["k"] },
  {
    path: "/setting/prefix/:prefix",
    component: ViewPrefix,
    access: ["k1", "k2", "k3"],
  },
  { path: "/setting/add", component: AddSetting, access: [] },
  { path: "/setting/edit/:id", component: EditSetting, access: [] },
  { path: "/cms", component: ViewAllCms, access: ["e1"] },
  { path: "/cms/view/:id", component: ViewOneCms, access: ["e2"] },
  { path: "/cms/add", component: AddCms, access: [] },
  { path: "/cms/edit/:id", component: EditCms, access: ["e3"] },
  { path: "/email-template", component: ViewAllEmailTemplate, access: ["g1"] },
  { path: "/email-template/add", component: AddEmailTemplate, access: [] },
  {
    path: "/email-template/edit/:id",
    component: EditEmailTemplate,
    access: ["g2"],
  },
  { path: "/email-logs", component: ViewAllEmailLogs, access: ["h1"] },
  {
    path: "/change-password",
    component: ChangePassword,
    access: ["changePassword"],
  },
  { path: "/my-profile", component: Profile, access: ["myProfile"] },
  { path: "/users", component: ViewAllStudents, access: ["a1"] },
  { path: "/users/add", component: AddStudent, access: ["a2"] },
  { path: "/users/edit/:id", component: EditStudent, access: ["a5"] },
  { path: "/users/view/:id", component: ViewStudent, access: ["a4"] },
  {
    path: "/users/change-password/:id",
    component: ChangePasswordStudent,
    access: ["a7"],
  },
  {
    path: "/users/logs/:id",
    component: ViewStudentLogs,
    access: ["a11"],
  },
  { path: "/ehub", component: ViewAllEhub, access: ["l1"] },
  // { path: "/ehub/edit/:id", component: EditEhub, access: ["l5"] },
  { path: "/ehub/view/:id", component: ViewOneEhub, access: ["l2"] },
  { path: "/ehub-types", component: ViewAllEhubTypes, access: ["m1"] },
  { path: "/ehub-types/add", component: AddEhubTypes, access: ["m4"] },
  { path: "/ehub-types/edit/:id", component: EditEhubTypes, access: ["m5"] },
  { path: "/scholarship", component: ViewAllScholarship, access: ["b1"] },
  { path: "/scholarship/add", component: AddScholarship, access: ["b4"] },
  { path: "/scholarship/edit/:id", component: EditScholarship, access: ["b5"] },
  {
    path: "/scholarship/view/:id",
    component: ViewOneScholarship,
    access: ["b2"],
  },
  {
    path: "/scholarship-types",
    component: ViewAllScholarshipTypes,
    access: ["n1"],
  },
  {
    path: "/scholarship-types/add",
    component: AddScholarshipTypes,
    access: ["n4"],
  },
  {
    path: "/scholarship-types/edit/:id",
    component: EditScholarshipTypes,
    access: ["n5"],
  },

  { path: "/sub-admin", component: ViewAllSubAdmin, access: ["c1"] },
  { path: "/sub-admin/add", component: AddSubadmin, access: ["c4"] },
  // { path: "/sub-admin/add-old", component: AddOld, access: ["c4"] },
  { path: "/sub-admin/edit/:id", component: EditSubadmin, access: ["c5"] },
  {
    path: "/sub-admin/change-password/:id",
    component: ChangePasswordSubadmin,
    access: ["c7"],
  },
  {
    path: "/sub-admin/logs/:id",
    component: ViewSubadminLogs,
    access: ["c11"],
  },

  { path: "/faq", component: ViewAllFaqs, access: ["i1"] },
  { path: "/faq/add", component: AddFaq, access: ["i4"] },
  { path: "/faq/edit/:id", component: EditFaq, access: ["i5"] },
  { path: "/course-level", component: ViewAllCourseLevel, access: ["p1"] },
  { path: "/course-level/add", component: CourseLevelAdd, access: ["p4"] },
  {
    path: "/course-level/edit/:id",
    component: CourseLevelEdit,
    access: ["p5"],
  },

  { path: "/system-images/add", component: AddImage, access: [] },
  { path: "/system-images", component: ViewAllImage, access: ["j1"] },
  { path: "/system-images/edit/:id", component: EditImage, access: ["j2"] },

  { path: "/seo-pages", component: SeoPageViewAll, access: ["f1"] },
  { path: "/seo-page/add", component: SeoPageAdd, access: [] },
  { path: "/seo-page/edit/:id", component: SeoPageEdit, access: ["f2"] },

  {
    path: "/subscription-plan",
    component: ViewAllSubscriptionPlans,
    access: ["d1"],
  },
  {
    path: "/subscription-plan/add",
    component: AddSubscriptionPlan,
    access: ["d4"],
  },
  {
    path: "/subscription-plan/edit/:id",
    component: EditSubscriptionPlan,
    access: ["d5"],
  },

  { path: "/accommodation", component: ViewAllAccommodation, access: ["t"] },
  // {
  //   path: "/accommodation/edit/:id",
  //   component: EditAccommodation,
  //   access: ["t5"],
  // },
  {
    path: "/accommodation/vacant-room/view/:id",
    component: ViewOneVacantAccommodation,
    access: ["t7"],
  },
  {
    path: "/accommodation/room-mate/view/:id",
    component: ViewOneAccommodationRoomMate,
    access: ["t8"],
  },

  {
    path: "/bookstore-categories",
    component: ViewAllBookstoreCategory,
    access: ["q"],
  },
  {
    path: "/bookstore-categories/add",
    component: AddBookstoreCategory,
    access: ["q4"],
  },
  {
    path: "/bookstore-categories/edit/:id",
    component: EditBookstoreCategory,
    access: ["q5"],
  },

  {
    path: "/bookstore",
    component: ViewAllBookstore,
    access: ["u"],
  },
  {
    path: "/bookstore/view/:id",
    component: ViewOneBookstore,
    access: ["u1"],
  },
  // {
  //   path: "/bookstore/edit/:id",
  //   component: EditBookstore,
  //   access: ["u5"],
  // },
  {
    path: "/report/viewlist/:type/:id",
    component: ViewReportList,
    access: ["u2"],
  },
  {
    path: "/freelance-types",
    component: ViewAllFreelanceTypes,
    access: ["r"],
  },
  {
    path: "/freelance-types/add",
    component: AddFreelanceTypes,
    access: ["r4"],
  },
  {
    path: "/freelance-types/edit/:id",
    component: EditFreelanceTypes,
    access: ["r5"],
  },

  {
    path: "/freelance-student-level",
    component: ViewAllFreelanceStudentLevel,
    access: ["s"],
  },
  {
    path: "/freelance-student-level/add",
    component: AddFreelanceStudentLevel,
    access: ["s4"],
  },
  {
    path: "/freelance-student-level/edit/:id",
    component: EditFreelanceStudentLevel,
    access: ["s5"],
  },

  {
    path: "/freelance",
    component: ViewAllFreelance,
    access: ["v"],
  },
  // {
  //   path: "/freelance/edit/:id",
  //   component: EditFreelance,
  //   access: ["v5"],
  // },
  {
    path: "/freelance/expert/view/:id",
    component: ViewFreelanceExpert,
    access: ["v7"],
  },
  {
    path: "/freelance/project/view/:id",
    component: ViewFreelanceProject,
    access: ["v8"],
  },
  {
    path: "/bookstore-consent",
    component: ViewAllBookstoreConsent,
    access: ["w1"],
  },
  {
    path: "/bookstore-consent/view/:id",
    component: ViewBookstoreConsent,
    access: ["w2"],
  },
  {
    path: "/contact-us",
    component: ViewContactUs,
    access: ["x1"],
  },
  {
    path: "/contact-us/view/:id",
    component: ViewOneContactUs,
    access: ["x2"],
  },

  {
    path: "/admin-roles",
    component: ViewAllAdminRoles,
    access: ["y"],
  },
  {
    path: "/admin-roles/add",
    component: AddAdminRole,
    access: ["y4"],
  },
  {
    path: "/admin-roles/edit/:id",
    component: EditAdminRole,
    access: ["y5"],
  },

  {
    path: "/expert-categories",
    component: ViewAllExpertCategory,
    access: ["z"],
  },
  {
    path: "/expert-categories/add",
    component: AddExpertCategory,
    access: ["z4"],
  },
  {
    path: "/expert-categories/edit/:id",
    component: EditExpertCategory,
    access: ["z5"],
  },

  {
    path: "/religions",
    component: ViewAllReligions,
    access: ["A"],
  },
  {
    path: "/religion/add",
    component: AddReligion,
    access: ["A4"],
  },
  {
    path: "/religion/edit/:id",
    component: EditReligion,
    access: ["A5"],
  },
  {
    path: "/age-range",
    component: ViewAllAgeRange,
    access: ["B"],
  },
  {
    path: "/age-range/add",
    component: AddAgeRange,
    access: ["B4"],
  },
  {
    path: "/age-range/edit/:id",
    component: EditAgeRange,
    access: ["B5"],
  },

  // report ----
  {
    path: "/report",
    component: ViewAllReports,
    access: ["C1"],
  },
  {
    path: "/report/view/:id",
    component: ViewOneReport,
    access: ["C2"],
  },

  // report reason ---
  {
    path: "/report-reason",
    component: ViewAllReportReason,
    access: ["D"],
  },
  {
    path: "/report-reason/add",
    component: AddReportReason,
    access: ["D4"],
  },
  {
    path: "/report-reason/edit/:id",
    component: EditReportReason,
    access: ["D5"],
  },

  // room categories ---
  {
    path: "/room-category",
    component: ViewAllRoomCategory,
    access: ["E"],
  },
  {
    path: "/room-category/add",
    component: AddRoomCategory,
    access: ["E4"],
  },
  {
    path: "/room-category/edit/:id",
    component: EditRoomCategory,
    access: ["E5"],
  },

  // Notification ---
  {
    path: "/notifications",
    component: ViewAllNotification,
    access: ["F"],
  },
  {
    path: "/notifications/view/:id",
    component: ViewOneNotification,
    access: ["F2"],
  },
  {
    path: "/notifications/send",
    component: SendNotification,
    access: ["F3"],
  },

  // notification template ---
  {
    path: "/notification-template",
    component: ViewAllNotificationTemplate,
    access: ["G"],
  },
  {
    path: "/notification-template/add",
    component: AddNotificationTemplate,
    access: ["G4"],
  },
  {
    path: "/notification-template/edit/:id",
    component: EditNotificationTemplate,
    access: ["G5"],
  },

  // study level ---
  {
    path: "/study-level",
    component: ViewAllStudyLevel,
    access: ["H"],
  },
  {
    path: "/study-level/add",
    component: AddStudyLevel,
    access: ["H4"],
  },
  {
    path: "/study-level/edit/:id",
    component: EditStudyLevel,
    access: ["H5"],
  },

  // scholarship tag ---
  {
    path: "/scholarship-tag",
    component: ViewAllScholarshipTag,
    access: ["I"],
  },
  {
    path: "/scholarship-tag/add",
    component: AddScholarshipTag,
    access: ["I4"],
  },
  {
    path: "/scholarship-tag/edit/:id",
    component: EditScholarshipTag,
    access: ["I5"],
  },

  // Consent document Types

  {
    path: "/consent-document-types",
    component: ViewAllConsentDocumentTypes,
    access: ["J"],
  },
  {
    path: "/consent-document-types/add",
    component: AddConsentDocumentTypes,
    access: ["J4"],
  },
  {
    path: "/consent-document-types/edit/:id",
    component: EditConsentDocumentTypes,
    access: ["J5"],
  },

  // Consent document Types

  {
    path: "/consent-designation-types",
    component: ViewAllConsentDesignationTypes,
    access: ["K"],
  },
  {
    path: "/consent-designation-types/add",
    component: AddConsentDesignationTypes,
    access: ["K4"],
  },
  {
    path: "/consent-designation-types/edit/:id",
    component: EditConsentDesignationTypes,
    access: ["K5"],
  },

  // Item conditions

  {
    path: "/item-condition",
    component: ViewAllItemCondition,
    access: ["L"],
  },
  {
    path: "/item-condition/add",
    component: AddItemCondition,
    access: ["L4"],
  },
  {
    path: "/item-condition/edit/:id",
    component: EditItemCondition,
    access: ["L5"],
  },

  // School

  {
    path: "/school",
    component: ViewAllSchool,
    access: ["M"],
  },
  {
    path: "/school/add",
    component: AddSchool,
    access: ["M4"],
  },
  {
    path: "/school/edit/:id",
    component: EditSchool,
    access: ["M5"],
  },

  // City

  {
    path: "/city",
    component: ViewAllCity,
    access: ["N"],
  },
  {
    path: "/city/add",
    component: AddCity,
    access: ["N4"],
  },
  {
    path: "/city/edit/:id",
    component: EditCity,
    access: ["N5"],
  },

  // MArketing site
  {
    path: "/marketing-site/home-page",
    component: MarketingSiteHomePage,
    access: ["O"],
  },

  // Contact us subject

  {
    path: "/contact-us-subject",
    component: ViewAllContactUsSubject,
    access: ["P"],
  },
  {
    path: "/contact-us-subject/add",
    component: AddContactUsSubject,
    access: ["P4"],
  },
  {
    path: "/contact-us-subject/edit/:id",
    component: EditContactUsSubject,
    access: ["P5"],
  },

  // Subscription

  {
    path: "/subscription",
    component: ViewAllSubscription,
    access: ["Q1"],
  },
  {
    path: "/subscription/view/:id",
    component: ViewOneSubscription,
    access: ["Q2"],
  },

  // Feedback

  {
    path: "/feedback",
    component: ViewAllFeedback,
    access: ["R1"],
  },
  {
    path: "/feedback/view/:id",
    component: ViewOneFeedback,
    access: ["R2"],
  },

  // Degree data ---
  {
    path: "/degree",
    component: ViewAllDegree,
    access: ["S"],
  },
  {
    path: "/degree/add",
    component: AddDegree,
    access: ["S4"],
  },
  {
    path: "/degree/edit/:id",
    component: EditDegree,
    access: ["S5"],
  },
  { path: "/transaction", component: ViewAllTransaction, access: ["T1"] },
];

export const notPrivateRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/reset-password/:token", component: ResetPassword },
  { path: "/user-credential", component: UserCredential },
];
