import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Input, RenderInputFields, SubmitButton } from "../Form/Form";
import {capitalizeFirstLetter} from "../../util/fn"

const Edit = (props) => {
    const { id: contactId } = props.match.params;
    const history = useHistory();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();

    const [contactUsData, setContactUsData] = useState([])
    const [replied, setReplied] = useState(false)

    const { response: responseContactUs, request: requestContactUs } =
        useRequest();

    const { response, request } = useRequest();

    useEffect(() => {
        if (contactId) {
            requestContactUs("GET", `contact-us/${contactId}`);
            document.title = "Contact Us - Kura";
        }
    }, [contactId]);

    useEffect(() => {
        if (responseContactUs) {
            const obj = responseContactUs.contactUs;
            let arr = []
            for (let key in obj) {
                if (["name", "email", "message", "subject"].includes(key)) {
                    arr.push({ title: capitalizeFirstLetter(key), value: obj[key] })
                }
            }

            if (obj.isReplied && obj.reply) {
                arr.push({ title: 'Reply', value: obj.reply })
                setReplied(true)
            }

            setContactUsData(arr)
            setValue('reply', obj.reply)
        }
    }, [responseContactUs]);

    useEffect(() => {
        if (response) {
            toast.success("Contact us has been replied successfully.");
            history.push("/contact-us");
        }
    }, [response]);

    const onSubmit = (data) => {
        const { reply } = data;
        request("POST", "contact-us/reply", {
            id: contactId,
            reply
        });
    };

    const InputFields = [
        [

            {
                Component: Input,
                label: "Reply",
                type: "text",
                name: "reply",
                registerFields: {
                    required: false,
                }
            },
        ],
    ];

    return (
        <div
            className="content  d-flex flex-column flex-column-fluid"
            id="kt_content"
        >
            <Breadcrumb
                title="View contact us"
                links={[
                    { to: "/", name: "Dashboard" },
                    {
                        to: { pathname: "/contact-us" /*backPageNum: page */ },
                        name: "Back To Contact Us",
                    },
                ]}
            />

            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-1"></div>
                                <div className="col-xl-10">

                                    {
                                        contactUsData.length > 0 &&
                                        contactUsData.map((val, ind) => {
                                            return (
                                                <div key={ind} className="form-group row my-2">
                                                    <label className="col-4 col-form-label">
                                                        {val.title}
                                                    </label>
                                                    <div className="col-8">
                                                        <span className="form-control-plaintext font-weight-bolder">
                                                            {val.value}
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        !replied &&
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <RenderInputFields
                                                InputFields={InputFields}
                                                errors={errors}
                                                register={register}
                                            />

                                            <div className="row"></div>
                                            <SubmitButton
                                                handleSubmit={handleSubmit}
                                                onSubmit={onSubmit}
                                                name="Update"
                                            />
                                        </form>
                                    }


                                </div>
                                <div className="col-xl-1"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Edit;
