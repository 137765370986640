import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  RenderInputFields,
  SubmitButton,
  SelectInput,
  Textarea,
} from "../Form/Form";

const AddScholarship = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
  } = useForm();

  const [scholarshipTypes, setScholarshipTypes] = useState([]);
  const [scholarshipTags, setScholarshipTags] = useState([]);

  const { response, request } = useRequest();
  const { response: typesRes, request: typesReq } = useRequest();
  const { response: tagRes, request: tagReq } = useRequest();

  const history = useHistory();

  useEffect(() => {
    document.title = "Add Scholarship - Kura";
    typesReq("GET", "admin/scholarship-types");
    tagReq("GET", "admin/scholarship-tags");
  }, []);

  useEffect(() => {
    if (typesRes && typesRes?.status) {
      setScholarshipTypes(typesRes.types);
    }
  }, [typesRes]);

  useEffect(() => {
    if (tagRes && tagRes?.status) {
      setScholarshipTags(tagRes.tags);
    }
  }, [tagRes]);

  useEffect(() => {
    if (response) {
      toast.success("Scholarship has been added successfully.");
      history.push("/scholarship");
    }
  }, [response]);

  const onSubmit = (data) => {
    let {
      type,
      name,
      title,
      eligibility,
      deadline,
      externalLink,
      photo,
      description,
      tags,
    } = data;
    
    if (tags[0].value === "all") {
      tags = scholarshipTags.map((v) => v.tag);
    } else {
      tags = tags.map((v)=>v.label)
    }    

    let formData = new FormData();
    formData.append("type", type);
    formData.append("name", name);
    formData.append("title", title);
    formData.append("eligibility", eligibility);
    formData.append("deadline", deadline);
    formData.append("externalLink", externalLink);
    formData.append("tags", JSON.stringify(tags));
    if (photo && photo[0]) {
      formData.append("photo", photo[0]);
    }
    formData.append("description", description);
    request("POST", "scholarship", formData);
  };

  const InputFields = [
    [
      {
        Component: SelectInput,
        label: "Type",
        name: "type",
        registerFields: {
          required: true,
        },
        children: (
          <>
            <option value="">Select</option>
            {scholarshipTypes.length > 0 &&
              scholarshipTypes.map((type, i) => {
                return (
                  <option key={i} value={type.type}>
                    {type.type}
                  </option>
                );
              })}
          </>
        ),
      },
      {
        Component: Input,
        label: "Name",
        type: "text",
        name: "name",
        registerFields: {
          required: true,
          // pattern: /^[A-Za-z ]+$/,
        },
        // registerFieldsFeedback: {
        //   pattern: "Name can only contain letters.",
        // },
      },
      // {
      //   Component: Input,
      //   label: "Scholarship Coverage/Benefits/Tags",
      //   type: "text",
      //   name: "title",
      //   registerFields: {
      //     required: true,
      //     pattern: /^[A-Za-z ]+$/,
      //   },
      //   registerFieldsFeedback: {
      //     pattern: "Title can only contain letters.",
      //   },
      // },
      {
        Component: Textarea,
        label: "Eligibility",
        type: "text",
        name: "eligibility",
        registerFields: {
          required: true,
          // pattern:
          // /^http:\/\/|(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        },
        // registerFieldsFeedback: {
        //   pattern: "The external link pattern wrong.",
        // },
      },
      {
        Component: Input,
        label: "Deadline",
        type: "date",
        name: "deadline",
        registerFields: {
          required: true,
        },
        isDate: true,
      },
      {
        Component: Input,
        label: "External Link",
        type: "text",
        name: "externalLink",
        registerFields: {
          required: true,
          pattern:
            /^http:\/\/|(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        },
        registerFieldsFeedback: {
          pattern: "Invalid link.",
        },
      },
      {
        Component: Input,
        label: "Photo",
        type: "file",
        name: "photo",
        registerFields: {
          //   required: true,
        },
      },
      {
        Component: Textarea,
        label: "Description",
        type: "text",
        name: "description",
        registerFields: {
          required: true,
        },
      },
    ],

    // [
    //   {
    //     Component: Input,
    //     label: "Password",
    //     type: "password",
    //     name: "password",
    //     registerFields: {
    //       required: true,
    //       pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
    //     },
    //     registerFieldsFeedback: {
    //       pattern:
    //         "Password must be of 8 characters long with atleast one Uppercase, one Lowercase and one Number",
    //     },
    //   },
    //   {
    //     Component: Input,
    //     label: "Confirm Password",
    //     type: "password",
    //     name: "confirmpassword",
    //     registerFields: {
    //       required: true,
    //       pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
    //     },
    //     registerFieldsFeedback: {
    //       pattern:
    //         "Confirm password must be of 8 characters long with atleast one Uppercase, one Lowercase and one Number",
    //     },
    //     otherRegisterFields: {
    //       manual: true,
    //       feedback: "Both Passwords do not match.",
    //     },
    //   },
    // ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add Scholarship"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/scholarship", name: "Back To Scholarship" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Add New Scholarship
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />
                    <div className="col-xl-6">
                      <div className="form-group">
                        <label>
                          Scholarship Coverage/Benefits/Tags
                          <span className="text-danger">*</span>
                        </label>
                        
                        <Controller
                          control={control}
                          name="tags"
                          {...register("tags", {
                            required: true,
                          })}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              isMulti
                              onChange={(val) => {
                                const isSelectAllAvailable = val.find(
                                  (v) => v.value == "all"
                                );

                                if (isSelectAllAvailable) {
                                  onChange([
                                    { value: "all", label: "Select All" },
                                  ]);
                                } else {
                                  return onChange(val);
                                }
                              }}
                              defaultValue={[]}
                              value={value}
                              // inputRef={ref}

                              options={[
                                { value: "all", label: "Select All" },
                                ...scholarshipTags.map((val) => {
                                  return {
                                    value: val._id,
                                    label: val.tag,
                                  };
                                }),
                              ]}
                              className={`basic-multi-select ${
                                errors.toUser ? "is-invalid" : ""
                              }`}
                              classNamePrefix="select"
                              menuPosition="fixed"
                            />
                          )}
                        />

                        {errors?.tags?.type === "required" && (
                          <div
                            style={{ display: "block" }}
                            className="invalid-feedback"
                          >
                            Please select tags.
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddScholarship;
