import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { ExportButton, SearchInput, SearchSubmitButton } from "../Form/Form";
import {
  addOneToDate,
  downloadExcel,
  downloadPDF,
  intersect,
} from "../../util/fn";
import { useHistory } from "react-router-dom";
import moment from "moment";

const OBJ_TABLE = {
  "Name On Consent": "name",
  user: "user_name",
  "user id": "user_uniqueId",
  Profession: "designation",
  "created At": "createdAt",
  status: "status",
};

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  isActive = "",
  category = ""
) => {
  if (sortBy.length > 0) {
    if (sortBy == "registered on") {
      sortBy = "createdAt";
    }
  } else {
    sortBy = "createdAt";
  }
  order = order.length > 0 ? order : "desc";

  return `bookstore-consent?page=${page}&per_page=${per_page}&sortBy=${sortBy}&order=${order}&status=${isActive}`;
};

const ViewAllTypes = () => {
  const [bookstoreCategory, setBookstoreCategory] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "registered on",
    order: "desc",
  });
  const history = useHistory();

  const MySwal = withReactContent(Swal);

  const { records_per_page, date_format } = useSelector(
    (state) => state.setting
  );
  const { permissions, roleId } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    clearErrors,
    setError,
  } = useForm();

  const { request, response } = useRequest();
  const { request: requestChangeStatus, response: responseChangeStatus } =
    useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = "Bookstore Consent - Kura";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      setBookstoreCategory(response.bookstoreConsents);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  useEffect(() => {
    if (responseChangeStatus) {
      const { id, newStatus } = responseChangeStatus;

      console.log(newStatus);

      const oldBookstoreCategory = [...bookstoreCategory];
      const indexToChange = oldBookstoreCategory.findIndex(
        (scholar) => scholar._id == id
      );
      oldBookstoreCategory[indexToChange].status = newStatus;

      setBookstoreCategory(oldBookstoreCategory);
    }
  }, [responseChangeStatus]);

  useEffect(() => {
    if (responseDelete) {
      const { id } = responseDelete;
      let newBookstoreCategory = [...bookstoreCategory];
      newBookstoreCategory = newBookstoreCategory.filter((ty) => ty._id != id);
      setBookstoreCategory(newBookstoreCategory);
      toast.success("Bookstore category has been deleted successfully.");
    }
  }, [responseDelete]);

  const fetchMoreData = ({ selected }) => {
    setBookstoreCategory([]);
    const { status } = getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        status
      )
    );
  };

  const onSearchHandler = (data) => {
    let { status } = data;

    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        status
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("isActive");
    resetField("dateFrom");
    resetField("dateTo");
    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const { status } = getValues();
    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        status
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    const { status } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(page, perPage, sortBy, newOrder, status)
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request("GET", searchQueryHandler(page, perPage, sortBy, "desc", status));
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  const approve = (id) => {
    const ehub = bookstoreCategory.find((hub) => hub._id == id);
    const status = "approved";

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to approve this consent?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestChangeStatus("PUT", "bookstore-consent/approve", {
          id,
          status,
        });
      } else if (result.isDismissed) {
      }
    });
  };

  const reject = (id) => {
    const ehub = bookstoreCategory.find((hub) => hub._id == id);
    const status = "rejected";

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to reject this consent?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestChangeStatus("PUT", "bookstore-consent/approve", {
          id,
          status,
        });
      } else if (result.isDismissed) {
      }
    });
  };

  const changeApprovedHandler = (id) => {
    // const scholars = accommodation.find((scholar) => scholar._id == id);
    // const status = scholars.isApproved == "true" ? "false" : "true";

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Approve",
      denyButtonText: `Not - Approve`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        requestChangeStatus("PUT", "bookstore-consent/approve", {
          id,
          status: "approved",
        });
      } else if (result.isDenied) {
        requestChangeStatus("PUT", "bookstore-consent/approve", {
          id,
          status: "rejected",
        });
      }
    });

    // MySwal.fire({
    //   title: "Are you sure?",
    //   text: "Want to change this status?",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonText: "Yes, change it",
    //   cancelButtonText: "No, cancel",
    //   reverseButtons: true,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     requestChangeStatus("PUT", "accommodation/status", {
    //       id,
    //       status,
    //     });
    //   } else if (result.isDismissed) {
    //   }
    // });
  };

  const InputFields = [
    {
      label: "Status",
      name: "status",
      required: false,
      isSelectInput: true,
      children: (
        <>
          <option value="">Select</option>
          <option value="pending">Pending</option>
          <option value="approved">Approved</option>
          <option value="rejected">Rejected</option>
        </>
      ),
    },
  ];

  const openCreatorHandler = (id, data) => {
    history.push(`/users/view/${data.userId}`);
  };

  const getDownloadData = () => {
    let data = JSON.parse(JSON.stringify(bookstoreCategory));

    data = data.map((item) => {
      return {
        "Name On Consent": item.name,
        User: item.user_name,
        "User Id": item.user_uniqueId,
        Profession: item.designation,
        "Created At": moment(item.createdAt).format(date_format),
        // Status: item.isActive ? "Active" : "Inactive",
        Status: item.status.charAt(0).toUpperCase() + item.status.slice(1),
      };
    });

    return data;
  };

  const downloadExcelHandler = () => {
    const data = getDownloadData();

    downloadExcel(data, "Bookstore Consent");
  };

  const generatePdfDocument = async () => {
    const data = getDownloadData();

    const headings = data.length > 0 ? Object.keys(data[0]) : [];

    await downloadPDF(headings.length, headings, data, "Bookstore Consent");
  };

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Bookstore Consent"
        links={[{ to: "/", name: "Dashboard" }]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <ExportButton
                      downloadExcelHandler={downloadExcelHandler}
                      downloadPdfHandler={generatePdfDocument}
                    />

                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>

                    {/* {(roleId === 1 ||
                      (roleId === 2 &&
                        permissions &&
                        intersect(permissions, ["q4"]))) && (
                      <Link
                        to="/bookstore-consent/add"
                        className="btn btn-primary"
                      >
                        Add New Categories
                      </Link>
                    )} */}
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={bookstoreCategory}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      links={[
                        {
                          isLink: false,
                          name: "Creator",
                          click: openCreatorHandler,
                          title: "View Creator",
                          access: ["w5"],
                        },
                        {
                          isLink: false,
                          name: "Approved",
                          click: changeApprovedHandler,
                          title: "Click To Approve/Reject",
                          access: ["w4"],
                        },
                        {
                          isLink: true,
                          to: `/bookstore-consent/view`,
                          name: "View",
                          extraData: true,
                          access: ["w2"],
                        },
                      ]}
                      onlyDate={{
                        createdAt: "date",
                        startDate: "dateTime",
                        endDate: "dateTime",
                        deadline: "dateTime",
                      }}
                      renderAs={{
                        status: (val) =>
                          val.charAt(0).toUpperCase() + val.slice(1),
                      }}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={bookstoreCategory.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAllTypes;
