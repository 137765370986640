import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Moment from "react-moment";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Input, RenderInputFields, SubmitButton } from "../Form/Form";
import { useSelector } from "react-redux";

const ViewOne = (props) => {
  const { id: subscriptionId } = props.match.params;
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const { date_format, date_time_format } = useSelector(
    (state) => state.setting
  );

  const [subscriptionData, setSubscriptionData] = useState([]);
  const [replied, setReplied] = useState(false);

  const { response: responseSub, request: requestSub } = useRequest();

  const { response, request } = useRequest();

  useEffect(() => {
    if (subscriptionId) {
      requestSub("GET", `subscription/${subscriptionId}`);
      document.title = "Subscription - Kura";
    }
  }, [subscriptionId]);

  useEffect(() => {
    if (responseSub) {
      const obj = responseSub.subscription;
      setSubscriptionData([
        { title: "User Name", value: obj?.user_name ?? "-" },
        {
          title: "User ID",
          value: obj?.user_uniqueId ? (
            <>
              <Link to={`/users/view/${obj.userId}`}>{obj?.user_uniqueId}</Link>
            </>
          ) : (
            "-"
          ),
        },
        { title: "Plan Name", value: obj?.plan_name ?? "-" },
        { title: "Price", value: obj?.price ?? "-" },
        {
          title: "Subscribed On",
          value: <Moment format={date_format}>{obj?.subscribedOn}</Moment>,
        },
        {
          title: "Expire On",
          value: <Moment format={date_format}>{obj?.expireOn}</Moment>,
        },
      ]);
    }
  }, [responseSub]);

  useEffect(() => {
    if (response) {
      toast.success("Contact us has been replied successfully.");
      history.push("/subscription");
    }
  }, [response]);

  const onSubmit = (data) => {
    const { reply } = data;
    request("POST", "subscription/reply", {
      id: subscriptionId,
      reply,
    });
  };

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="View Subscription"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/subscription" /*backPageNum: page */ },
            name: "Back To Subscriptions",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  {subscriptionData.length > 0 &&
                    subscriptionData.map((val, ind) => {
                      return (
                        <div key={ind} className="form-group row my-2">
                          <label className="col-4 col-form-label">
                            {val.title}
                          </label>
                          <div className="col-8">
                            <span className="form-control-plaintext font-weight-bolder">
                              {val.value}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOne;
