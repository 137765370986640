import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  RenderInputFields,
  SubmitButton,
  OtherInput,
  Textarea,
} from "../Form/Form";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    // resetField,
    control,
    setError,
    setValue,
    getValues,
    watch,
  } = useForm();

  const {
    register: registerStudents,
    handleSubmit: handleSubmitStudents,
    formState: { errors: errorsStudents },
    // resetField,
    control: controlStudents,
    setError: setErrorStudents,
    setValue: setValueStudents,
    getValues: getValuesStudents,
  } = useForm();

  const [users, setUsers] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const [toUser, setToUser] = useState([]);

  const { response: studentListRes, request: userListReq } = useRequest();
  const { response: responseNotification, request: requestNotification } =
    useRequest();

  const history = useHistory();

  const { toUser: toUserFormHook } = watch();

  useEffect(() => {
    document.title = "Send Notification - KURA";
    userListReq("GET", "admin/users-for-notification");
  }, []);

  useEffect(() => {
    if (studentListRes) {
      setUsers(studentListRes.users);
    }
  }, [studentListRes]);

  useEffect(() => {
    if (responseNotification) {
      toast.success("Notifications sent successfully");
      history.push("/notifications");
    }
  }, [responseNotification]);

  const onSubmit = (data) => {
    if (data.toUser[0].value === "all") {
      data.toUser = [
        ...users.map((val) => {
          return { value: val._id, label: val.name || val.userId };
        }),
      ];
    }
    // console.log(data.toUser)

    requestNotification("POST", "notification-log", data);
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "Subject",
        type: "text",
        name: "title",
        registerFields: {
          required: true,
        },
      },
      {
        Component: Textarea,
        label: "Body",
        type: "text",
        name: "body",
        registerFields: {
          required: true,
        },
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Send Notification"
        links={[{ to: "/", name: "Dashboard" }]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Send Notification
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <label>
                            To User
                            <span className="text-danger">*</span>
                          </label>
                          <Controller
                            control={control}
                            name="toUser"
                            {...register("toUser", {
                              required: true,
                            })}
                            render={({ field: { onChange, value, ref } }) => (
                              <Select
                                isMulti
                                onChange={(val) => {
                                  const isSelectAllAvailable = val.find(
                                    (v) => v.value == "all"
                                  );

                                  if (isSelectAllAvailable) {
                                    onChange([
                                      { value: "all", label: "Select All" },
                                    ]);
                                  } else {
                                    return onChange(val);
                                  }
                                }}
                                defaultValue={toUser}
                                value={value}
                                // inputRef={ref}

                                options={[
                                  { value: "all", label: "Select All" },
                                  ...users.map((val) => {
                                    return {
                                      value: val._id,
                                      label: val.name || val.userId,
                                    };
                                  }),
                                ]}
                                className={`basic-multi-select ${
                                  errors.toUser ? "is-invalid" : ""
                                }`}
                                classNamePrefix="select"
                                menuPosition="fixed"
                              />
                            )}
                          />
                          {errors.toUser?.type === "required" && (
                            <div style={{display : 'block'}} className="invalid-feedback">
                              The to user field is required.
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <button
                      onClick={handleSubmit(onSubmit)}
                      style={{ display: "none" }}
                    ></button>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Send"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
