import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";
import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { ExportButton, SearchInput, SearchSubmitButton } from "../Form/Form";
import { addOneToDate, downloadExcel, downloadPDF } from "../../util/fn";
import { ADMINURL } from "../../constant/api";
import moment from "moment";

const OBJ_TABLE = {
  Id: "accommodationId",
  "Accommodation Type": "accommodationType",
  // "user name": "user_name",
  Name: "nameOrLodge",
  "Preferred Gender": "preferredGender",
  "Preferred Age Range": "preferredAgeRange",
  "created at": "createdAt",
  isApproved: "status",
  status: "isActive",
  Reported: "isReported",
};

const searchQueryHandler = ({
  page,
  per_page,
  sortBy,
  order,
  isActive = "",
  dateFrom = "1970-01-01",
  dateTo,

  user_name = "",
  user_customId = "",
  user_userName = "",

  roomMateId = "",
  roomId = "",
  name = "",
  lodgeName = "",
  status = "",

  category = "",

  preferredGender = "",
  preferredReligion = "",
}) => {
  // -----
  isActive = isActive ?? "";
  dateFrom = dateFrom ?? "1970-01-01";
  order = order.length > 0 ? order : "desc";
  dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";
  dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());

  user_name = user_name ?? "";
  user_customId = user_customId ?? "";
  user_userName = user_userName ?? "";
  roomMateId = roomMateId ?? "";
  roomId = roomId ?? "";
  name = name ?? "";
  lodgeName = lodgeName ?? "";
  status = status ?? "";
  category = category ?? "";
  preferredGender = preferredGender ?? "";
  preferredReligion = preferredReligion ?? "";
  if (sortBy.length > 0) {
    if (sortBy == "registered on" || sortBy == "Created At") {
      sortBy = "createdAt";
    }
  } else {
    sortBy = "createdAt";
  }
  page = page ?? 1;
  per_page = per_page ?? 10;
  // -----

  return `accommodation?page=${page}&per_page=${per_page}&sortBy=${sortBy}&order=${order}&isActive=${isActive}&dateFrom=${dateFrom}&dateTo=${dateTo}&user_name=${user_name}&user_customId=${user_customId}&user_userName=${user_userName}&roomMateId=${roomMateId}&roomId=${roomId}&name=${name}&lodgeName=${lodgeName}&category=${category}&status=${status}&preferredGender=${preferredGender}&preferredReligion=${preferredReligion}`;
};

const ViewAll = () => {
  const history = useHistory();
  const [accommodation, setAccommodation] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "registered on",
    order: "desc",
  });

  const MySwal = withReactContent(Swal);

  const { records_per_page, date_format } = useSelector(
    (state) => state.setting
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    clearErrors,
    setError,
  } = useForm();

  const { request, response } = useRequest();
  const { request: requestChangeStatus, response: responseChangeStatus } =
    useRequest();
  const { request: requestCheckApproved, response: responseCheckApproved } =
    useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler({
          page: 1,
          per_page: records_per_page,
          sortBy: currentSort.sortBy,
          order: currentSort.order,
        })
      );
    }
    document.title = "Accommodation - Kura";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      setAccommodation(response.accommodations);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  useEffect(() => {
    if (responseChangeStatus) {
      const { id, newStatus } = responseChangeStatus;

      const oldAccommodation = [...accommodation];
      const indexToChange = oldAccommodation.findIndex(
        (scholar) => scholar._id == id
      );
      oldAccommodation[indexToChange].isActive = newStatus;

      setAccommodation(oldAccommodation);
    }
  }, [responseChangeStatus]);

  useEffect(() => {
    if (responseCheckApproved) {
      const { id, status } = responseCheckApproved;

      const oldAccommodation = [...accommodation];
      const indexToChange = oldAccommodation.findIndex((hub) => hub._id == id);
      oldAccommodation[indexToChange].status = status;

      setAccommodation(oldAccommodation);
    }
  }, [responseCheckApproved]);

  useEffect(() => {
    if (responseDelete) {
      const { id } = responseDelete;
      let newAccommodation = [...accommodation];
      newAccommodation = newAccommodation.filter(
        (scholar) => scholar._id != id
      );
      setAccommodation(newAccommodation);
      toast.success("Accommodation has been deleted successfully.");
    }
  }, [responseDelete]);

  const fetchMoreData = ({ selected }) => {
    setAccommodation([]);
    const {
      isActive,
      dateFrom,
      dateTo,
      user_name,
      user_customId,
      user_userName,
      roomId,
      status,
      preferredGender,
      preferredReligion,
    } = getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler({
        page: selected + 1,
        per_page: perPage,
        sortBy: currentSort.sortBy,
        order: currentSort.order,
        isActive,
        dateFrom,
        dateTo,
        user_name,
        user_customId,
        user_userName,
        roomId,
        status,
        preferredGender,
        preferredReligion,
      })
    );
  };

  const deleteHandler = (id, data) => {
    let roomType;
    if (data.accommodationId[1] === "V") {
      roomType = "vacantRoom";
    } else {
      roomType = "roomMate";
    }

    MySwal.fire({
      title: "Are you sure?",
      text: "You want to delete this Accommodation?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestDelete("DELETE", "accommodation", { id, roomType });
      } else if (result.isDismissed) {
      }
    });
  };

  const onSearchHandler = (data) => {
    let {
      isActive,
      dateFrom,
      dateTo,
      user_name,
      user_customId,
      user_userName,
      roomId,
      status,
      preferredGender,
      preferredReligion,
    } = data;

    request(
      "GET",
      searchQueryHandler({
        page: 1,
        per_page: perPage,
        sortBy: currentSort.sortBy,
        order: currentSort.order,
        isActive,
        dateFrom,
        dateTo,
        user_name,
        user_customId,
        user_userName,
        roomId,
        status,
        preferredGender,
        preferredReligion,
      })
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("isActive");
    resetField("dateFrom");
    resetField("dateTo");
    resetField("user_name");
    resetField("user_customId");
    resetField("user_userName");
    resetField("accommodationId");
    resetField("status");
    resetField("preferredGender");
    resetField("preferredReligion");
    request(
      "GET",
      searchQueryHandler({
        page: 1,
        per_page: perPage,
        sortBy: currentSort.sortBy,
        order: currentSort.order,
      })
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const {
      isActive,
      dateFrom,
      dateTo,
      user_name,
      user_customId,
      user_userName,
      roomId,
      status,
      preferredGender,
      preferredReligion,
    } = getValues();
    request(
      "GET",
      searchQueryHandler({
        page: 1,
        per_page: event.target.value,
        sortBy: currentSort.sortBy,
        order: currentSort.order,
        isActive,
        dateFrom,
        dateTo,
        user_name,
        user_customId,
        user_userName,
        roomId,
        status,
        preferredGender,
        preferredReligion,
      })
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    const {
      isActive,
      dateFrom,
      dateTo,
      user_name,
      user_customId,
      user_userName,
      roomId,
      status,
      preferredGender,
      preferredReligion,
    } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler({
          page,
          per_page: perPage,
          sortBy,
          order: newOrder,
          isActive,
          dateFrom,
          dateTo,
          user_name,
          user_customId,
          user_userName,
          roomId,
          status,
          preferredGender,
          preferredReligion,
        })
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler({
          page,
          per_page: perPage,
          sortBy,
          order: "desc",
          isActive,
          dateFrom,
          dateTo,
          user_name,
          user_customId,
          user_userName,
          roomId,
          status,
          preferredGender,
          preferredReligion,
        })
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  const changeStatusHandler = (id, data) => {
    let roomType;
    if (data.accommodationId[1] === "V") {
      roomType = "vacantRoom";
    } else {
      roomType = "roomMate";
    }

    const scholars = accommodation.find((scholar) => scholar._id == id);
    const status = scholars.isActive == true ? false : true;

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestChangeStatus("PUT", "accommodation/status", {
          id,
          status,
          roomType,
        });
      } else if (result.isDismissed) {
      }
    });
  };

  const changeApprovedHandler = (id, data) => {
    let roomType;
    if (data.accommodationId[1] === "V") {
      roomType = "vacantRoom";
    } else {
      roomType = "roomMate";
    }
    // const scholars = accommodation.find((scholar) => scholar._id == id);
    // const status = scholars.isApproved == "true" ? "false" : "true";

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Approve",
      denyButtonText: `Not - Approve`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        requestCheckApproved("PUT", "accommodation/approve", {
          id,
          status: "approved",
          roomType,
        });
      } else if (result.isDenied) {
        requestCheckApproved("PUT", "accommodation/approve", {
          id,
          status: "rejected",
          roomType,
        });
      }
    });

    // MySwal.fire({
    //   title: "Are you sure?",
    //   text: "Want to change this status?",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonText: "Yes, change it",
    //   cancelButtonText: "No, cancel",
    //   reverseButtons: true,
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     requestChangeStatus("PUT", "accommodation/status", {
    //       id,
    //       status,
    //     });
    //   } else if (result.isDismissed) {
    //   }
    // });
  };

  const openCreatorHandler = (id, data) => {
    // window.open(`${ADMINURL}/users/view/${data.userId}`, "_blank");
    history.push(`/users/view/${data.userId}`);
  };

  const viewReportHandler = (id, data) => {
    const type = data.accommodationType[1] === "V" ? "vacantRoom" : "roomMate";

    history.push(`/report/viewlist/${type}/${id}`);
  };

  const getDownloadData = () => {
    let data = JSON.parse(JSON.stringify(accommodation));

    data = data.map((item) => {
      return {
        Id: item.accommodationId,
        "Accommodation Type":
          item.accommodationType[1] === "V" ? "Vacant Room" : "Room Mate",
        Name: item.nameOrLodge,
        "Preferred Gender": item.preferredGender,
        "Preferred Age Range": Array.isArray(item.preferredAgeRange)
          ? item.preferredAgeRange.join(", ")
          : item.preferredAgeRange,
        "Created At": moment(item.createdAt).format(date_format),
        "Is Approved":
          item.status.charAt(0).toUpperCase() + item.status.slice(1),
        Status: item.isActive ? "Active" : "Inactive",
        Reported: item.isReported ? "True" : "False",
      };
    });

    return data;
  };

  const downloadExcelHandler = () => {
    const data = getDownloadData();

    downloadExcel(data, "Accommodation");
  };

  const generatePdfDocument = async () => {
    const data = getDownloadData();

    const headings = data.length > 0 ? Object.keys(data[0]) : [];

    await downloadPDF(headings.length, headings, data, "Accommodation");
  };

  const InputFields = [
    {
      label: "User (Name)",
      name: "user_name",
      required: false,
    },
    {
      label: "User Name",
      name: "user_userName",
      required: false,
    },
    {
      label: "User Id",
      name: "user_customId",
      required: false,
    },
    {
      label: "Preferred Gender",
      name: "preferredGender",
      required: false,
    },
    {
      label: "Preferred Religion",
      name: "preferredReligion",
      required: false,
    },
    {
      label: "Vaccant Room Id",
      name: "roomId",
      required: false,
    },
    {
      label: "Room Mate Id",
      name: "roomMateId",
      required: false,
    },
    {
      label: "Status",
      name: "status",
      required: false,
      isSelectInput: true,
      children: (
        <>
          <option value="">Select</option>
          <option value="pending">Pending</option>
          <option value="approved">Approved</option>
          <option value="rejected">Rejected</option>
        </>
      ),
    },
    {
      label: "IsActive",
      name: "isActive",
      required: false,
      isSelectInput: true,
      children: (
        <>
          <option value="">Select</option>
          <option value="true">Active</option>
          <option value="false">Not active</option>
        </>
      ),
    },
    {
      label: "Date From",
      name: "dateFrom",
      isDate: true,
      clearErrors,
    },
    {
      label: "Date To",
      name: "dateTo",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback: "'To Date' cannot be smaller than 'From Date'",
      },
    },
  ];

  // console.log(seekers)

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Accommodation"
        links={[{ to: "/", name: "Dashboard" }]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <ExportButton
                      downloadExcelHandler={downloadExcelHandler}
                      downloadPdfHandler={generatePdfDocument}
                    />

                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>

                    {/* <Link to="/accommodation/add" className="btn btn-primary">
                      Add New Up-cycle
                    </Link> */}
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={accommodation}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      renderAs={{
                        status: (val) =>
                          val.charAt(0).toUpperCase() + val.slice(1),
                        // preferredAgeRange: (val) => `${val[0]}-${val[1]}`,
                        accommodationType: (val) =>
                          val[1] === "V" ? "Vacant Room" : "Room Mate",
                        isReported: (val) => (val ? "True" : "False"),
                      }}
                      links={[
                        // {
                        //   isLink: true,
                        //   to: `/wallet/view`,
                        //   name: "Wallet",
                        //   extraData: true,
                        // },
                        {
                          isLink: false,
                          name: "Deactivate",
                          click: changeStatusHandler,
                          title: "Click To Activate",
                          access: ["t3"],
                        },
                        {
                          isLink: false,
                          name: "Activate",
                          click: changeStatusHandler,
                          title: "Click To Deactivate",
                          access: ["t3"],
                        },
                        {
                          isLink: false,
                          name: "Approved",
                          click: changeApprovedHandler,
                          title: "Click To Approve/Reject",
                          access: ["t9"],
                        },
                        // {
                        //   isLink: true,
                        //   to: "/accommodation/view",
                        //   name: "View",
                        //   extraData: true,
                        // },
                        // {
                        //   isLink: true,
                        //   to: "/accommodation/edit",
                        //   name: "Edit",
                        //   extraData: true,
                        // },
                        {
                          isLink: false,
                          name: "Creator",
                          click: openCreatorHandler,
                          title: "View Creator",
                          access: ["t11"],
                        },
                        {
                          isLink: false,
                          name: "Delete",
                          click: deleteHandler,
                          access: ["t6"],
                        },
                        {
                          isLink: false,
                          name: "Report",
                          click: viewReportHandler,
                          title: "View report",
                          access: ["t10"],
                        },
                      ]}
                      onlyDate={{
                        createdAt: "date",
                        startDate: "dateTime",
                        endDate: "dateTime",
                      }}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={accommodation.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
