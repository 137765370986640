import * as XLSX from "xlsx";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";

export function convertDate(inputFormat) {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  let d = new Date(inputFormat);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
}

export const addOneToDate = (currDate) => {
  currDate.setDate(currDate.getDate() + 1);
  return (currDate = currDate.toISOString().split("T")[0]);
};

export const intersect = (arr1, arr2) => {
  for (let i = 0; i < arr1.length; i++) {
    for (let j = 0; j < arr2.length; j++) {
      if (arr1[i] === arr2[j]) {
        return true;
      }
    }
  }
  return false;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const downloadExcel = (data, name) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${name}.xlsx`);
};

export const downloadPDF = async (totalColumns, headings, items, name) => {
  const BORDER_COLOR = "#bfbfbf";
  const BORDER_STYLE = "solid";
  const COLN_WIDTH = 100 / totalColumns;

  const styles = StyleSheet.create({
    body: {
      padding: 10,
    },
    table: {
      display: "table",
      width: "auto",
      borderStyle: BORDER_STYLE,
      borderColor: BORDER_COLOR,
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableColHeader: {
      width: COLN_WIDTH + "%",
      borderStyle: BORDER_STYLE,
      borderColor: BORDER_COLOR,
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCol: {
      width: COLN_WIDTH + "%",
      borderStyle: BORDER_STYLE,
      borderColor: BORDER_COLOR,
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCellHeader: {
      margin: 5,
      fontSize: 12,
      fontWeight: 500,
    },
    tableCell: {
      margin: 5,
      fontSize: 10,
    },
  });

  const MyDocument = (
    <Document>
      <Page style={styles.body}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            {headings.map((heading, idx) => (
              <View key={idx} style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>{heading}</Text>
              </View>
            ))}
          </View>

          {items.length > 0 &&
            items.map((item, idx) => (
              <View key={idx} style={styles.tableRow}>
                {headings.map((heading, hIdx) => (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{item[heading]}</Text>
                  </View>
                ))}
              </View>
            ))}
        </View>
      </Page>
    </Document>
  );

  const blob = await pdf(MyDocument).toBlob();

  saveAs(blob, `${name}.pdf`);
};
