import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { Input, RenderInputFields, SubmitButton } from "../Form/Form";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm();

  const { city, town, council, state, country } = watch();

  const [existingList, setExistingList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const { response, request } = useRequest();
  const { request: alreadyExistingReq, response: alreadyExistingRes } =
    useRequest();

  const history = useHistory();

  useEffect(() => {
    document.title = "Add City - Kura";
    alreadyExistingReq("GET", "admin/cities");
  }, []);

  useEffect(() => {
    if (response) {
      toast.success("City has been added successfully.");
      history.push("/city");
    }
  }, [response]);

  useEffect(() => {
    if (alreadyExistingRes) {
      setExistingList(alreadyExistingRes?.data);
      setSuggestions(alreadyExistingRes?.data);
    }
  }, [alreadyExistingRes]);

  const onSubmit = (data) => {
    for (let a in data) {
      data[a] = data[a] ? data[a] : undefined;
    }

    const { city, town, council, state, country } = data;

    request("POST", "master/city", {
      city,
      town,
      council,
      state,
      country,
    });
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "City",
        type: "text",
        name: "city",
        registerFields: {
          // required: true,
        },
        colClass: "col-xl-6 oneInputInOneRow",
      },
      {
        Component: Input,
        label: "Town",
        type: "text",
        name: "town",
        registerFields: {
          // required: true,
        },
        colClass: "col-xl-6 oneInputInOneRow",
      },
      {
        Component: Input,
        label: "L.G.A/Council",
        type: "text",
        name: "council",
        registerFields: {
          // required: true,
        },
        colClass: "col-xl-6 oneInputInOneRow",
      },
      {
        Component: Input,
        label: "State",
        type: "text",
        name: "state",
        registerFields: {
          // required: true,
        },
        colClass: "col-xl-6 oneInputInOneRow",
      },
      {
        Component: Input,
        label: "Country",
        type: "text",
        name: "country",
        registerFields: {
          // required: true,
        },
        colClass: "col-xl-6 oneInputInOneRow",
      },
    ],
  ];

  useEffect(() => {
    let keywords = [];
    let inputWatch = {
      city,
      town,
      council,
      state,
      country,
    };
    for (let key in inputWatch) {
      if (inputWatch[key]) {
        keywords.push(inputWatch[key].trim().toLowerCase());
      }
    }

    let newSuggestions = [];
    existingList.forEach((a) => {
      keywords.forEach((b) => {
        let name = a?.name ? a.name.trim().toLowerCase() : "";
        if (name.indexOf(b) !== -1) {
          newSuggestions.push(a);
        }
      });
    });

    // if (newSuggestions.length > 0) {
    setSuggestions(newSuggestions);
    // }
    if (keywords.length === 0) {
      setSuggestions(existingList);
    }

    // console.log(newSuggestions);
  }, [city, town, council, state, country]);

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add City"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/city", name: "Back To City" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Add New City
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
              <div className="address-suggestion-div">
                <h3 className="mb-10 font-weight-bold text-dark">
                  Already Exists
                </h3>
                <table className="address-suggestion">
                  {suggestions.map((val) => {
                    return (
                      <tr key={val._id}>
                        <td>{val.name}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
