// export const API = {
//   PORT:
//     process.env.NODE_ENV == "development"
//       ? "http://192.168.235.245:7007"
//       : "https://kurans.stage04.obdemo.com",
// };

// export const BASEURL = {
//   PORT:
//     process.env.NODE_ENV == "development"
//       ? "http://192.168.235.245:7007"
//       : "https://kurans.stage04.obdemo.com",
// };

// export const ADMINURL =
//   process.env.NODE_ENV == "development"
//     ? "http://192.168.235.245:3007"
//     : "https://kura.stage04.obdemo.com";

// export const FRONTENDURL =
//   process.env.NODE_ENV == "development"
//     ? "http://192.168.235.245:3004"
//     : "https://kura.stage04.obdemo.com";


export const API = {
  PORT:
    process.env.NODE_ENV == "development"
      ? "http://192.168.235.245:7007"
      : "https://kns.thekura.co",
};

export const BASEURL = {
  PORT:
    process.env.NODE_ENV == "development"
      ? "http://192.168.235.245:7007"
      : "https://kns.thekura.co",
};

export const ADMINURL =
  process.env.NODE_ENV == "development"
    ? "http://192.168.235.245:3007"
    : "https://bkpanel.thekura.co";

export const FRONTENDURL =
  process.env.NODE_ENV == "development"
    ? "http://192.168.235.245:3004"
    : "https://thekura.co";
