import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "react-modal";
import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { SearchInput, SearchSubmitButton } from "../Form/Form";
import { addOneToDate, intersect } from "../../util/fn";

const OBJ_TABLE = {
  "user ID": "user_uniqueId",
  Name: "name",
  Subject: "subject",
  "E-Mail": "email",
  // Deadline: "deadline",
  "Created At": "createdAt",
  Replied: "isReplied",
};

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order = "",
  name = "",
  subject = "",
  email = "",
  dateFrom = "1970-01-01",
  dateTo
) => {
  if (sortBy.length > 0) {
    if (sortBy == "registered on" || sortBy == "Created At") {
      sortBy = "createdAt";
    }
  } else {
    sortBy = "createdAt";
  }
  dateFrom = dateFrom.length > 0 ? dateFrom : "1970-01-01";

  dateTo = dateTo ? addOneToDate(new Date(dateTo)) : addOneToDate(new Date());
  order = order.length > 0 ? order : "desc";

  return `contact-us?page=${page}&per_page=${per_page}&sortBy=${sortBy}&order=${order}&name=${name}&subject=${subject}&email=${email}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
};

const ViewContactUs = () => {
  const [contacts, setContacts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [viewModalData, setViewModalData] = useState({});
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "Created At",
    order: "desc",
  });

  const { records_per_page } = useSelector((state) => state.setting);

  const viewModalHandler = (id) => {
    const data = contacts.find((email) => email._id == id);
    if (!data) {
      return;
    }
    setViewModalData(data);
    setShowModal(true);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    getValues,
    clearErrors,
    setError,
  } = useForm();

  const { request, response } = useRequest();
  const { request: loginRequest, response: loginResponce } = useRequest();

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = "Scholarship - Kura";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      setContacts(response.contactUs);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  useEffect(() => {
    if (loginResponce) {
      window.open(
        `http://192.168.235.200:3002?token=${loginResponce?.token}`,
        "_blank",
        "noopener,noreferrer"
      );
    }
  }, [loginResponce]);

  const fetchMoreData = ({ selected }) => {
    setContacts([]);
    const { name, subject, email, dateFrom, dateTo } = getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        name,
        subject,
        email,
        dateFrom,
        dateTo
      )
    );
  };

  const onSearchHandler = (data) => {
    let { name, subject, email, dateFrom, dateTo } = data;

    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        name,
        subject,
        email,
        dateFrom,
        dateTo
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("name");
    resetField("subject");
    resetField("email");
    resetField("dateFrom");
    resetField("dateTo");
    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const { name, subject, email, dateFrom, dateTo } = getValues();
    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        name,
        subject,
        email,
        dateFrom,
        dateTo
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    const { name, subject, email, dateFrom, dateTo } = getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          newOrder,
          name,
          subject,
          email,
          dateFrom,
          dateTo
        )
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          "desc",
          name,
          subject,
          email,
          dateFrom,
          dateTo
        )
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  const loginUser = (id) => {
    loginRequest("POST", "admin/login-user", { id });
  };

  const InputFields = [
    {
      label: "Name",
      name: "name",
      required: false,
    },
    {
      label: "Subject",
      name: "subject",
      required: false,
    },
    {
      label: "E-mail",
      name: "email",
      required: false,
    },
    {
      label: "Date From",
      name: "dateFrom",
      isDate: true,
      clearErrors,
    },
    {
      label: "Date To",
      name: "dateTo",
      isDate: true,
      clearErrors,
      otherRegisterFields: {
        manual: true,
        feedback: "'To Date' cannot be smaller than 'From Date'",
      },
    },
  ];

  // console.log(seekers)

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb title="Contact-Us" links={[{ to: "/", name: "Dashboard" }]} />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={contacts}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      renderAs={{
                        isReplied: (val) => (val ? "True" : "False"),
                        user_uniqueId: (val) => (val ? val : "Not Available"),
                      }}
                      links={[
                        {
                          isLink: true,
                          to: "/contact-us/view",
                          name: "View",
                          extraData: true,
                          access: ["x2"],
                        },
                      ]}
                      onlyDate={{
                        createdAt: "date",
                        startDate: "dateTime",
                        endDate: "dateTime",
                        deadline: "dateTime",
                      }}
                      isHighlight={(data) => {
                        return !data.isReplied;
                      }}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={contacts.length}
                      />
                    )}
                    {/* <Modal
                      isOpen={showModal}
                      ariaHideApp={false}
                      className="model_block"
                      onRequestClose={() => setShowModal(false)}
                    >
                      <div
                        className="modal-dialog modal-dialog-centered modal-lg"
                        role="document"
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Contact - Us Details</h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={() => setShowModal(false)}
                            >
                              <i aria-hidden="true" className="ki ki-close"></i>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="form-group row my-2">
                                  <label className="col-12 font-size-h6 text-dark-75 font-weight-bolder">
                                    Name
                                  </label>
                                  <div className="col-12">
                                    <span className="font-size-sm text-muted font-weight-bold mt-1r">
                                      {viewModalData?.name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group row my-2">
                                  <label className="col-12 font-size-h6 text-dark-75 font-weight-bolder">
                                    Email
                                  </label>
                                  <div className="col-12">
                                    <span className="font-size-sm text-muted font-weight-bold mt-1r">
                                      {viewModalData?.email}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="form-group row my-2">
                              <label className="col-12 font-size-h6 text-dark-75 font-weight-bolder">
                                Subject
                              </label>
                              <div className="col-12">
                                <span className="font-size-sm text-muted font-weight-bold mt-1r">
                                  {viewModalData?.subject}
                                </span>
                              </div>
                            </div>
                            <hr />
                            <div className="form-group row my-2">
                              <label className="col-12 font-size-h6 text-dark-75 font-weight-bolder">
                                Message
                              </label>
                              <div className="col-12">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: viewModalData?.message,
                                  }}
                                  className="font-size-sm text-muted font-weight-bold mt-1r"
                                ></span>
                              </div>
                            </div>
                            <div className="clearfix">&nbsp;</div>
                          </div>
                        </div>
                      </div>
                    </Modal> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewContactUs;
