import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const intersect = (arr1, arr2) => {
  for (let i = 0; i < arr1.length; i++) {
    for (let j = 0; j < arr2.length; j++) {
      if (arr1[i] === arr2[j]) {
        return true;
      }
    }
  }
  return false;
};

const OneLevelMenu = ({ menu, pathname }) => {
  const [active, setActive] = useState(false);

  const { name, Svg, subMenu, highlight, subHighlight, access } = menu;

  const paths = subMenu.map((sub) => sub.path);

  const { permissions, roleId } = useSelector((state) => state.auth);

  useEffect(() => {
    if (
      paths.includes(pathname) ||
      paths.includes("/" + pathname.split("/")[1]) ||
      highlight.includes(pathname) ||
      subHighlight.includes(pathname.split("/").slice(0, 3).join("/"))
    ) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [pathname]);

  if (
    roleId === 2 &&
    permissions &&
    access &&
    !intersect(permissions, access)
  ) {
    return null;
  }

  return (
    <li
      id={`collapse-menu-main ${name}-toggle-7`}
      className={`menu-item menu-item-submenu ${
        active ? "menu-item-open" : ""
      } `}
      data-menu-toggle="hover"
      onClick={() => setActive((prev) => !prev)}
    >
      <a className="menu-link menu-toggle">
        <span className="svg-icon menu-icon">
          <Svg />
        </span>
        <span className="menu-text">{name}</span>
        <i className="menu-arrow"></i>
      </a>
      <div className="menu-submenu">
        <i className="menu-arrow"></i>
        <ul className="menu-subnav">
          <li className="menu-item menu-item-parent">
            <span className="menu-link">
              <span className="menu-text">{name}</span>
            </span>
          </li>
          {subMenu
            .filter((menu) => {
              if (roleId === 2 && menu.access) {
                return intersect(permissions, menu.access);
              } else {
                return true;
              }
            })
            .map((menu, index) => (
              <li
                key={index}
                className={`menu-item ${
                  (menu.path == pathname ||
                    menu.highlight.includes(pathname) ||
                    menu.subHighlight.includes(
                      pathname.split("/").slice(0, 3).join("/")
                    )) &&
                  "menu-item-open"
                }`}
              >
                <Link to={menu.path} className="menu-link">
                  <i className="menu-bullet menu-bullet-line">
                    <span></span>
                  </i>
                  <span className="menu-text">{menu.name}</span>
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </li>
  );
};

export default OneLevelMenu;
