import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { SearchInput, SearchSubmitButton } from "../Form/Form";
import { addOneToDate, intersect } from "../../util/fn";

const OBJ_TABLE = {
    Category: "category",
    "registered on": "createdAt",
    status: "isActive",
};

const searchQueryHandler = (
    page,
    per_page,
    sortBy,
    order,
    isActive = "",
    category = ""
) => {
    if (sortBy.length > 0) {
        if (sortBy == "registered on") {
            sortBy = "createdAt";
        }
    } else {
        sortBy = "createdAt";
    }
    order = order.length > 0 ? order : "desc";

    return `master/room-category?page=${page}&per_page=${per_page}&sortBy=${sortBy}&order=${order}&isActive=${isActive}&category=${category}`;
};

const ViewAllTypes = () => {
    const [categories, setCategories] = useState([]);
    const [page, setPage] = useState(1);
    const [totalDocuments, setTotalDocuments] = useState(10);
    const [perPage, setPerPage] = useState(0);
    const [currentSort, setCurrentSort] = useState({
        sortBy: "registered on",
        order: "desc",
    });

    const MySwal = withReactContent(Swal);

    const { records_per_page } = useSelector((state) => state.setting);
    const { permissions, roleId } = useSelector((state) => state.auth);

    const {
        register,
        handleSubmit,
        formState: { errors },
        resetField,
        getValues,
        clearErrors,
        setError,
    } = useForm();

    const { request, response } = useRequest();
    const { request: requestChangeStatus, response: responseChangeStatus } =
        useRequest();
    const { request: requestDelete, response: responseDelete } = useRequest();

    useEffect(() => {
        if (records_per_page) {
            setPerPage(records_per_page);
            request(
                "GET",
                searchQueryHandler(
                    1,
                    records_per_page,
                    currentSort.sortBy,
                    currentSort.order
                )
            );
        }
        document.title = "Room Category - Kura";
    }, [records_per_page]);

    useEffect(() => {
        if (response) {
            setCategories(response.roomCategories);
            setTotalDocuments((prev) => response.totalDocuments ?? prev);
        }
    }, [response]);

    useEffect(() => {
        if (responseChangeStatus) {
            const { id, newStatus } = responseChangeStatus;

            const oldReportReason = [...categories];
            const indexToChange = oldReportReason.findIndex(
                (scholar) => scholar._id == id
            );
            oldReportReason[indexToChange].isActive = newStatus;

            setCategories(oldReportReason);
        }
    }, [responseChangeStatus]);

    useEffect(() => {
        if (responseDelete) {
            const { id } = responseDelete;
            let newReportReason = [...categories];
            newReportReason = newReportReason.filter((ty) => ty._id != id);
            setCategories(newReportReason);
            toast.success("Room category has been deleted successfully.");
        }
    }, [responseDelete]);

    const fetchMoreData = ({ selected }) => {
        setCategories([]);
        const { isActive, category } = getValues();

        setPage(selected + 1);
        request(
            "GET",
            searchQueryHandler(
                selected + 1,
                perPage,
                currentSort.sortBy,
                currentSort.order,
                isActive,
                category
            )
        );
    };

    const deleteHandler = (id) => {
        MySwal.fire({
            title: "Are you sure?",
            text: "You want to delete this room category?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it",
            cancelButtonText: "No, cancel",
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                requestDelete("DELETE", "master/room-category", { id });
            } else if (result.isDismissed) {
            }
        });
    };

    const onSearchHandler = (data) => {
        let { isActive, category } = data;

        request(
            "GET",
            searchQueryHandler(
                1,
                perPage,
                currentSort.sortBy,
                currentSort.order,
                isActive,
                category
            )
        );
        setPage(1);
    };

    const onResetHandler = (e) => {
        e.preventDefault();
        resetField("isActive");
        resetField("dateFrom");
        resetField("dateTo");
        request(
            "GET",
            searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
        );
        setPage(1);
    };

    const perPageChangeHandler = (event) => {
        const { isActive, category } = getValues();
        request(
            "GET",
            searchQueryHandler(
                1,
                event.target.value,
                currentSort.sortBy,
                currentSort.order,
                isActive,
                category
            )
        );
        setPage(1);

        setPerPage(event.target.value);
    };

    const sortingHandler = (sortBy) => {
        const { isActive, category } = getValues();

        if (currentSort.sortBy == sortBy) {
            const newOrder = currentSort.order === "asc" ? "desc" : "asc";
            request(
                "GET",
                searchQueryHandler(page, perPage, sortBy, newOrder, isActive, category)
            );
            setCurrentSort({ sortBy, order: newOrder });
        } else {
            request(
                "GET",
                searchQueryHandler(page, perPage, sortBy, "desc", isActive, category)
            );
            setCurrentSort({ sortBy, order: "desc" });
        }
    };

    const changeStatusHandler = (id, data) => {

        const status = data.isActive == true ? false : true;

        MySwal.fire({
            title: "Are you sure?",
            text: "Want to change this status?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, change it",
            cancelButtonText: "No, cancel",
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                requestChangeStatus("PUT", "master/room-category/status", {
                    id,
                    status,
                });
            } else if (result.isDismissed) {
            }
        });
    };

    const InputFields = [
        {
            label: "Category",
            name: "category",
            required: false,
        },
        {
            label: "Status",
            name: "isActive",
            required: false,
            isSelectInput: true,
            children: (
                <>
                    <option value="">Select</option>
                    <option value={true}>Active</option>
                    <option value={false}>Not active</option>
                </>
            ),
        },

        {
            label: "Date From",
            name: "dateFrom",
            isDate: true,
            clearErrors,
        },
        {
            label: "Date To",
            name: "dateTo",
            isDate: true,
            clearErrors,
            otherRegisterFields: {
                manual: true,
                feedback: "'To Date' cannot be smaller than 'From Date'",
            },
        },
    ];

    // console.log(seekers)

    return (
        <div
            className="content  d-flex flex-column flex-column-fluid"
            id="kt_content"
        >
            <Breadcrumb
                title="Room Category"
                links={[{ to: "/", name: "Dashboard" }]}
            />

            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-custom card-stretch card-shadowless">
                                <div className="card-header">
                                    <div className="card-title"></div>
                                    <div className="card-toolbar">
                                        <a
                                            /*href="#!"*/
                                            className="btn btn-primary dropdown-toggle mr-2"
                                            data-toggle="collapse"
                                            data-target="#collapseOne6"
                                        >
                                            Search
                                        </a>

                                        {(roleId === 1 ||
                                            (roleId === 2 &&
                                                permissions &&
                                                intersect(permissions, ["E4"]))) && (
                                                <Link
                                                    to="/room-category/add"
                                                    className="btn btn-primary"
                                                >
                                                    Add New Category
                                                </Link>
                                            )}
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div
                                        className="accordion accordion-solid accordion-toggle-plus"
                                        id="accordionExample6"
                                    >
                                        <div
                                            id="collapseOne6"
                                            className="collapse"
                                            data-parent="#accordionExample6"
                                        >
                                            <div>
                                                <form
                                                    onSubmit={handleSubmit(onSearchHandler)}
                                                    className="kt-form kt-form--fit mb-0"
                                                >
                                                    <div className="row mb-6">
                                                        {InputFields.map((inputMain, index) => (
                                                            <SearchInput
                                                                key={index}
                                                                {...inputMain}
                                                                errors={errors}
                                                                register={register}
                                                            />
                                                        ))}
                                                    </div>

                                                    <SearchSubmitButton
                                                        handleSubmit={handleSubmit}
                                                        onSearchHandler={onSearchHandler}
                                                        onResetHandler={onResetHandler}
                                                    />
                                                </form>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dataTables_wrapper ">
                                        <Table
                                            currentSort={currentSort}
                                            sortingHandler={sortingHandler}
                                            mainData={categories}
                                            tableHeading={Object.keys(OBJ_TABLE)}
                                            tableData={Object.values(OBJ_TABLE)}
                                            links={[
                                                {
                                                    isLink: false,
                                                    name: "Deactivate",
                                                    click: changeStatusHandler,
                                                    title: "Click To Activate",
                                                    access: ["E3"],
                                                },
                                                {
                                                    isLink: false,
                                                    name: "Activate",
                                                    click: changeStatusHandler,
                                                    title: "Click To Deactivate",
                                                    access: ["E3"],
                                                },
                                                {
                                                    isLink: true,
                                                    to: "/room-category/edit",
                                                    name: "Edit",
                                                    extraData: true,
                                                    access: ["E5"],
                                                },
                                                {
                                                    isLink: false,
                                                    name: "Delete",
                                                    click: deleteHandler,
                                                    access: ["E6"],
                                                },
                                            ]}
                                            onlyDate={{
                                                createdAt: "date",
                                                startDate: "dateTime",
                                                endDate: "dateTime",
                                                deadline: "dateTime",
                                            }}
                                        />

                                        {perPage !== 0 && (
                                            <Pagination
                                                page={page}
                                                totalDocuments={totalDocuments}
                                                getNewData={fetchMoreData}
                                                perPage={perPage}
                                                defaultPerPage={records_per_page}
                                                perPageChangeHandler={perPageChangeHandler}
                                                currentDocLength={categories.length}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewAllTypes;
