import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  RenderInputFields,
  SubmitButton,
  SelectInput,
} from "../Form/Form";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const { response, request } = useRequest();

  const history = useHistory();

  useEffect(() => {
    document.title = "Add Users - Kura";
  }, []);

  useEffect(() => {
    if (response) {
      toast.success("User has been added successfully.");
      history.push("/users");
    }
  }, [response]);

  const onSubmit = (data) => {
    const {
      name,
      email,
      // username,
      // contact,
      userType,
      // password,
      // confirmpassword,
    } = data;

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setError("email", {
        type: "manual",
      });
      return;
    }

    // if (password !== confirmpassword) {
    //   setError("confirmpassword", {
    //     type: "manual",
    //   });
    //   return;
    // }

    // console.log(userType)
    // return

    request("POST", "user/create", {
      email,
      name,
      // password,      
      // username,
      // contact,
      userType,
    });
  };

  const InputFields = [
    [
      // {
      //   Component: Input,
      //   label: "First Name",
      //   type: "text",
      //   name: "firstName",
      //   registerFields: {
      //     required: true,
      //     pattern: /^[A-Za-z ]+$/,
      //   },
      //   registerFieldsFeedback: {
      //     pattern: "Name can only contain letters.",
      //   },
      // },
      {
        Component: Input,
        label: "Name",
        type: "text",
        name: "name",
        registerFields: {
          required: true,
          pattern: /^[A-Za-z ]+$/,
        },
        registerFieldsFeedback: {
          pattern: "Name can only contain letters.",
        },
      },
      {
        Component: Input,
        label: "Email",
        type: "email",
        name: "email",
        registerFields: {
          required: true,
          pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        },
        registerFieldsFeedback: {
          pattern: "The email field must be a valid email address.",
        },
      },
      // {
      //   Component: Input,
      //   label: "Phone Number",
      //   type: "number",
      //   name: "contact",
      //   registerFields: {
      //     // required: true,
      //   },
      // },
      {
        Component: SelectInput,
        label: "User Type",
        name: "userType",
        registerFields: {
          required: false,
        },
        children: (
          <>
            <option value="">Select</option>
            <option value={"Student"}>Student</option>
            <option value={"Non-Student"}>Non-Student</option>
          </>
        ),
      },
    ],

    // [
    //   {
    //     Component: Input,
    //     label: "Password",
    //     type: "password",
    //     name: "password",
    //     registerFields: {
    //       required: true,
    //       pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
    //     },
    //     registerFieldsFeedback: {
    //       pattern:
    //         "Password must be of 8 characters long with atleast one Uppercase, one Lowercase and one Number",
    //     },
    //   },
    //   {
    //     Component: Input,
    //     label: "Confirm Password",
    //     type: "password",
    //     name: "confirmpassword",
    //     registerFields: {
    //       required: true,
    //       pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
    //     },
    //     registerFieldsFeedback: {
    //       pattern:
    //         "Confirm password must be of 8 characters long with atleast one Uppercase, one Lowercase and one Number",
    //     },
    //     otherRegisterFields: {
    //       manual: true,
    //       feedback: "Both Passwords do not match.",
    //     },
    //   },
    // ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add User"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/users", name: "Back To User" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <h3 className="mb-10 font-weight-bold text-dark">
                    Add New User
                  </h3>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
