import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Switch, Redirect, Route, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./App.css";

import useRequest from "./hooks/useRequest";
import { authSuccess, logout } from "./store/auth/action";
import { addSetting } from "./store/setting/action";

import { privateRoutes, notPrivateRoutes } from "./util/routes";
import Sidebar from "./components/Sidebar/Sidebar";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Loading from "./components/Loading/Loading";
import { intersect } from "./util/fn";

const App = () => {
  const [token, setToken] = useState(null);

  const dispatch = useDispatch();
  const { loggedIn, loading, permissions, roleId } = useSelector(
    (state) => state.auth
  );

  const { request, response } = useRequest();

  const routePath = useLocation();

  useEffect(() => {
    onTop();
  }, [routePath]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(authSuccess({ loggedIn: false }));
    } else {
      setToken(token);
    }
  }, []);

  useEffect(() => {
    if (token) {
      request("POST", "admin/verify", { token });
    }
  }, [token]);

  useEffect(() => {
    if (response) {
      if (!response.status) {
        dispatch(logout());
      } else {
        const setting = {};

        response.setting.forEach((s) => {
          setting[s.newKey] = s.selected || s.value;
        });
        dispatch(
          authSuccess({
            loggedIn: true,
            token,
            userId: response.id,
            name: response.name,
            email: response.email,
            permissions: response.permissions,
            roleId: response.roleId,
          })
        );
        dispatch(addSetting(setting));
      }
    }
  }, [response]);

  const onTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-row flex-column-fluid page">
        {loading && <Loading />}
        {loggedIn !== null && (
          <>
            {loggedIn ? (
              <>
                <Sidebar />
                <div
                  className="d-flex flex-column flex-row-fluid wrapper"
                  id="kt_wrapper"
                >
                  <Header />
                  <Switch>
                    {privateRoutes.map((route, index) => {
                      if (
                        roleId === 2 &&
                        !intersect(permissions, route.access)
                      ) {
                        return null;
                      }
                      return <Route key={index} exact {...route} />;
                    })}
                    <Redirect to="/" />
                  </Switch>
                  <Footer />
                </div>
              </>
            ) : (
              <Switch>
                {notPrivateRoutes.map((route, index) => {
                  {
                    /* if (roleId === 2 && !intersect(permissions, route.access)) {
                    return null
                  } */
                  }
                  return <Route key={index} exact {...route} />;
                })}
                <Redirect to="/login" />
              </Switch>
            )}
            <ToastContainer rtl />
          </>
        )}
      </div>
    </div>
  );
};

export default App;

/*
Old  = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
new = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
*/
