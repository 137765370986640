import React from "react";

export const Store = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //
      //xmlns:xlink="http://www.w3.org/1999/xlink"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24" />
        <path
          d="M14,9 L14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 L10,9 L8,9 L8,8 C8,5.790861 9.790861,4 12,4 C14.209139,4 16,5.790861 16,8 L16,9 L14,9 Z M14,9 L14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 L10,9 L8,9 L8,8 C8,5.790861 9.790861,4 12,4 C14.209139,4 16,5.790861 16,8 L16,9 L14,9 Z"
          fill="#000000"
          fillRule="nonzero"
          opacity="0.3"
        />
        <path
          d="M6.84712709,9 L17.1528729,9 C17.6417121,9 18.0589022,9.35341304 18.1392668,9.83560101 L19.611867,18.671202 C19.7934571,19.7607427 19.0574178,20.7911977 17.9678771,20.9727878 C17.8592143,20.9908983 17.7492409,21 17.6390792,21 L6.36092084,21 C5.25635134,21 4.36092084,20.1045695 4.36092084,19 C4.36092084,18.8898383 4.37002252,18.7798649 4.388133,18.671202 L5.86073316,9.83560101 C5.94109783,9.35341304 6.35828794,9 6.84712709,9 Z"
          fill="#000000"
        />
      </g>
    </svg>
  );
};

export const Driver = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //
      //xmlns:xlink="http://www.w3.org/1999/xlink"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon points="0 0 24 0 24 24 0 24" />
        <path
          d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
          fill="#000000"
          fillRule="nonzero"
          opacity="0.3"
        />
        <path
          d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
          fill="#000000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export const Cms = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //
      //xmlns:xlink="http://www.w3.org/1999/xlink"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon points="0 0 24 0 24 24 0 24" />
        <path
          d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
          fill="#000000"
          fillRule="nonzero"
        />
        <path
          d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
          fill="#000000"
          opacity="0.3"
        />
      </g>
    </svg>
  );
};

export const Dashboard = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //
      //xmlns:xlink="http://www.w3.org/1999/xlink"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon points="0 0 24 0 24 24 0 24" />
        <path
          d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
          fill="#000000"
          fillRule="nonzero"
        />
        <path
          d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
          fill="#000000"
          opacity="0.3"
        />
      </g>
    </svg>
  );
};

export const Settings = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <path
          d="M5,8.6862915 L5,5 L8.6862915,5 L11.5857864,2.10050506 L14.4852814,5 L19,5 L19,9.51471863 L21.4852814,12 L19,14.4852814 L19,19 L14.4852814,19 L11.5857864,21.8994949 L8.6862915,19 L5,19 L5,15.3137085 L1.6862915,12 L5,8.6862915 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z"
          fill="#000000"
        ></path>
      </g>
    </svg>
  );
};

export const View = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24" />
        <path
          d="M12.8434797,16 L11.1565203,16 L10.9852159,16.6393167 C10.3352654,19.064965 7.84199997,20.5044524 5.41635172,19.8545019 C2.99070348,19.2045514 1.55121603,16.711286 2.20116652,14.2856378 L3.92086709,7.86762789 C4.57081758,5.44197964 7.06408298,4.00249219 9.48973122,4.65244268 C10.5421727,4.93444352 11.4089671,5.56345262 12,6.38338695 C12.5910329,5.56345262 13.4578273,4.93444352 14.5102688,4.65244268 C16.935917,4.00249219 19.4291824,5.44197964 20.0791329,7.86762789 L21.7988335,14.2856378 C22.448784,16.711286 21.0092965,19.2045514 18.5836483,19.8545019 C16.158,20.5044524 13.6647346,19.064965 13.0147841,16.6393167 L12.8434797,16 Z M17.4563502,18.1051865 C18.9630797,18.1051865 20.1845253,16.8377967 20.1845253,15.2743923 C20.1845253,13.7109878 18.9630797,12.4435981 17.4563502,12.4435981 C15.9496207,12.4435981 14.7281751,13.7109878 14.7281751,15.2743923 C14.7281751,16.8377967 15.9496207,18.1051865 17.4563502,18.1051865 Z M6.54364977,18.1051865 C8.05037928,18.1051865 9.27182488,16.8377967 9.27182488,15.2743923 C9.27182488,13.7109878 8.05037928,12.4435981 6.54364977,12.4435981 C5.03692026,12.4435981 3.81547465,13.7109878 3.81547465,15.2743923 C3.81547465,16.8377967 5.03692026,18.1051865 6.54364977,18.1051865 Z"
          fill="#000000"
        />
      </g>
    </svg>
  );
};

export const Edit = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24" />
        <path
          d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
          fill="#000000"
          opacity="0.3"
        />
        <path
          d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
          fill="#000000"
        />
      </g>
    </svg>
  );
};

export const Delete = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24" />
        <path
          d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
          fill="#000000"
          fillRule="nonzero"
        />
        <path
          d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
          fill="#000000"
          opacity="0.3"
        />
      </g>
    </svg>
  );
};

export const SystemManagement = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <path
          d="M5.5,2 L18.5,2 C19.3284271,2 20,2.67157288 20,3.5 L20,6.5 C20,7.32842712 19.3284271,8 18.5,8 L5.5,8 C4.67157288,8 4,7.32842712 4,6.5 L4,3.5 C4,2.67157288 4.67157288,2 5.5,2 Z M11,4 C10.4477153,4 10,4.44771525 10,5 C10,5.55228475 10.4477153,6 11,6 L13,6 C13.5522847,6 14,5.55228475 14,5 C14,4.44771525 13.5522847,4 13,4 L11,4 Z"
          fill="#000000"
          opacity="0.3"
        ></path>
        <path
          d="M5.5,9 L18.5,9 C19.3284271,9 20,9.67157288 20,10.5 L20,13.5 C20,14.3284271 19.3284271,15 18.5,15 L5.5,15 C4.67157288,15 4,14.3284271 4,13.5 L4,10.5 C4,9.67157288 4.67157288,9 5.5,9 Z M11,11 C10.4477153,11 10,11.4477153 10,12 C10,12.5522847 10.4477153,13 11,13 L13,13 C13.5522847,13 14,12.5522847 14,12 C14,11.4477153 13.5522847,11 13,11 L11,11 Z M5.5,16 L18.5,16 C19.3284271,16 20,16.6715729 20,17.5 L20,20.5 C20,21.3284271 19.3284271,22 18.5,22 L5.5,22 C4.67157288,22 4,21.3284271 4,20.5 L4,17.5 C4,16.6715729 4.67157288,16 5.5,16 Z M11,18 C10.4477153,18 10,18.4477153 10,19 C10,19.5522847 10.4477153,20 11,20 L13,20 C13.5522847,20 14,19.5522847 14,19 C14,18.4477153 13.5522847,18 13,18 L11,18 Z"
          fill="#000000"
        ></path>
      </g>
    </svg>
  );
};

export const Arrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //
      //xmlns:xlink="http://www.w3.org/1999/xlink"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon points="0 0 24 0 24 24 0 24" />
        <path
          d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
          fill="#000000"
          fillRule="nonzero"
          transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) "
        />
        <path
          d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
          fill="#000000"
          fillRule="nonzero"
          opacity="0.3"
          transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) "
        />
      </g>
    </svg>
  );
};

export const GoogleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M19.9895 10.1871C19.9895 9.36767 19.9214 8.76973 19.7742 8.14966H10.1992V11.848H15.8195C15.7062 12.7671 15.0943 14.1512 13.7346 15.0813L13.7155 15.2051L16.7429 17.4969L16.9527 17.5174C18.879 15.7789 19.9895 13.221 19.9895 10.1871Z"
        fill="#4285F4"
      />
      <path
        d="M10.1993 19.9313C12.9527 19.9313 15.2643 19.0454 16.9527 17.5174L13.7346 15.0813C12.8734 15.6682 11.7176 16.0779 10.1993 16.0779C7.50243 16.0779 5.21352 14.3395 4.39759 11.9366L4.27799 11.9466L1.13003 14.3273L1.08887 14.4391C2.76588 17.6945 6.21061 19.9313 10.1993 19.9313Z"
        fill="#34A853"
      />
      <path
        d="M4.39748 11.9366C4.18219 11.3166 4.05759 10.6521 4.05759 9.96565C4.05759 9.27909 4.18219 8.61473 4.38615 7.99466L4.38045 7.8626L1.19304 5.44366L1.08875 5.49214C0.397576 6.84305 0.000976562 8.36008 0.000976562 9.96565C0.000976562 11.5712 0.397576 13.0882 1.08875 14.4391L4.39748 11.9366Z"
        fill="#FBBC05"
      />
      <path
        d="M10.1993 3.85336C12.1142 3.85336 13.406 4.66168 14.1425 5.33717L17.0207 2.59107C15.253 0.985496 12.9527 0 10.1993 0C6.2106 0 2.76588 2.23672 1.08887 5.49214L4.38626 7.99466C5.21352 5.59183 7.50242 3.85336 10.1993 3.85336Z"
        fill="#EB4335"
      />
    </svg>
  );
};

export const Users = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon points="0 0 24 0 24 24 0 24"></polygon>
        <path
          d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
          fill="#000000"
          fillRule="nonzero"
          opacity="0.3"
        ></path>
        <path
          d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
          fill="#000000"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

export const MakeActivate = () => {
  return (
    <span className="svg-icon svg-icon-md svg-icon-success">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <polygon points="0 0 24 0 24 24 0 24"></polygon>
          <path
            d="M9.26193932,16.6476484 C8.90425297,17.0684559 8.27315905,17.1196257 7.85235158,16.7619393 C7.43154411,16.404253 7.38037434,15.773159 7.73806068,15.3523516 L16.2380607,5.35235158 C16.6013618,4.92493855 17.2451015,4.87991302 17.6643638,5.25259068 L22.1643638,9.25259068 C22.5771466,9.6195087 22.6143273,10.2515811 22.2474093,10.6643638 C21.8804913,11.0771466 21.2484189,11.1143273 20.8356362,10.7474093 L17.0997854,7.42665306 L9.26193932,16.6476484 Z"
            fill="#000000"
            fillRule="nonzero"
            opacity="0.3"
            transform="translate(14.999995, 11.000002) rotate(-180.000000) translate(-14.999995, -11.000002) "
          ></path>
          <path
            d="M4.26193932,17.6476484 C3.90425297,18.0684559 3.27315905,18.1196257 2.85235158,17.7619393 C2.43154411,17.404253 2.38037434,16.773159 2.73806068,16.3523516 L11.2380607,6.35235158 C11.6013618,5.92493855 12.2451015,5.87991302 12.6643638,6.25259068 L17.1643638,10.2525907 C17.5771466,10.6195087 17.6143273,11.2515811 17.2474093,11.6643638 C16.8804913,12.0771466 16.2484189,12.1143273 15.8356362,11.7474093 L12.0997854,8.42665306 L4.26193932,17.6476484 Z"
            fill="#000000"
            fillRule="nonzero"
            transform="translate(9.999995, 12.000002) rotate(-180.000000) translate(-9.999995, -12.000002) "
          ></path>
        </g>
      </svg>
    </span>
  );
};

export const MakeDeactivate = () => {
  return (
    <span className="svg-icon svg-icon-md svg-icon-danger">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
            fill="#000000"
          >
            <rect x="0" y="7" width="16" height="2" rx="1"></rect>
            <rect
              opacity="0.3"
              transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
              x="0"
              y="7"
              width="16"
              height="2"
              rx="1"
            ></rect>
          </g>
        </g>
      </svg>
    </span>
  );
};

export const ChangePassword = () => {
  return (
    <span className="svg-icon svg-icon-md svg-icon-primary">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-person"
        viewBox="0 0 16 16"
      >
        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"></path>
      </svg>
    </span>
  );
};

export const SendCreds = () => {
  return (
    <span className="svg-icon svg-icon-primary svg-icon-2x">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24"></rect>
          <path
            d="M14,13.381038 L14,3.47213595 L7.99460483,15.4829263 L14,13.381038 Z M4.88230018,17.2353996 L13.2844582,0.431083506 C13.4820496,0.0359007077 13.9625881,-0.12427877 14.3577709,0.0733126292 C14.5125928,0.15072359 14.6381308,0.276261584 14.7155418,0.431083506 L23.1176998,17.2353996 C23.3152912,17.6305824 23.1551117,18.1111209 22.7599289,18.3087123 C22.5664522,18.4054506 22.3420471,18.4197165 22.1378777,18.3482572 L14,15.5 L5.86212227,18.3482572 C5.44509941,18.4942152 4.98871325,18.2744737 4.84275525,17.8574509 C4.77129597,17.6532815 4.78556182,17.4288764 4.88230018,17.2353996 Z"
            fill="#000000"
            fillRule="nonzero"
            transform="translate(14.000087, 9.191034) rotate(-315.000000) translate(-14.000087, -9.191034) "
          ></path>
        </g>
      </svg>
    </span>
  );
};

export const Seeker = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
      // xml:space="preserve"
    >
      <g>
        <path d="M500,990C229.8,990,10,770.2,10,500C10,229.8,229.8,10,500,10c270.2,0,490,219.8,490,490C990,770.2,770.2,990,500,990z M500,56.7C255.5,56.7,56.7,255.5,56.7,500c0,244.5,198.9,443.3,443.3,443.3c244.5,0,443.3-198.9,443.3-443.3C943.3,255.5,744.5,56.7,500,56.7z" />
        <path d="M646.9,229.6" />
        <path d="M457.1,738.1H285.3c-12.9,0-23.3-10.4-23.3-23.3V543c0-6.2,2.5-12.1,6.8-16.5l343.6-343.6c8.8-8.7,24.2-8.7,33,0l171.8,171.8c9.1,9.1,9.1,23.9,0,33l-67.8,67.8c-9.1,9.1-23.9,9.1-33,0L593,332.2c-9.1-9.1-9.1-23.9,0-33c9.1-9.1,23.9-9.1,33,0L732.8,406l34.8-34.8L628.8,232.4L308.6,552.6v138.8h138.8l214.8-214.8c9.1-9.1,23.9-9.1,33,0c9.1,9.1,9.1,23.9,0,33L473.6,731.2C469.2,735.6,463.2,738.1,457.1,738.1z" />
      </g>
    </svg>
  );
};

export const Creator = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
      // xml:space="preserve"
    >
      <g>
        <path d="M731.2,75.6c-219.4,4.6-301.8,177.8-301.8,177.8c-25-30.4-86.1-76.5-190.3-70.7c-96.3,5.4-142.7,48.2-178.3,96.4C-45.1,422.2,2.8,572.6,317.9,830.4c18.4,15.1,56.1,21.2,74.5,5.6c16.9-14.3,40.3-62.5-17.8-110.9c-150.7-125.7-227.3-209.9-164.2-254c49.3-34.4,83,127,237.3,127c163.1,0,242.5-208.6,292-217.7c81.7-15,56.9,119.5-73,272.1c-38.9,45.7-100.4,93.7-154.7,138.9c-41.3,34.3-77.6,46.6-85.1,75.9C416,909,446.1,921,477,924.4c8.6,0.3,17.7-0.8,27.3-3.1c23.2-5.5,48.4-20.4,86.2-42c163.6-94,392.1-286,399.3-496.6C995.1,227.3,900.6,72,731.2,75.6z M456.7,525.6c-45.4,0-82.1-36.6-82.1-81.6c0-45.1,36.8-81.6,82.1-81.6c45.3,0,82.1,36.6,82.1,81.6C538.9,489,502.1,525.6,456.7,525.6z" />
      </g>
    </svg>
  );
};

export const HelpCentre = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
      // xml:space="preserve"
    >
      <g>
        <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
          <path d="M4597.4,4993c-92.1-9.8-268.5-41.2-392-68.6c-1264.1-284.2-2285.1-1230.8-2655.5-2465.4c-119.5-395.9-139.1-552.7-139.1-1099.5c2-507.6,21.6-703.6,111.7-1117.1C1887-1433.2,3060.9-3204.9,4752.2-4627.7c245-205.8,227.3-201.9,395.9-66.6C6427.8-3661.5,7572.4-2183.8,8117.2-857c429.2,1044.6,576.2,2146,397.8,2998.5C8238.7,3480,7211.8,4559.8,5898.7,4896.9C5520.5,4994.9,4965.8,5036.1,4597.4,4993z M5420.5,4218.8c531.1-90.2,1038.7-344.9,1434.6-713.4c339-317.5,595.8-709.5,742.8-1136.7c92.1-264.6,133.3-493.9,147-803.5c31.4-811.3-254.8-1534.5-832.9-2108.7c-392-388-899.6-654.6-1464-764.3c-243-49-756.5-49-999.5,0C3292.2-1080.5,2441.6-239.7,2190.7,920.5c-50.9,233.2-49,840.7,2,1075.9c123.5,570.3,368.4,1023,776.1,1432.6c456.6,456.6,960.3,709.4,1626.6,809.4C4740.4,4260,5252,4248.2,5420.5,4218.8z" />
          <path d="M5957.5,3109.6c-211.7-111.7-515.4-515.4-580.1-770.2l-23.5-92.1l150.9-150.9c147-147,148.9-150.9,148.9-248.9c0-141.1-99.9-329.2-290-546.8c-309.6-354.7-676.1-640.9-876-684c-58.8-11.8-64.7-5.9-172.5,152.9c-129.4,188.1-182.3,219.5-341,196c-147-19.6-386.1-119.6-546.8-227.3c-182.3-121.5-243-215.6-243-376.3c0-192.1,31.4-276.3,143.1-374.3c115.6-105.8,401.8-250.9,582.1-297.9l127.4-31.3l215.6,109.7c252.8,129.4,621.3,366.5,840.8,540.9C5681.2,779.4,6425.9,1704.4,6625.8,2214l52.9,135.2l-33.3,123.5c-76.4,272.4-221.4,501.7-393.9,621.3C6149.6,3164.5,6069.2,3168.4,5957.5,3109.6z" />
        </g>
      </g>
    </svg>
  );
};

export const Tutorials = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
      // xml:space="preserve"
    >
      <path d="M448.4,397.8c-45.5,28.5-59.4,88.7-30.8,134.3c28.4,45.6,88.6,59.4,134.1,30.9c45.6-28.4,59.5-88.5,31-134.2C554.2,383.2,494,369.3,448.4,397.8z" />
      <path d="M872.9,729.3c21.8-8.8,37.2-30.1,37.2-55V267c0-32.8-26.6-59.4-59.4-59.4H149.3c-32.8,0-59.4,26.7-59.4,59.4v407.3c0,24.9,15.4,46.1,37.2,55H10c0,50.1,19.5,63.1,43.4,63.1h893.2c23.9,0,43.4-13,43.4-63.1H872.9z M590.6,764.4H409.5v-30.7h181.1V764.4z M664.2,546.1l-33.6-7.8c-7.6,17.3-18.9,33.3-33.3,46.5l18.4,29.3l-46,28.8l-18.3-29.3c-18.3,7.1-37.6,10.2-56.5,9.5l-7.7,33.7l-52.8-12.3l7.7-33.6c-17.3-7.6-33.1-18.8-46.5-33.3L366.2,596l-28.8-46.1l29.3-18.3c-7-18.5-10.2-37.5-9.5-56.5l-33.7-7.7l12.3-52.9l33.6,7.8c7.6-17.4,18.9-33.2,33.3-46.5l-18.3-29.4l45.9-28.7l18.3,29.4c18.4-7,37.5-10.2,56.5-9.4L513,304l52.8,12.1l-7.7,33.6c17.3,7.7,33.1,18.9,46.5,33.3l29.3-18.4l28.7,46.1l-29.3,18.3c7,18.3,10.1,37.5,9.4,56.4l33.7,7.7L664.2,546.1z" />
    </svg>
  );
};

export const Category = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect x="0" y="0" width="24" height="24" />
        <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5" />
        <path
          d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z"
          fill="#000000"
          opacity="0.3"
        />
      </g>
    </svg>
  );
};

export const Review = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="478.248px"
      height="478.248px"
      viewBox="0 0 478.248 478.248"
      // style="enable-background:new 0 0 478.248 478.248;"
      enableBackground="new 0 0 478.248 478.248"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <path
              d="M456.02,44.821H264.83c-12.26,0-22.232,9.972-22.232,22.229v98.652c0,12.258,9.974,22.23,22.232,22.23h16.787v39.161
				c0,2.707,1.58,5.165,4.043,6.292c0.92,0.42,1.901,0.627,2.875,0.627c1.631,0,3.244-0.576,4.523-1.685l51.383-44.396h111.576
				c12.26,0,22.23-9.973,22.23-22.23V67.05C478.25,54.792,468.277,44.821,456.02,44.821z M319.922,112.252l-10.209,9.953
				l2.41,14.054c0.174,1.015-0.242,2.038-1.076,2.643c-0.469,0.342-1.027,0.516-1.588,0.516c-0.428,0-0.861-0.103-1.256-0.31
				l-12.621-6.635l-12.619,6.635c-0.912,0.478-2.016,0.398-2.848-0.206s-1.248-1.628-1.074-2.643l2.41-14.054l-10.211-9.953
				c-0.734-0.718-1.002-1.792-0.685-2.769c0.317-0.978,1.164-1.691,2.183-1.839l14.11-2.05l6.31-12.786
				c0.457-0.923,1.396-1.507,2.424-1.507s1.969,0.584,2.422,1.507l6.312,12.786l14.107,2.05c1.02,0.148,1.863,0.861,2.184,1.839
				C320.924,110.46,320.658,111.535,319.922,112.252z M384.766,112.252l-10.211,9.953l2.412,14.054
				c0.172,1.015-0.244,2.038-1.076,2.643c-0.469,0.342-1.025,0.516-1.588,0.516c-0.43,0-0.859-0.103-1.26-0.31l-12.619-6.635
				l-12.619,6.635c-0.912,0.478-2.014,0.398-2.846-0.206c-0.834-0.604-1.25-1.628-1.076-2.643l2.41-14.054l-10.209-9.953
				c-0.734-0.718-1.002-1.792-0.684-2.769c0.316-0.978,1.16-1.691,2.182-1.839l14.109-2.05l6.311-12.786
				c0.455-0.923,1.396-1.507,2.422-1.507c1.029,0,1.967,0.584,2.422,1.507l6.312,12.786l14.109,2.05
				c1.021,0.148,1.863,0.861,2.182,1.839C385.768,110.46,385.5,111.535,384.766,112.252z M449.607,112.252l-10.211,9.953
				l2.408,14.054c0.176,1.015-0.238,2.038-1.072,2.643c-0.471,0.342-1.027,0.516-1.59,0.516c-0.43,0-0.859-0.103-1.258-0.31
				l-12.621-6.635l-12.621,6.635c-0.908,0.478-2.012,0.398-2.844-0.206c-0.834-0.604-1.248-1.628-1.076-2.643l2.412-14.054
				l-10.211-9.953c-0.734-0.718-1-1.792-0.684-2.769c0.316-0.978,1.164-1.691,2.182-1.839l14.111-2.05l6.311-12.786
				c0.453-0.923,1.395-1.507,2.42-1.507c1.027,0,1.971,0.584,2.426,1.507L434,105.594l14.109,2.05
				c1.018,0.148,1.861,0.861,2.182,1.839C450.609,110.46,450.344,111.535,449.607,112.252z"
            />
            <path
              d="M152.844,112.924c-46.76,0-72.639,24.231-72.166,70.921c0.686,63.947,27.859,102.74,72.166,102.063
				c0,0,72.131,2.924,72.131-102.063C224.975,137.155,200.605,112.924,152.844,112.924z"
            />
            <path
              d="M280.428,334.444l-72.074-28.736l-16.877-14.223c-4.457-3.766-11.041-3.488-15.178,0.621l-23.463,23.336l-23.533-23.342
				c-4.137-4.104-10.713-4.369-15.164-0.615l-16.881,14.223l-72.074,28.739C1.975,343.69,1.995,425.884,0,433.427h305.646
				C303.656,425.9,303.646,343.679,280.428,334.444z"
            />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

export const Order = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <polygon
            points="153.106,312.121 153.106,512 168.717,512 168.717,327.731 345.18,327.731 345.18,512 360.791,512 
			360.791,312.121 		"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon points="295.548,386.072 295.548,512 311.158,512 311.158,401.683 319.484,401.683 319.484,386.072 		" />
        </g>
      </g>
      <g>
        <g>
          <polygon points="192.516,386.072 192.516,401.683 200.842,401.683 200.842,512 216.452,512 216.452,386.072 		" />
        </g>
      </g>
      <g>
        <g>
          <path d="M209.127,128.05v167.669h95.642V128.05H209.127z M289.157,280.108h-64.42V143.661h0h64.42V280.108z" />
        </g>
      </g>
      <g>
        <g>
          <polygon points="368.398,360.054 368.398,375.665 488.082,375.665 488.082,512 503.692,512 503.692,360.054 		" />
        </g>
      </g>
      <g>
        <g>
          <polygon points="447.493,417.294 447.493,512 463.104,512 463.104,432.905 472.471,432.905 472.471,417.294 		" />
        </g>
      </g>
      <g>
        <g>
          <polygon points="368.398,417.294 368.398,432.905 376.724,432.905 376.724,512 392.335,512 392.335,417.294 		" />
        </g>
      </g>
      <g>
        <g>
          <path d="M393.199,240.092v103.646h55.626V240.092H393.199z M433.214,328.127H408.81v-72.424h24.404V328.127z" />
        </g>
      </g>
      <g>
        <g>
          <polygon points="8.308,360.054 8.308,512 23.918,512 23.918,375.665 143.602,375.665 143.602,360.054 		" />
        </g>
      </g>
      <g>
        <g>
          <polygon points="40.57,417.294 40.57,432.905 47.855,432.905 47.855,512 63.466,512 63.466,417.294 		" />
        </g>
      </g>
      <g>
        <g>
          <polygon points="120.706,417.294 120.706,512 136.317,512 136.317,432.905 143.602,432.905 143.602,417.294 		" />
        </g>
      </g>
      <g>
        <g>
          <path d="M65.072,240.092v103.646h55.627V240.092H65.072z M105.088,328.127H80.683v-72.424h24.405V328.127z" />
        </g>
      </g>
      <g>
        <g>
          <rect x="249.754" y="337.153" width="15.611" height="15.611" />
        </g>
      </g>
      <g>
        <g>
          <rect x="249.754" y="360.049" width="15.611" height="86.38" />
        </g>
      </g>
      <g>
        <g>
          <rect x="216.45" y="41.593" width="15.611" height="15.611" />
        </g>
      </g>
      <g>
        <g>
          <rect x="249.754" y="41.593" width="15.611" height="15.611" />
        </g>
      </g>
      <g>
        <g>
          <rect x="280.975" y="41.593" width="15.611" height="15.611" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M344.785,41.593V0H169.112v41.593h-16.144v15.611h16.144v38.438h61.467l26.369,17.583l26.37-17.583h61.467V57.203h15.288
			V41.593H344.785z M329.173,80.031H278.59l-21.643,14.43l-21.641-14.43h-50.583v-64.42h144.45V80.031z"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon
            points="143.602,57.203 143.602,41.593 88.443,41.593 88.443,190.161 78.396,180.112 67.357,191.152 95.846,220.68 
			125.375,191.151 115.375,180.113 104.054,191.435 104.054,57.203 		"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon
            points="434.458,182.194 423.557,193.097 423.557,41.593 368.398,41.593 368.398,57.203 407.946,57.203 407.946,192.662 
			397.479,182.193 386.439,193.233 415.969,222.762 445.497,193.232 		"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

export const Transactions = () => {
  return (
    <svg
      version="1.2"
      baseProfile="tiny"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 256 256"
      xmlSpace="preserve"
    >
      <path
        id="XMLID_11_"
        d="M57.9,41.9c11.3,0,20.5-9.2,20.5-20.5c0-11.3-9.2-20.5-20.5-20.5c-11.3,0-20.5,9.2-20.5,20.5
	C37.4,32.7,46.6,41.9,57.9,41.9"
      />
      <path
        id="XMLID_10_"
        d="M203.4,41.6c11.5,0,20.8-9.3,20.8-20.8c0-11.5-9.3-20.8-20.8-20.8c-11.5,0-20.8,9.3-20.8,20.8
	C182.6,32.3,191.9,41.6,203.4,41.6"
      />
      <path
        id="XMLID_7_"
        d="M50.3,158.5c-26.9,0-48.7,21.8-48.7,48.7c0,26.9,21.8,48.8,48.7,48.8c26.9,0,48.8-21.8,48.8-48.8
	C99.1,180.3,77.2,158.5,50.3,158.5 M56.1,238.2v7.8H44.4v-7.8c-12.4-1.4-20.4-13.3-20.4-21h12.4c0,3.5,2.7,8.1,8,9.2v-14.6
	c-2.7-0.5-4.2-0.9-7.1-1.7c-7.3-2-12.4-8.2-12.4-16.1c0-7.8,7.6-16.6,19.4-18.1v-7.3h11.7v7.3c12.5,1.6,20.1,10.8,20.1,20l-12.2,0
	c0-4-3.4-7.7-8-8.1v13.7c3.4,0.7,3.4,0.7,6.5,1.5c11.6,3.2,14.2,10.7,14.2,17.1C76.8,229.8,69,236.5,56.1,238.2"
      />
      <path
        id="XMLID_4_"
        d="M44.4,199.3c-5.2-0.9-6.6-3.2-6.6-5.6c0-2.8,3.5-5.5,6.6-5.9V199.3z M56.2,226.3V214c5.1,0.7,8.4,3,8.4,6.1
	C64.6,222.9,61.6,226.1,56.2,226.3"
      />
      <path
        id="XMLID_3_"
        d="M31.2,77.4h8.1l-9.3,34.7h56l-9.3-34.7h8.1l9.6,34.7h18L100.6,70c-3.1-10.2-13.2-21-26.4-21H41.5
	c-13.2,0-23.3,10.8-26.4,21L3.6,112.1h18L31.2,77.4z"
      />
      <path
        id="XMLID_2_"
        d="M225.6,48.8c10.2,0,18.5,8.3,18.5,18.5l0,56.8H256v23.3l-24.1,0v96.4c0,6.7-5.5,12.2-12.3,12.2
	c-6.7,0-12.2-5.5-12.2-12.2v-96.4h-7.8v96.4c0,6.7-5.5,12.2-12.2,12.2c-6.7,0-12.2-5.5-12.2-12.2v-96.4L1.6,147.4v-23.4h173.6V69.4
	l-38.8,46c-8.2,9.4-22.7-2.4-14.5-12.4l40.2-48.4c2.6-2.6,4.9-5.8,14.5-5.8H225.6z"
      />
    </svg>
  );
};

export const Reports = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 463 463"
      enable-background="new 0 0 463 463"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M367.5,32h-57.734c-3.138-9.29-11.93-16-22.266-16h-24.416c-7.41-9.965-19.148-16-31.584-16
		c-12.435,0-24.174,6.035-31.585,16H175.5c-10.336,0-19.128,6.71-22.266,16H95.5C78.131,32,64,46.131,64,63.5v368
		c0,17.369,14.131,31.5,31.5,31.5h272c17.369,0,31.5-14.131,31.5-31.5v-368C399,46.131,384.869,32,367.5,32z M175.5,87h112
		c7.023,0,13.332-3.101,17.641-8H352v337H111V79h46.859C162.168,83.899,168.477,87,175.5,87z M175.5,31h28.438
		c2.67,0,5.139-1.419,6.482-3.727C214.893,19.588,222.773,15,231.5,15c8.728,0,16.607,4.588,21.079,12.272
		c1.343,2.308,3.813,3.728,6.482,3.728H287.5c4.687,0,8.5,3.813,8.5,8.5v24c0,4.687-3.813,8.5-8.5,8.5h-112
		c-4.687,0-8.5-3.813-8.5-8.5v-24C167,34.813,170.813,31,175.5,31z M384,431.5c0,9.098-7.402,16.5-16.5,16.5h-272
		c-9.098,0-16.5-7.402-16.5-16.5v-368C79,54.402,86.402,47,95.5,47H152v16.5c0,0.168,0.009,0.333,0.013,0.5H103.5
		c-4.143,0-7.5,3.358-7.5,7.5v352c0,4.142,3.357,7.5,7.5,7.5h256c4.143,0,7.5-3.358,7.5-7.5v-352c0-4.142-3.357-7.5-7.5-7.5h-48.513
		c0.004-0.167,0.013-0.332,0.013-0.5V47h56.5c9.098,0,16.5,7.402,16.5,16.5V431.5z"
        />
        <path
          d="M231.5,47c1.979,0,3.91-0.8,5.3-2.2c1.4-1.39,2.2-3.33,2.2-5.3c0-1.97-0.8-3.91-2.2-5.3c-1.39-1.4-3.32-2.2-5.3-2.2
		c-1.98,0-3.91,0.8-5.3,2.2c-1.4,1.39-2.2,3.32-2.2,5.3s0.8,3.91,2.2,5.3C227.59,46.2,229.52,47,231.5,47z"
        />
        <path d="M183.5,159h136c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-136c-4.143,0-7.5,3.358-7.5,7.5S179.357,159,183.5,159z" />
        <path d="M183.5,239h136c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-136c-4.143,0-7.5,3.358-7.5,7.5S179.357,239,183.5,239z" />
        <path d="M183.5,319h24c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-24c-4.143,0-7.5,3.358-7.5,7.5S179.357,319,183.5,319z" />
        <path d="M183.5,199h136c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-136c-4.143,0-7.5,3.358-7.5,7.5S179.357,199,183.5,199z" />
        <path d="M183.5,279h32c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-32c-4.143,0-7.5,3.358-7.5,7.5S179.357,279,183.5,279z" />
        <path d="M183.5,359h32c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-32c-4.143,0-7.5,3.358-7.5,7.5S179.357,359,183.5,359z" />
        <path d="M143.5,159h8c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-8c-4.143,0-7.5,3.358-7.5,7.5S139.357,159,143.5,159z" />
        <path d="M143.5,239h8c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-8c-4.143,0-7.5,3.358-7.5,7.5S139.357,239,143.5,239z" />
        <path d="M143.5,319h8c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-8c-4.143,0-7.5,3.358-7.5,7.5S139.357,319,143.5,319z" />
        <path d="M143.5,199h8c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-8c-4.143,0-7.5,3.358-7.5,7.5S139.357,199,143.5,199z" />
        <path d="M143.5,279h8c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-8c-4.143,0-7.5,3.358-7.5,7.5S139.357,279,143.5,279z" />
        <path d="M143.5,359h8c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-8c-4.143,0-7.5,3.358-7.5,7.5S139.357,359,143.5,359z" />
        <path
          d="M279.5,264c-26.191,0-47.5,21.309-47.5,47.5s21.309,47.5,47.5,47.5c10.583,0,20.367-3.482,28.272-9.357
		c0.074-0.052,0.155-0.088,0.228-0.143c0.2-0.15,0.389-0.309,0.57-0.474C319.771,340.329,327,326.747,327,311.5
		C327,285.309,305.691,264,279.5,264z M272,279.883V304h-24.117C250.708,292.094,260.094,282.708,272,279.883z M247.883,319h27.867
		l16.719,22.292c-3.976,1.737-8.36,2.708-12.969,2.708C264.161,344,251.279,333.315,247.883,319z M304.463,332.284L287,309v-29.117
		c14.315,3.396,25,16.278,25,31.617C312,319.398,309.165,326.646,304.463,332.284z"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

export const Wallet = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="28"
      viewBox="0 0 31 28"
      fill="none"
    >
      <path
        d="M27.125 6.22222H24.8V4.66667C24.7988 3.42937 24.3085 2.2431 23.4367 1.3682C22.5649 0.493298 21.3829 0.00123545 20.15 0H3.875C2.84754 0.000823927 1.86239 0.41081 1.13587 1.13994C0.409342 1.86907 0.000820985 2.85774 0 3.88889V23.3333C0.00123103 24.5706 0.491536 25.7569 1.36331 26.6318C2.23509 27.5067 3.41712 27.9988 4.65 28H27.125C28.1525 27.9992 29.1376 27.5892 29.8641 26.8601C30.5907 26.1309 30.9992 25.1423 31 24.1111V10.1111C30.9992 9.07997 30.5907 8.09129 29.8641 7.36216C29.1376 6.63303 28.1525 6.22305 27.125 6.22222ZM3.875 1.55556H20.15C20.9718 1.55679 21.7596 1.88496 22.3407 2.46814C22.9218 3.05132 23.2488 3.84193 23.25 4.66667V6.22222H3.875C3.56968 6.22222 3.26734 6.16187 2.98526 6.04461C2.70318 5.92735 2.44687 5.75548 2.23098 5.53881C2.01508 5.32213 1.84382 5.06491 1.72698 4.78182C1.61014 4.49872 1.55 4.19531 1.55 3.88889C1.55 3.58247 1.61014 3.27905 1.72698 2.99596C1.84382 2.71287 2.01508 2.45564 2.23098 2.23897C2.44687 2.0223 2.70318 1.85043 2.98526 1.73317C3.26734 1.61591 3.56968 1.55556 3.875 1.55556ZM29.45 20.2222H26.35C25.5278 20.2222 24.7393 19.8944 24.158 19.311C23.5766 18.7276 23.25 17.9362 23.25 17.1111C23.25 16.286 23.5766 15.4947 24.158 14.9112C24.7393 14.3278 25.5278 14 26.35 14H29.45V20.2222ZM29.45 12.4444H26.35C25.7394 12.4444 25.1347 12.5652 24.5705 12.7997C24.0064 13.0342 23.4937 13.3779 23.062 13.8113C22.6302 14.2446 22.2876 14.7591 22.054 15.3253C21.8203 15.8914 21.7 16.4983 21.7 17.1111C21.7 17.7239 21.8203 18.3308 22.054 18.897C22.2876 19.4632 22.6302 19.9776 23.062 20.4109C23.4937 20.8443 24.0064 21.188 24.5705 21.4226C25.1347 21.6571 25.7394 21.7778 26.35 21.7778H29.45V24.1111C29.45 24.7299 29.205 25.3234 28.769 25.761C28.333 26.1986 27.7416 26.4444 27.125 26.4444H4.65C3.82821 26.4432 3.04042 26.115 2.45933 25.5319C1.87823 24.9487 1.55123 24.1581 1.55 23.3333V6.99844C2.2196 7.50556 3.03645 7.77933 3.875 7.77778H27.125C27.7416 7.77778 28.333 8.02361 28.769 8.46119C29.205 8.89878 29.45 9.49227 29.45 10.1111V12.4444Z"
        fill="currentcolor"
      />
    </svg>
  );
};

export const Payout = () => {
  return (
    <span class="svg-icon svg-icon-primary svg-icon-2x">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        fill="none"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <rect x="0" y="0" width="24" height="24" />
          <path
            d="M22,17 L22,21 C22,22.1045695 21.1045695,23 20,23 L4,23 C2.8954305,23 2,22.1045695 2,21 L2,17 L6.27924078,17 L6.82339262,18.6324555 C7.09562072,19.4491398 7.8598984,20 8.72075922,20 L15.381966,20 C16.1395101,20 16.8320364,19.5719952 17.1708204,18.8944272 L18.118034,17 L22,17 Z"
            fill="#000000"
          />
          <path
            d="M2.5625,15 L5.92654389,9.01947752 C6.2807805,8.38972356 6.94714834,8 7.66969497,8 L16.330305,8 C17.0528517,8 17.7192195,8.38972356 18.0734561,9.01947752 L21.4375,15 L18.118034,15 C17.3604899,15 16.6679636,15.4280048 16.3291796,16.1055728 L15.381966,18 L8.72075922,18 L8.17660738,16.3675445 C7.90437928,15.5508602 7.1401016,15 6.27924078,15 L2.5625,15 Z"
            fill="#000000"
            opacity="0.3"
          />
          <path
            d="M11.1288761,0.733697713 L11.1288761,2.69017121 L9.12120481,2.69017121 C8.84506244,2.69017121 8.62120481,2.91402884 8.62120481,3.19017121 L8.62120481,4.21346991 C8.62120481,4.48961229 8.84506244,4.71346991 9.12120481,4.71346991 L11.1288761,4.71346991 L11.1288761,6.66994341 C11.1288761,6.94608579 11.3527337,7.16994341 11.6288761,7.16994341 C11.7471877,7.16994341 11.8616664,7.12798964 11.951961,7.05154023 L15.4576222,4.08341738 C15.6683723,3.90498251 15.6945689,3.58948575 15.5161341,3.37873564 C15.4982803,3.35764848 15.4787093,3.33807751 15.4576222,3.32022374 L11.951961,0.352100892 C11.7412109,0.173666017 11.4257142,0.199862688 11.2472793,0.410612793 C11.1708299,0.500907473 11.1288761,0.615386087 11.1288761,0.733697713 Z"
            fill="#000000"
            fill-rule="nonzero"
            transform="translate(11.959697, 3.661508) rotate(-90.000000) translate(-11.959697, -3.661508) "
          />
        </g>
      </svg>
    </span>
  );
};

export const Courses = () => {
  return (
    <span class="svg-icon svg-icon-primary svg-icon-2x">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        fill="none"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <rect x="0" y="0" width="24" height="24" />
          <path
            d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
            fill="#000000"
            opacity="0.3"
          />
          <path
            d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
            fill="#000000"
          />
          <rect
            fill="#000000"
            opacity="0.3"
            x="10"
            y="9"
            width="7"
            height="2"
            rx="1"
          />
          <rect
            fill="#000000"
            opacity="0.3"
            x="7"
            y="9"
            width="2"
            height="2"
            rx="1"
          />
          <rect
            fill="#000000"
            opacity="0.3"
            x="7"
            y="13"
            width="2"
            height="2"
            rx="1"
          />
          <rect
            fill="#000000"
            opacity="0.3"
            x="10"
            y="13"
            width="7"
            height="2"
            rx="1"
          />
          <rect
            fill="#000000"
            opacity="0.3"
            x="7"
            y="17"
            width="2"
            height="2"
            rx="1"
          />
          <rect
            fill="#000000"
            opacity="0.3"
            x="10"
            y="17"
            width="7"
            height="2"
            rx="1"
          />
        </g>
      </svg>
    </span>
  );
};

export const Creators = () => {
  return (
    <span class="svg-icon svg-icon-primary svg-icon-2x">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        fill="none"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <rect x="0" y="0" width="24" height="24" />
          <rect
            fill="#000000"
            opacity="0.3"
            x="4"
            y="4"
            width="4"
            height="4"
            rx="1"
          />
          <path
            d="M5,10 L7,10 C7.55228475,10 8,10.4477153 8,11 L8,13 C8,13.5522847 7.55228475,14 7,14 L5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 L14,7 C14,7.55228475 13.5522847,8 13,8 L11,8 C10.4477153,8 10,7.55228475 10,7 L10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,10 L13,10 C13.5522847,10 14,10.4477153 14,11 L14,13 C14,13.5522847 13.5522847,14 13,14 L11,14 C10.4477153,14 10,13.5522847 10,13 L10,11 C10,10.4477153 10.4477153,10 11,10 Z M17,4 L19,4 C19.5522847,4 20,4.44771525 20,5 L20,7 C20,7.55228475 19.5522847,8 19,8 L17,8 C16.4477153,8 16,7.55228475 16,7 L16,5 C16,4.44771525 16.4477153,4 17,4 Z M17,10 L19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 L17,14 C16.4477153,14 16,13.5522847 16,13 L16,11 C16,10.4477153 16.4477153,10 17,10 Z M5,16 L7,16 C7.55228475,16 8,16.4477153 8,17 L8,19 C8,19.5522847 7.55228475,20 7,20 L5,20 C4.44771525,20 4,19.5522847 4,19 L4,17 C4,16.4477153 4.44771525,16 5,16 Z M11,16 L13,16 C13.5522847,16 14,16.4477153 14,17 L14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 L10,17 C10,16.4477153 10.4477153,16 11,16 Z M17,16 L19,16 C19.5522847,16 20,16.4477153 20,17 L20,19 C20,19.5522847 19.5522847,20 19,20 L17,20 C16.4477153,20 16,19.5522847 16,19 L16,17 C16,16.4477153 16.4477153,16 17,16 Z"
            fill="#000000"
          />
        </g>
      </svg>
    </span>
  );
};

// export const Log = () => {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width="24px"
//       height="24px"
//       viewBox="0 0 24 24"
//       version="1.1"
//     >
//       <path d="M23 5v13.883l-1 .117v-16c-3.895.119-7.505.762-10.002 2.316-2.496-1.554-6.102-2.197-9.998-2.316v16l-1-.117v-13.883h-1v15h9.057c1.479 0 1.641 1 2.941 1 1.304 0 1.461-1 2.942-1h9.06v-15h-1zm-12 13.645c-1.946-.772-4.137-1.269-7-1.484v-12.051c2.352.197 4.996.675 7 1.922v11.613zm9-1.484c-2.863.215-5.054.712-7 1.484v-11.613c2.004-1.247 4.648-1.725 7-1.922v12.051z" />
//     </svg>
//   );
// };

export const Log = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 103.12 122.88"
    >
      {/* <title>View Lessons</title> */}
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M36.09,34.45a3.15,3.15,0,0,1,0-6.28H76.37a3.15,3.15,0,0,1,0,6.28ZM16.43,90a5.79,5.79,0,1,1-4.86,8.94H8.82v13.36a1.91,1.91,0,0,0,1.9,1.9H91.1a1.91,1.91,0,0,0,1.34-.56,1.87,1.87,0,0,0,.56-1.34V7.12a1.9,1.9,0,0,0-.55-1.34,1.93,1.93,0,0,0-1.35-.56H10.72a1.89,1.89,0,0,0-1.9,1.9V20.48h2.75a5.8,5.8,0,1,1,0,6.29H8.82V44.53h2.75a5.8,5.8,0,1,1,0,6.29H8.82V68.58h2.75a5.79,5.79,0,1,1,0,6.29H8.82V92.63h2.75A5.79,5.79,0,0,1,16.43,90ZM3.14,98.92a3.15,3.15,0,0,1,0-6.29H3.6V74.87H3.14a3.15,3.15,0,0,1,0-6.29H3.6V50.82H3.14a3.15,3.15,0,0,1,0-6.29H3.6V26.77H3.14a3.15,3.15,0,0,1,0-6.29H3.6V7.12a7.09,7.09,0,0,1,2.09-5h0a7.07,7.07,0,0,1,5-2.09H91.1a7.07,7.07,0,0,1,5,2.09h0A7.1,7.1,0,0,1,98,5.44h.6a4.55,4.55,0,0,1,4.5,4.51V118.37a4.53,4.53,0,0,1-4.5,4.51H16.76a4.51,4.51,0,0,1-4.39-3.48H10.72a7.14,7.14,0,0,1-7.12-7.12V98.92Zm33-7.69a3.15,3.15,0,0,1,0-6.29H76.37a3.15,3.15,0,0,1,0,6.29Zm0-18.92a3.15,3.15,0,0,1,0-6.29H76.37a3.15,3.15,0,0,1,0,6.29Zm0-18.93a3.15,3.15,0,0,1,0-6.29H76.37a3.15,3.15,0,0,1,0,6.29Z"
        fill="#000000"
      />
    </svg>
  );
};
