import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useParams } from "react-router-dom";
import { ADMINURL } from "../../constant/api";

import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";

const OBJ_TABLE = {
  location: "model",
  description: "description",
  "logged on": "createdAt",
};
function searchQueryHandler(page, per_page, id) {
  return `log/admin?page=${page}&per_page=${per_page}&id=${id}`;
}

const ViewAll = () => {
  const { id } = useParams();

  const [subadmin, setSubadmin] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [perPage, setPerPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "logged on",
    order: "desc",
  });

  const { records_per_page } = useSelector((state) => state.setting);

  const { permissions, roleId } = useSelector((state) => state.auth);

  const { request, response } = useRequest();

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request("GET", searchQueryHandler(1, records_per_page, id));
    }
    document.title = "Subadmin Logs - Kura";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      setSubadmin(response.logs);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  const fetchMoreData = ({ selected }) => {
    setSubadmin([]);

    setPage(selected + 1);
    request("GET", searchQueryHandler(selected + 1, perPage, id));
  };

  const perPageChangeHandler = (event) => {
    request("GET", searchQueryHandler(1, event.target.value, id));
    setPage(1);

    setPerPage(event.target.value);
  };

  const openLinkHandler = (id, data) => {
    if (data.url) {
      window.open(`${ADMINURL}${data.url}`, "_blank");
    }
  };

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Sub-Admin Logs"
        links={[{ to: "/", name: "Dashboard" }]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                {/* <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar"></div>
                </div> */}
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper ">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={() => {}}
                      mainData={subadmin}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      links={[
                        {
                          isLink: false,
                          name: "SendCreds",
                          click: openLinkHandler,
                          title: "View",
                          show: (data) => data.url,
                          //   access: ["c8"],
                        },
                      ]}
                      onlyDate={{
                        createdAt: "dateTime",
                        startDate: "dateTime",
                        endDate: "dateTime",
                      }}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={subadmin.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
